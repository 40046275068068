import { useMutation } from 'react-query';
import type { ServerFormError } from '@/services/api/types';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import { baseURL } from '@/services/api/axiosInstance';

export interface useVerifyEmailParams {
  email: string;
  token: string;
}

function useVerifyEmail() {
  return useMutation<AxiosResponse, ServerFormError, useVerifyEmailParams>((formData) => {
    return axios.post(baseURL + '/verify_email', {
      ...formData,
    });
  });
}

export default useVerifyEmail;
