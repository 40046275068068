import { useQuery } from 'react-query';
import axiosInstance from '@/services/api/axiosInstance';

export const usePingKey = 'ping';

function usePing(enabled: boolean) {
  return useQuery<number>(
    [usePingKey],
    async () => {
      const start = Date.now();
      await axiosInstance.get('/ping');
      return Date.now() - start;
    },
    {
      refetchInterval: 60000,
      refetchOnWindowFocus: 'always',
      enabled: enabled,
    },
  );
}

export default usePing;
