import { useMutation } from 'react-query';
import axiosInstance from '@/services/api/axiosInstance';
import useCompanyId from '@/hooks/useCompanyId';
import { ICustomer } from '@/services/api/types';

function useCreateCustomer() {
  const { apiRoutes } = useCompanyId();
  return useMutation<unknown, unknown, Partial<ICustomer>, unknown>((params) => {
    return axiosInstance.post(apiRoutes.customer.list, {
      ...params,
    });
  });
}

export default useCreateCustomer;
