import { useQuery } from 'react-query';
import axiosInstance from '@/services/api/axiosInstance';
import type { ServerFormError } from '@/services/api/types';
import type { AxiosResponse } from 'axios';
import useCompanyId from '@/hooks/useCompanyId';
import type { IAgent } from '@/services/api/types';

export const useMyAgentKey = 'agent';

function useMyAgent() {
  const { apiRoutes, COMPANY_ID } = useCompanyId();
  return useQuery<AxiosResponse<IAgent>, ServerFormError>(
    [useMyAgentKey, COMPANY_ID],
    () => {
      return axiosInstance.get(apiRoutes.agent.me, {});
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchInterval: false,
      retry: false,
    },
  );
}

export default useMyAgent;
