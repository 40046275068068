import {
  IUserInfo,
  mapCountryCodeToLanguage,
  mapLanguageToCountryCode,
} from '@/services/api/user/useUserInfo';
import { useMutation } from 'react-query';
import type { DefaultServerError } from '@/services/api/types';
import axiosInstance from '@/services/api/axiosInstance';
import type { AxiosResponse } from 'axios';
import useCompanyId from '@/hooks/useCompanyId';
function useEditUserInfo(USER_ID: string) {
  const { apiRoutes } = useCompanyId();

  return useMutation<AxiosResponse<IUserInfo>, DefaultServerError, Partial<IUserInfo>>(
    (input) => {
      const fmData = new FormData();
      Object.entries(input).forEach(([key, value]) => {
        if (value) {
          fmData.append(key, value);
        }
      });
      return axiosInstance.put(apiRoutes.user.detail(USER_ID), fmData);
    },
    {
      onSuccess: async (data) => {
        const dbCountryCode = data?.data?.user?.language;
        const language = mapCountryCodeToLanguage(dbCountryCode);
        window.localStorage.setItem('umi_locale', language);
        window.location.reload();
      },
    },
  );
}

export default useEditUserInfo;
