import { useMutation } from 'react-query';
import type { DefaultServerError, ICustomer } from '@/services/api/types';
import axiosInstance from '@/services/api/axiosInstance';
import type { AxiosResponse } from 'axios';
import useCompanyId from '@/hooks/useCompanyId';

function useUpdateCustomer(CUSTOMER_ID: string) {
  const { apiRoutes } = useCompanyId();

  return useMutation<AxiosResponse<ICustomer>, DefaultServerError, Partial<ICustomer>>((input) => {
    return axiosInstance.put(apiRoutes.customer.detail(CUSTOMER_ID), {
      ...input,
    });
  });
}

export default useUpdateCustomer;
