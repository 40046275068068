import { useQuery } from 'react-query';
import axiosInstance from '@/services/api/axiosInstance';
import type { ICallCampaignCustomer, IPaginate, ServerFormError } from '@/services/api/types';
import type { AxiosResponse } from 'axios';
import useCompanyId from '@/hooks/useCompanyId';
import { useCustomersKey } from '@/services/api/customer/useCustomers';

function useListCallCampaignCustomersByCustomer(CUSTOMER_ID: string, enabled = false) {
  const { COMPANY_ID, apiRoutes } = useCompanyId();

  return useQuery<AxiosResponse<IPaginate<ICallCampaignCustomer>>, ServerFormError>(
    [COMPANY_ID, useCustomersKey, CUSTOMER_ID, 'call_campaign_customers'],
    () => {
      return axiosInstance.get(apiRoutes.customer.call_campaign_customer(CUSTOMER_ID));
    },
    {
      enabled: enabled,
    },
  );
}

export default useListCallCampaignCustomersByCustomer;
