import kh from './app/kh.json';
export default {
  ...kh,
  'use_cases.REAL_ESTATE': 'អចលនទ្រព្យ',
  'use_cases.INSURANCE': 'ការធានារ៉ាប់រង',
  'use_cases.FINANCE': 'ហិរញ្ញវត្ថុ',
  'use_cases.EDUCATION': 'ការអប់រំ',
  'use_cases.BEAUTY': 'ផ្សេងទៀត',
  'use_cases.SOFTWARE': 'កម្មវិធី',
  'use_cases.ADVISORY': 'អ្នកប្រឹក្សាពន្ធ',
  'use_cases.MEDICAL': 'វេជ្ជសាស្ត្រ',
  'use_cases.EVENT': 'ព្រឹត្តិការណ៍',
  'use_cases.HIRING': 'ភ្នាក់ងារជ្រើសរើស',
  'use_cases.OTHER': 'ផ្សេងទៀត',
  'problems.title': 'បញ្ហាអាជីវកម្ម',
  'header.homepage': 'ទំព័រដើម',
  'header.solution': 'ដំណោះស្រាយ',
  'header.product': 'ផលិតផល',
  'header.customer': 'អតិថិជន',
  'header.help': 'ការណែនាំអ្នកប្រើប្រាស់',
  'header.pricing': 'តម្លៃ',
  'header.blog': 'ប្លុក',
  'header.start_button': 'សាកល្បងវា',
  'header.login': 'Login',
  'header.contact': 'ទំនាក់ទំនង',
  'header.use_cases': 'ករណីប្រើប្រាស់',
  'footer.contact': 'ព័ត៌មានទំនាក់ទំនង',
  'footer.contact_number': 'ផ្នែកលក់: 0249 9999 468',
  'footer.company_name': 'UCALL TECHNOLOGY CORPORATION',
  'footer.address': 'អាសយដ្ឋាន',
  'footer.company_address':
    'ទេ 221 Tran Quang Khai, Tan Dinh Ward, District 1\nHo Chi Minh City, Vietnam',
  'footer.company_license':
    'វិញ្ញាបនបត្រចុះបញ្ជីពាណិជ្ជកម្មលេខ 0109690915 ចេញដំបូងដោយការិយាល័យចុះបញ្ជីពាណិជ្ជកម្ម - នាយកដ្ឋានផែនការ និងវិនិយោគហាណូយ នៅថ្ងៃទី 1 ខែកក្កដា ឆ្នាំ 2021',
  'footer.terms_and_conditions': 'គោលការណ៍ និងលក្ខខណ្ឌ',
  'footer.terms_using': 'លក្ខខណ្ឌប្រើប្រាស់',
  'footer.terms_privacy': 'គោលការណ៍ឯកជនភាព',
  'footer.terms_delivery': 'គោលការណ៍ដឹកជញ្ជូន',
  'footer.terms_refund': 'គោលការណ៍បង្វិលសង',
  'footer.terms_payment': 'គោលការណ៍បង់ប្រាក់',
  'banner1.title': 'វេទិកា Callbot ដោយស្វ័យប្រវត្តិ',
  'banner1.subtitle':
    'ដំណោះស្រាយការហៅទូរសព្ទដោយស្វ័យប្រវត្តិ Telesales, Telemarketing & Customer Care\nជួយអាជីវកម្មបង្កើនប្រសិទ្ធភាពការចំណាយ ពេលវេលា និងបង្កើនប្រសិទ្ធភាពនៃការលក់។',
  'banner1.button1': 'ទាក់ទងអ្នកប្រឹក្សា',
  'banner1.button2': 'បទពិសោធន៍ឥតគិតថ្លៃ',
  'banner1.button2b': 'ពិសោធវាឥឡូវនេះ',
  'use_case.title': 'កម្មវិធីដំណោះស្រាយ',
  'use_case.subtitle':
    'ការ​លក់​តាម​ទូរស័ព្ទ​ដោយ​ស្វ័យ​ប្រវត្តិ ការ​លក់​តាម​ទូរស័ព្ទ និង​ដំណោះស្រាយ​ការ​ថែទាំ​អតិថិជន',
  'demo.title': 'បទពិសោធន៍ Callbot',
  'demo.select_script': 'ការជ្រើសរើសអេក្រង់',
  'demo.real_estate': 'អចលនទ្រព្យ (ការកត់ត្រា)',
  'demo.finance': 'ហិរញ្ញវត្ថុ (ការកត់ត្រា)',
  'demo.education': 'ការអប់រំ (ផ្ទាល់ខ្លួន)',
  'demo.beauty': 'ហាងកែសម្ផស្ស (ផ្ទាល់ខ្លួន)',
  'demo.form.name.required_message': 'សូមបញ្ចូលឈ្មោះរបស់អ្នក',
  'demo.form.name.placeholder': 'ឈ្មោះរបស់អ្នក',
  'demo.form.phone_number.required_message': 'សូមបញ្ចូលលេខទូរស័ព្ទ',
  'demo.form.phone_number.placeholder': 'លេខទូរស័ព្ទ',
  'demo.form.gender.placeholder': 'សូមជ្រើសរើសចំណងជើង',
  'demo.form.gender.male': 'បង',
  'demo.form.gender.female': 'បងស្រី',
  'demo.form.call': 'សាកល្បងការហៅ',
  'products.CALLBOT_AI_AUTO_CALL': 'Callbot AI ហៅដោយស្វ័យប្រវត្តិ',
  'products.CALL_CENTER': 'Call Center',
  'products.CUSTOMERS_DATA': 'ស្វែងរកអតិថិជន',
  'solution.title': 'ដំណោះស្រាយ UCALL',
  'solution.subtitle': 'Telesales, Telemarketing & Automated Customer Care',
  'solution.data.title': 'ស្វែងរកទិន្នន័យអតិថិជន',
  'solution.data.description1': '• ប្រមូលទិន្នន័យអតិថិជនដោយស្វ័យប្រវត្តិពីបណ្តាញសង្គម',
  'solution.data.description2': '• ប្រមូលលេខទូរស័ព្ទអតិថិជនអាជីវកម្មដោយស្វ័យប្រវត្តិតាមឧស្សាហកម្ម',
  'solution.call.title': 'ការហៅដោយស្វ័យប្រវត្តិ Callbot',
  'solution.call.description1': '• ធ្វើការហៅទូរសព្ទក្នុងពេលដំណាលគ្នារាប់ពាន់ទៅកាន់អតិថិជន',
  'solution.call.description2': '• ការរចនាស្គ្រីបឆ្លាតវៃ អន្តរកម្មធម្មជាតិ',
  'solution.call.description3': '• វិចារណញាណ របាយការណ៍លទ្ធផលយុទ្ធនាការងាយយល់',
  'solution.cta': 'ស្វែងយល់បន្ថែម',

  'callbot.solution1.title': 'ដំណោះស្រាយ',
  'callbot.solution1.subtitle':
    'ការលក់តាមទូរស័ព្ទដោយស្វ័យប្រវត្តិ ទីផ្សារតាមទូរស័ព្ទ និងដំណោះស្រាយថែទាំអតិថិជន',
  'callbot.solution1.part_1.text':
    'ស្វែងរកទិន្នន័យអតិថិជនសក្តានុពលពីអតិថិជនអាជីវកម្ម និងបណ្តាញសង្គម',
  'callbot.solution1.part_2.text':
    'ដំណោះស្រាយការហៅដោយស្វ័យប្រវត្តិជួយឱ្យអាជីវកម្មធ្វើការហៅទូរសព្ទដោយស្វ័យប្រវត្តិ និងឆ្លាតវៃរាប់ពាន់ដោយគ្រាន់តែចុច 1 ដង',

  'call_center.solution1.title': 'ដំណោះស្រាយ',
  'call_center.solution1.subtitle':
    'ការលក់តាមទូរស័ព្ទដោយស្វ័យប្រវត្តិ ទីផ្សារតាមទូរស័ព្ទ និងដំណោះស្រាយថែទាំអតិថិជន',
  'call_center.solution1.part_1.text':
    'ស្វែងរកទិន្នន័យអតិថិជនសក្តានុពលពីអតិថិជនអាជីវកម្ម និងបណ្តាញសង្គម',
  'call_center.solution1.part_2.text':
    'ដំណោះស្រាយការហៅដោយស្វ័យប្រវត្តិជួយឱ្យអាជីវកម្មធ្វើការហៅទូរសព្ទដោយស្វ័យប្រវត្តិ និងឆ្លាតវៃរាប់ពាន់ដោយគ្រាន់តែចុច 1 ដង',

  'uleads.solution1.title': 'ដំណោះស្រាយ',
  'uleads.solution1.subtitle':
    'ការលក់តាមទូរស័ព្ទដោយស្វ័យប្រវត្តិ ទីផ្សារតាមទូរស័ព្ទ និងដំណោះស្រាយថែទាំអតិថិជន',
  'uleads.solution1.part_1.text':
    'ស្វែងរកទិន្នន័យអតិថិជនសក្តានុពលពីអតិថិជនអាជីវកម្ម និងបណ្តាញសង្គម',
  'uleads.solution1.part_2.text':
    'ដំណោះស្រាយការហៅដោយស្វ័យប្រវត្តិជួយឱ្យអាជីវកម្មធ្វើការហៅទូរសព្ទដោយស្វ័យប្រវត្តិ និងឆ្លាតវៃរាប់ពាន់ដោយគ្រាន់តែចុច 1 ដង',

  'callbot.problems.title': 'បញ្ហាអាជីវកម្ម',
  'callbot.problems.problem1.title': 'បាត់ការនាំមុខ',
  'callbot.problems.problem1.description': 'អាជីវកម្មមានការលំបាកក្នុងការបង្កើតគុណភាពនាំមុខ',
  'callbot.problems.problem2.title': 'Telesales ចំណាយពេលយូរ ប៉ុន្តែមិនដំណើរការ',
  'callbot.problems.problem2.description':
    'សមត្ថភាពបុគ្គលិកក្នុងការគ្រប់គ្រងការហៅទូរសព្ទមានកម្រិតទាប ហើយវាត្រូវការពេលវេលាដើម្បីគ្រប់គ្រងគុណភាពការហៅទូរសព្ទរបស់បុគ្គលិកដែលនាំឱ្យបាត់បង់អតិថិជនទៅកាន់ដៃគូប្រកួតប្រជែង',
  'callbot.problems.problem3.title': 'ប្រើប្រាស់ធនធានដើម្បីដំណើរការ switchboard',
  'callbot.problems.problem3.description':
    'អាជីវកម្មចំណាយប្រាក់ច្រើនដើម្បីបង់ថ្លៃសេវាទូរគមនាគមន៍ បុគ្គលិក ការិយាល័យ ការគ្រប់គ្រង និងទិញឧបករណ៍ switchboard ដែលមានតម្លៃថ្លៃ ប៉ុន្តែគ្មានប្រសិទ្ធភាព។',
  'call_center.problems.title': 'បញ្ហាអាជីវកម្ម',
  'call_center.problems.problem1.title': 'បាត់ការនាំមុខ',
  'call_center.problems.problem1.description': 'អាជីវកម្មមានការលំបាកក្នុងការបង្កើតគុណភាពនាំមុខ',
  'call_center.problems.problem2.title': 'Telesales ចំណាយពេលយូរ ប៉ុន្តែមិនដំណើរការ',
  'call_center.problems.problem2.description':
    'សមត្ថភាពបុគ្គលិកក្នុងការគ្រប់គ្រងការហៅទូរសព្ទមានកម្រិតទាប ហើយវាត្រូវការពេលវេលាដើម្បីគ្រប់គ្រងគុណភាពការហៅទូរសព្ទរបស់បុគ្គលិកដែលនាំឱ្យបាត់បង់អតិថិជនទៅកាន់ដៃគូប្រកួតប្រជែង',
  'call_center.problems.problem3.title': 'ប្រើប្រាស់ធនធានដើម្បីដំណើរការ switchboard',
  'call_center.problems.problem3.description':
    'អាជីវកម្មចំណាយប្រាក់ច្រើនដើម្បីបង់ថ្លៃសេវាទូរគមនាគមន៍ បុគ្គលិក ការិយាល័យ ការគ្រប់គ្រង និងទិញឧបករណ៍ switchboard ដែលមានតម្លៃថ្លៃ ប៉ុន្តែគ្មានប្រសិទ្ធភាព។',
  'uleads.problems.title': 'បញ្ហាអាជីវកម្ម',
  'uleads.problems.problem1.title': 'បាត់អ្នកដឹកនាំ',
  'uleads.problems.problem1.description': 'អាជីវកម្មមានការលំបាកក្នុងការបង្កើតគុណភាពនាំមុខ',
  'uleads.problems.problem2.title': 'Telesales កំពុងប្រើពេលយូរ ប៉ុន្តែមិនដំណើរការ',
  'uleads.problems.problem2.description':
    'សមត្ថភាពបុគ្គលិកក្នុងការគ្រប់គ្រងការហៅទូរសព្ទមានកម្រិតទាប ហើយវាត្រូវការពេលវេលាដើម្បីគ្រប់គ្រងគុណភាពការហៅទូរសព្ទរបស់បុគ្គលិកដែលនាំឱ្យបាត់បង់អតិថិជនទៅកាន់ដៃគូប្រកួតប្រជែង',
  'uleads.problems.problem3.title': 'ធនធានសម្រាប់ដំណើរការក្ដារចុច',
  'uleads.problems.problem3.description':
    'អាជីវកម្មចំណាយប្រាក់ច្រើនដើម្បីបង់ថ្លៃសេវាទូរគមនាគមន៍ បុគ្គលិក ការិយាល័យ ការគ្រប់គ្រង និងទិញឧបករណ៍ switchboard ដែលមានតម្លៃថ្លៃ ប៉ុន្តែគ្មានប្រសិទ្ធភាព។',

  'callbot.features.title': 'លក្ខណៈពិសេស',
  'callbot.features.feature1.title': 'ស្វែងរកទិន្នន័យអតិថិជន',
  'callbot.features.feature1.0.title': 'អតិថិជន B2B',
  'callbot.features.feature1.0.description': 'ជ្រើសរើសអតិថិជន B2B ដោយតម្រងឆ្លាតវៃ',
  'callbot.features.feature1.1.title': 'ទិន្នន័យបណ្ដាញសង្គម',
  'callbot.features.feature1.1.description': 'ស្វែងរកទិន្នន័យអតិថិជនពីបណ្តាញសង្គម',
  'callbot.features.feature2.title': 'បង្កើតស្គ្រីប',
  'callbot.features.feature2.0.title': 'ការរចនាសេណារីយ៉ូ',
  'callbot.features.feature2.0.description':
    'ការកសាងងាយស្រួល បត់បែន ឬផ្លាស់ប្តូរសេណារីយ៉ូឱ្យសមនឹងតម្រូវការរបស់អ្នក',
  'callbot.features.feature2.1.title': 'គំរូសេណារីយ៉ូ',
  'callbot.features.feature2.1.description':
    'គំរូសេណារីយ៉ូ 200+ ដែលមាននៅក្នុងជាង 10 វិស័យផ្សេងៗគ្នា (B2B, អចលនទ្រព្យ, ហិរញ្ញវត្ថុ, ការធានារ៉ាប់រង, ការអប់រំ, ការថែទាំសុខភាព, ការថែរក្សាសម្ផស្ស, ការធ្វើដំណើរ, សណ្ឋាគារ, លក់រាយ, កម្មវិធី) ,...)',
  'callbot.features.feature3.title': 'អន្តរកម្មធម្មជាតិ',
  'callbot.features.feature3.0.title': 'សំឡេងម៉ាស៊ីន',
  'callbot.features.feature3.0.description': 'សំឡេងបុរស និងស្ត្រីចម្រុះពីតំបន់ផ្សេងៗគ្នា។',
  'callbot.features.feature3.1.title': 'ការថតសំឡេង',
  'callbot.features.feature3.1.description': 'អាចបង្កើតស្គ្រីបជាមួយនឹងសំឡេងមនុស្សពិតដែលបានថតទុក',
  'callbot.features.feature3.2.title': 'ការទទួលស្គាល់ការនិយាយ',
  'callbot.features.feature3.2.description':
    'កំណត់អត្តសញ្ញាណ និងឆ្លើយតបដោយស្វ័យប្រវត្តិចំពោះចម្លើយអតិថិជនដែលអាចបត់បែនបាន',
  'callbot.features.feature4.title': 'របាយការណ៍យុទ្ធនាការ',
  'callbot.features.feature4.0.title': 'របាយការណ៍ទិដ្ឋភាពទូទៅ',
  'callbot.features.feature4.0.description': 'ស្ថិតិទិដ្ឋភាពទូទៅនៃយុទ្ធនាការហៅតាមវិចារណញាណ',
  'callbot.features.feature4.1.title': 'គំរូសេណារីយ៉ូ',
  'callbot.features.feature4.1.description':
    'រក្សាទុកមាតិកាការហៅទូរស័ព្ទពីការកត់ត្រាការហៅ ស្គាល់អត្ថបទ និងចាត់ថ្នាក់អតិថិជនដោយស្វ័យប្រវត្តិ',

  'call_center.features.title': 'លក្ខណៈពិសេស',
  'call_center.features.feature1.title': 'ស្វែងរកទិន្នន័យអតិថិជន',
  'call_center.features.feature1.0.title': 'អតិថិជន B2B',
  'call_center.features.feature1.0.description': 'ជ្រើសរើសអតិថិជន B2B ដោយតម្រងឆ្លាតវៃ',
  'call_center.features.feature1.1.title': 'ទិន្នន័យបណ្ដាញសង្គម',
  'call_center.features.feature1.1.description': 'ស្វែងរកទិន្នន័យអតិថិជនពីបណ្តាញសង្គម',
  'call_center.features.feature2.title': 'បង្កើតស្គ្រីប',
  'call_center.features.feature2.0.title': 'ការរចនាសេណារីយ៉ូ',
  'call_center.features.feature2.0.description':
    'ការកសាងងាយស្រួល បត់បែន ឬផ្លាស់ប្តូរសេណារីយ៉ូឱ្យសមនឹងតម្រូវការរបស់អ្នក',
  'call_center.features.feature2.1.title': 'គំរូសេណារីយ៉ូ',
  'call_center.features.feature2.1.description':
    'គំរូសេណារីយ៉ូ 200+ ដែលមាននៅក្នុងជាង 10 វិស័យផ្សេងៗគ្នា (B2B, អចលនទ្រព្យ, ហិរញ្ញវត្ថុ, ការធានារ៉ាប់រង, ការអប់រំ, ការថែទាំសុខភាព, ការថែរក្សាសម្ផស្ស, ការធ្វើដំណើរ, សណ្ឋាគារ, លក់រាយ, កម្មវិធី) ,...)',
  'call_center.features.feature3.title': 'អន្តរកម្មធម្មជាតិ',
  'call_center.features.feature3.0.title': 'សំឡេងម៉ាស៊ីន',
  'call_center.features.feature3.0.description': 'សំឡេងបុរស និងស្ត្រីចម្រុះពីតំបន់ផ្សេងៗគ្នា។',
  'call_center.features.feature3.1.title': 'ការថតសំឡេង',
  'call_center.features.feature3.1.description':
    'អាចបង្កើតស្គ្រីបជាមួយនឹងសំឡេងមនុស្សពិតដែលបានកត់ត្រា',
  'call_center.features.feature3.2.title': 'ការទទួលស្គាល់ការនិយាយ',
  'call_center.features.feature3.2.description':
    'កំណត់អត្តសញ្ញាណ និងឆ្លើយតបដោយស្វ័យប្រវត្តិចំពោះចម្លើយអតិថិជនដែលអាចបត់បែនបាន',
  'call_center.features.feature4.title': 'របាយការណ៍យុទ្ធនាការ',
  'call_center.features.feature4.0.title': 'របាយការណ៍ទិដ្ឋភាពទូទៅ',
  'call_center.features.feature4.0.description':
    'ស្ថិតិរបាយការណ៍ទិដ្ឋភាពទូទៅនៃយុទ្ធនាការហៅតាមវិចារណញាណ',
  'call_center.features.feature4.1.title': 'គំរូអេក្រង់',
  'call_center.features.feature4.1.description':
    'រក្សាទុកមាតិកាការហៅទូរស័ព្ទពីការកត់ត្រាការហៅ ស្គាល់អត្ថបទ និងចាត់ថ្នាក់អតិថិជនដោយស្វ័យប្រវត្តិ',

  'uleads.features.title': 'លក្ខណៈពិសេស',
  'uleads.features.feature1.title': 'ស្វែងរកទិន្នន័យអតិថិជន',
  'uleads.features.feature1.0.title': 'អតិថិជន B2B',
  'uleads.features.feature1.0.description': 'ជ្រើសរើសអតិថិជន B2B ដោយតម្រងឆ្លាតវៃ',
  'uleads.features.feature1.1.title': 'ទិន្នន័យបណ្ដាញសង្គម',
  'uleads.features.feature1.1.description': 'ស្វែងរកទិន្នន័យអតិថិជនពីបណ្តាញសង្គម',
  'uleads.features.feature2.title': 'បង្កើតស្គ្រីប',
  'uleads.features.feature2.0.title': 'ការរចនាសេណារីយ៉ូ',
  'uleads.features.feature2.0.description':
    'ការកសាងងាយស្រួល បត់បែន ឬផ្លាស់ប្តូរសេណារីយ៉ូឱ្យសមនឹងតម្រូវការរបស់អ្នក',
  'uleads.features.feature2.1.title': 'គំរូអេក្រង់',
  'uleads.features.feature2.1.description':
    'គំរូសេណារីយ៉ូ 200+ ដែលមាននៅក្នុងជាង 10 វិស័យផ្សេងៗគ្នា (B2B, អចលនទ្រព្យ, ហិរញ្ញវត្ថុ, ការធានារ៉ាប់រង, ការអប់រំ, ការថែទាំសុខភាព, ការថែរក្សាសម្ផស្ស, ការធ្វើដំណើរ, សណ្ឋាគារ, លក់រាយ, កម្មវិធី) ,...)',
  'uleads.features.feature3.title': 'អន្តរកម្មធម្មជាតិ',
  'uleads.features.feature3.0.title': 'សំឡេងម៉ាស៊ីន',
  'uleads.features.feature3.0.description': 'សំឡេងបុរស និងស្ត្រីចម្រុះពីតំបន់ផ្សេងៗគ្នា។',
  'uleads.features.feature3.1.title': 'សំឡេងដែលបានថតទុក',
  'uleads.features.feature3.1.description': 'អាចបង្កើតស្គ្រីបជាមួយនឹងសំឡេងមនុស្សពិតដែលបានថតទុក',
  'uleads.features.feature3.2.title': 'ការទទួលស្គាល់ការនិយាយ',
  'uleads.features.feature3.2.description':
    'កំណត់អត្តសញ្ញាណ និងឆ្លើយតបដោយស្វ័យប្រវត្តិចំពោះចម្លើយអតិថិជនដែលអាចបត់បែនបាន',
  'uleads.features.feature4.title': 'របាយការណ៍យុទ្ធនាការ',
  'uleads.features.feature4.0.title': 'របាយការណ៍ទិដ្ឋភាពទូទៅ',
  'uleads.features.feature4.0.description': 'ស្ថិតិរបាយការណ៍ទិដ្ឋភាពទូទៅនៃយុទ្ធនាការហៅដោយវិចារណញាណ',
  'uleads.features.feature4.1.title': 'គំរូសេណារីយ៉ូ',
  'uleads.features.feature4.1.description':
    'រក្សាទុកមាតិកាការហៅទូរស័ព្ទពីការកត់ត្រាការហៅ ស្គាល់អត្ថបទ និងចាត់ថ្នាក់អតិថិជនដោយស្វ័យប្រវត្តិ',

  'contact_form.modal_title': 'ទំនាក់ទំនងប្រឹក្សាយោបល់',
  'contact_form.form.name.label': 'នាមខ្លួន និងនាមត្រកូល',
  'contact_form.thank_you': 'យើងនឹងទាក់ទងអ្នកឱ្យបានឆាប់តាមដែលអាចធ្វើទៅបាន',
  'contact_form.form.email.label': 'អាសយដ្ឋានអ៊ីមែល',
  'contact_form.form.phone_number.label': 'លេខទូរស័ព្ទ',
  'contact_form.form.industry.label': 'ឧស្សាហកម្ម',
  'contact_form.form.demands.label': 'ការហៅទូរសព្ទ/ខែ',
  'contact_form.form.notes.label': 'ចំណាំ',
  'contact_form.form.send_button': 'Gửi',
  'contact_form.industries.REAL_ESTATE': 'អចលនទ្រព្យ',
  'contact_form.industries.FINANCE': 'ហិរញ្ញវត្ថុ',
  'contact_form.industries.INSURANCE': 'ការធានារ៉ាប់រង',
  'contact_form.industries.EDUCATION': 'ការអប់រំ',
  'contact_form.industries.OTHER': 'Khác',
  'contact_form.industries.NULL': 'មិនស្គាល់',
  'error_messages.required': 'មិនអាចទទេ',
  'success_messages.calling': 'សូមទូរស័ព្ទមកអ្នកឆាប់ៗនេះ...',
  'pricing.voice.title': 'បញ្ជីតម្លៃ',
  'pricing.promotion.title': 'ការផ្សព្វផ្សាយ',
  'pricing.expire.title': 'រយៈពេល',
  'pricing.voice.price': 'តម្លៃ',
  'pricing.voice.subtitle':
    'តម្លៃពិតប្រាកដនៃការហៅទូរស័ព្ទត្រូវបានគណនានៅលើប្លុក 6s ដើម្បីបង្កើនប្រសិទ្ធភាពការចំណាយសម្រាប់អាជីវកម្ម។',
  'pricing.voice.unit': '/min',
  'pricing.voice.ucall_fee': 'តម្លៃវេទិកា UCALL',
  'pricing.voice.carrier_fee': 'អត្រាទូរគមនាគមន៍',
  'pricing.voice.personalize_fee': 'ការកំណត់តម្លៃផ្ទាល់ខ្លួន',
  'pricing.voice.custom_fee': 'ការគិតថ្លៃទូរគមនាគមន៍នៅពេលប្រើលេខកុងតាក់ផ្ទាល់ខ្លួនរបស់សហគ្រាស',
  'pricing.voice.custom_fee_description':
    'អតិថិជនបង់ប្រាក់ដោយផ្ទាល់ទៅក្រុមហ៊ុនដឹកជញ្ជូន អាស្រ័យលើក្រុមហ៊ុនដឹកជញ្ជូនដែលអតិថិជនចុះឈ្មោះ',
  'pricing.plan.title': 'កញ្ចប់សម្រាប់លក់',
  'pricing.plan.subtitle': '1M = 1,000,000 VND',

  'callbot.solution_banner.title': 'វេទិកា Callbot ដោយស្វ័យប្រវត្តិ',
  'callbot.solution_banner.text1': 'ដំណោះស្រាយការហៅដោយស្វ័យប្រវត្តិដើម្បីជួយអាជីវកម្ម',
  'callbot.solution_banner.text2': '- ការបង្កើនប្រសិទ្ធភាពបុគ្គលិក និងការចំណាយប្រតិបត្តិការ',
  'callbot.solution_banner.text3': '- ការសន្សំពេលវេលា Telesales និងការថែទាំអតិថិជន',
  'callbot.solution_banner.text4': '- បង្កើនប្រសិទ្ធភាពអាជីវកម្ម',
  'call_center.solution_banner.title': 'វេទិកា Callbot ដោយស្វ័យប្រវត្តិ',
  'call_center.solution_banner.text1': 'ដំណោះស្រាយការហៅដោយស្វ័យប្រវត្តិដើម្បីជួយអាជីវកម្ម',
  'call_center.solution_banner.text2': '- ការបង្កើនប្រសិទ្ធភាពបុគ្គលិក និងការចំណាយប្រតិបត្តិការ',
  'call_center.solution_banner.text3': '- ការសន្សំពេលវេលា Telesales និងការថែទាំអតិថិជន',
  'call_center.solution_banner.text4': '- បង្កើនប្រសិទ្ធភាពអាជីវកម្ម',
  'uleads.solution_banner.title': 'វេទិកា Callbot ស្វ័យប្រវត្តិ',
  'uleads.solution_banner.text1': 'ដំណោះស្រាយការហៅដោយស្វ័យប្រវត្តិដើម្បីជួយអាជីវកម្ម',
  'uleads.solution_banner.text2': '- ការបង្កើនប្រសិទ្ធភាពបុគ្គលិក និងការចំណាយប្រតិបត្តិការ',
  'uleads.solution_banner.text3': '- ការសន្សំពេលវេលា Telesales និងការថែទាំអតិថិជន',
  'uleads.solution_banner.text4': '- បង្កើនប្រសិទ្ធភាពអាជីវកម្ម',

  'callbot.use_case.title': 'កម្មវិធីដំណោះស្រាយ',
  'callbot.use_cases.REAL_ESTATE': 'ទ្រព្យសម្បត្តិ',
  'use_cases.callbot.REAL_ESTATE.detail':
    'អចលន​ទ្រព្យ​ត្រូវ​បាន​គេ​ចាត់​ទុក​ថា​ជា​ផ្នែក​មួយ​ក្នុង​ចំណោម​វិស័យ​អាជីវកម្ម​ដែល​មាន​ការ​រីក​ចម្រើន​និង​ការ​អភិវឌ្ឍ​លឿន​បំផុត​នា​ពេល​បច្ចុប្បន្ន​នេះ។ ដូច្នេះហើយ វាជារឿងសំខាន់សម្រាប់វិនិយោគិន ក្នុងការស្វែងរកមធ្យោបាយផ្សព្វផ្សាយ និងទីផ្សារជានិច្ច។ មធ្យោបាយដ៏ពេញនិយមមួយក្នុងការធ្វើអ្វីៗនាពេលបច្ចុប្បន្ននេះ ដែលអង្គភាពជាច្រើនជ្រើសរើសគឺបង្កើត ឬប្រភពក្រុមការងារ Telesale ដើម្បីធ្វើការហៅទៅកាន់អតិថិជន។\n' +
    '\n' +
    'ជាមួយនឹងទីផ្សារបច្ចេកវិទ្យាដែលកំពុងរីកចម្រើន ការជ្រើសរើសកម្មវិធីបច្ចេកវិទ្យាសម្រាប់ការថែទាំអតិថិជនកាន់តែប្រសើរកំពុងត្រូវបានចាប់អារម្មណ៍ដោយអង្គភាពជាច្រើន។ មួយក្នុងចំណោមពួកគេគឺជាដំណោះស្រាយការហៅដោយស្វ័យប្រវត្តិនៃក្ដារចុចនិម្មិត Callbot \n',
  'callbot.use_cases.FINANCE': 'ហិរញ្ញវត្ថុ',
  'use_cases.callbot.FINANCE.detail':
    'សហគ្រាសហិរញ្ញវត្ថុគឺជាប្រភេទនៃគ្រឹះស្ថានឥណទានមិនមែនធនាគារ។ អង្គការនេះមានមុខងារប្រើប្រាស់ដើមទុនផ្ទាល់ខ្លួន ដើមទុនដែលបានកៀរគរ និងប្រភពដើមទុនផ្សេងទៀតដើម្បីផ្តល់ប្រាក់កម្ចី វិនិយោគ ផ្តល់សេវាប្រឹក្សាផ្នែកហិរញ្ញវត្ថុ និងរូបិយវត្ថុ និងអនុវត្តសេវាកម្មមួយចំនួនទៀតស្របតាមបទប្បញ្ញត្តិនៃច្បាប់នេះ។ ច្បាប់។ ដូច្នេះហើយ ស្ថាប័នឥណទានតែងតែស្វែងរកមធ្យោបាយផ្សព្វផ្សាយពាណិជ្ជកម្ម និងទីផ្សារដល់អតិថិជនរបស់ពួកគេ។ មធ្យោបាយដ៏ពេញនិយមមួយក្នុងការធ្វើអ្វីៗនាពេលបច្ចុប្បន្ននេះ ដែលអង្គភាពជាច្រើនជ្រើសរើសគឺបង្កើត ឬប្រភពក្រុមការងារ Telesale ដើម្បីធ្វើការហៅទៅកាន់អតិថិជន។\n' +
    '\n' +
    'ការយល់ដឹងពីការលំបាកដែលអាជីវកម្មហិរញ្ញវត្ថុកំពុងប្រឈមមុខ ដំណោះស្រាយ Callbot Auto Call បានកើតមកដើម្បីទៅដល់អតិថិជនយ៉ាងឆាប់រហ័ស និងបង្កើនសមត្ថភាពអាជីវកម្មរបស់អាជីវកម្មហិរញ្ញវត្ថុយ៉ាងខ្លាំង។ \n',
  'callbot.use_cases.INSURANCE': 'ការធានារ៉ាប់រង',
  'use_cases.callbot.INSURANCE.detail':
    'នៅក្នុងទីផ្សារនាពេលបច្ចុប្បន្ននេះ ប្រភេទធានារ៉ាប់រងត្រូវបានបែងចែកយ៉ាងពិសេសដោយផ្អែកលើលក្ខណៈនៃមុខវិជ្ជា ហើយអាជីវកម្មធានារ៉ាប់រងជាច្រើនកំពុងអភិវឌ្ឍយ៉ាងខ្លាំង ដោយបន្តចេញកញ្ចប់ផលិតផលថ្មីៗ ដើម្បីបំពេញតម្រូវការចម្រុះ។ តម្រូវការរបស់អតិថិជន។ ទន្ទឹមនឹងនោះ ការកែលម្អគុណភាពសេវាកម្មគឺជាកត្តាសំខាន់មួយដើម្បីជួយអាជីវកម្មធានារ៉ាប់រងបង្កើនអត្ថប្រយោជន៍ប្រកួតប្រជែងរបស់ពួកគេ ទាក់ទាញអតិថិជនថ្មី និងរក្សាអតិថិជនស្មោះត្រង់។ \n' +
    '\n' +
    'ដូច្នេះបញ្ហាគឺថា ត្រូវតែមានដំណោះស្រាយសមស្របមួយ ដើម្បីគាំទ្រដល់អាជីវកម្ម ដើម្បីដោះស្រាយបញ្ហាទាំងអស់ខាងលើ។ នោះហើយជាមូលហេតុដែលដំណោះស្រាយ Callbot Ai Auto Call បានកើត។\n',
  'callbot.use_cases.EDUCATION': 'ការអប់រំ',
  'use_cases.callbot.EDUCATION.detail':
    'បច្ចុប្បន្ននេះ មានមជ្ឈមណ្ឌលអប់រំជាច្រើនដែលកំពុងតស៊ូជាមួយនឹងបញ្ហានៃការផ្ទុកទិន្នន័យលើសទម្ងន់ក្នុងរដូវកាលកំពូល ក៏ដូចជាការចំណាយលើបុគ្គលិកច្រើនដើម្បីចាត់ថ្នាក់ឯកសារអតិថិជនឆៅ។ មជ្ឈមណ្ឌលអប់រំតែងតែមាន Telesales ដើម្បីស្វែងរកសិស្សថ្មីជារៀងរាល់ថ្ងៃ។ លើសពីនេះ មជ្ឈមណ្ឌលក៏ត្រូវអំពាវនាវដល់សិស្សានុសិស្សអំពីកាលវិភាគនៃវគ្គបណ្តុះបណ្តាល និងវគ្គបណ្តុះបណ្តាល ក៏ដូចជាការប្រឹក្សា និងថែទាំសិស្សចាស់ ឱ្យយល់ដឹងបន្ថែមពីតម្រូវការរបស់សិស្ស។ ជាមួយនឹងការអនុវត្តការហៅទូរសព្ទតាម Telesales និងការថែទាំអតិថិជន មជ្ឈមណ្ឌលអប់រំប្រើប្រាស់ថវិកាយ៉ាងច្រើន។ \n' +
    '\n' +
    'ដើម្បីដោះស្រាយការលំបាកខាងលើ UCALL បានដាក់ឱ្យដំណើរការនូវដំណោះស្រាយ callbot ដោយស្វ័យប្រវត្តិ ដើម្បីដោះស្រាយបញ្ហាខាងលើយ៉ាងឆាប់រហ័ស និងងាយស្រួល។ បច្ចុប្បន្ន ដំណោះស្រាយ callbot ដោយស្វ័យប្រវត្តិកំពុងត្រូវបានចាប់អារម្មណ៍ និងជ្រើសរើសដោយស្ថាប័នអប់រំជាច្រើន ដើម្បីគាំទ្រអាជីវកម្មរបស់ពួកគេឱ្យកាន់តែប្រសើរឡើង\n',

  'callbot.use_cases.BEAUTY': 'បាទ',
  'callbot.use_cases.BEAUTY2': 'សម្រស់',
  'callbot.use_cases.SOFTWARE': 'កម្មវិធី',
  'callbot.use_cases.ADVISORY': 'អ្នកប្រឹក្សាពន្ធ',
  'callbot.use_cases.MEDICAL': 'ទឹក',
  'callbot.use_cases.EVENT': 'ព្រឹត្តិការណ៍',
  'callbot.use_cases.HIRING': 'ភ្នាក់ងារជ្រើសរើស',
  'callbot.use_cases.OTHER': 'បាទ',
  'use_cases.callbot.OTHER.លម្អិត':
    'បច្ចុប្បន្ន នៅពេលដែលទីផ្សារបច្ចេកវិទ្យាកំពុងរីកចម្រើន។ នេះមានន័យថាការលេចឡើងនៃដំណោះស្រាយបច្ចេកវិទ្យាដើម្បីគាំទ្រដល់អាជីវកម្មកាន់តែច្រើនឡើង។ មួយក្នុងចំណោមពួកគេគឺជារូបរាងរបស់ Callbot ។ ជាមួយនឹងគោលបំណងដើម្បីឈានទៅដល់អតិថិជនយ៉ាងឆាប់រហ័ស និងបង្កើនសមត្ថភាពអាជីវកម្មយ៉ាងសំខាន់។ \n' +
    '\n' +
    'ដូច្នេះហើយ ដំណោះស្រាយ callbot ដោយស្វ័យប្រវត្តិកំពុងត្រូវបានចាប់អារម្មណ៍ និងជ្រើសរើសដោយអាជីវកម្មជាច្រើន ដើម្បីគាំទ្រអាជីវកម្មរបស់ពួកគេឱ្យកាន់តែប្រសើរឡើង។ ដើម្បីអាចដឹកនាំឧស្សាហកម្ម; ក្រុមហ៊ុនតែងតែព្យាយាមបំពេញចិត្តអតិថិជនរបស់ខ្លួន។ ដូច្នេះជម្រើសនៃ callbot ត្រូវបានចាត់ទុកថាជាដំណោះស្រាយមួយដើម្បីកែលម្អបទពិសោធន៍របស់អតិថិជន។ នាំអាជីវកម្មរបស់អ្នកទៅកម្រិតបន្ទាប់។\n',

  'call_center.use_case.title': 'កម្មវិធីដំណោះស្រាយ',
  'call_center.use_cases.REAL_ESTATE': 'អចលនទ្រព្យ',
  'use_cases.call_center.REAL_ESTATE.detail':
    'អចលន​ទ្រព្យ​ត្រូវ​បាន​គេ​ចាត់​ទុក​ថា​ជា​ផ្នែក​មួយ​ក្នុង​ចំណោម​វិស័យ​អាជីវកម្ម​ដែល​មាន​ការ​រីក​ចម្រើន​និង​ការ​អភិវឌ្ឍ​លឿន​បំផុត​នា​ពេល​បច្ចុប្បន្ន​នេះ។ ដូច្នេះហើយ វាជារឿងសំខាន់សម្រាប់វិនិយោគិន ក្នុងការស្វែងរកមធ្យោបាយផ្សព្វផ្សាយ និងទីផ្សារជានិច្ច។ មធ្យោបាយដ៏ពេញនិយមមួយក្នុងការធ្វើអ្វីៗនាពេលបច្ចុប្បន្ននេះ ដែលអង្គភាពជាច្រើនជ្រើសរើសគឺបង្កើត ឬប្រភពក្រុមការងារ Telesale ដើម្បីធ្វើការហៅទៅកាន់អតិថិជន។\n' +
    '\n' +
    'ជាមួយនឹងទីផ្សារបច្ចេកវិទ្យាដែលកំពុងរីកចម្រើន ការជ្រើសរើសកម្មវិធីបច្ចេកវិទ្យាសម្រាប់ការថែទាំអតិថិជនកាន់តែប្រសើរកំពុងត្រូវបានចាប់អារម្មណ៍ដោយអង្គភាពជាច្រើន។ មួយក្នុងចំណោមពួកគេគឺជាដំណោះស្រាយការហៅដោយស្វ័យប្រវត្តិនៃក្ដារចុចនិម្មិត Callbot \n',
  'call_center.use_cases.FINANCE': 'ហិរញ្ញវត្ថុ',
  'use_cases.call_center.FINANCE.detail':
    'សហគ្រាសហិរញ្ញវត្ថុគឺជាប្រភេទនៃគ្រឹះស្ថានឥណទានមិនមែនធនាគារ។ អង្គការនេះមានមុខងារប្រើប្រាស់ដើមទុនផ្ទាល់ខ្លួន ដើមទុនដែលបានកៀរគរ និងប្រភពដើមទុនផ្សេងទៀតដើម្បីផ្តល់ប្រាក់កម្ចី វិនិយោគ ផ្តល់សេវាប្រឹក្សាផ្នែកហិរញ្ញវត្ថុ និងរូបិយវត្ថុ និងអនុវត្តសេវាកម្មមួយចំនួនទៀតស្របតាមបទប្បញ្ញត្តិនៃច្បាប់នេះ។ ច្បាប់។ ដូច្នេះហើយ ស្ថាប័នឥណទានតែងតែស្វែងរកមធ្យោបាយផ្សព្វផ្សាយពាណិជ្ជកម្ម និងទីផ្សារដល់អតិថិជនរបស់ពួកគេ។ មធ្យោបាយដ៏ពេញនិយមមួយក្នុងការធ្វើអ្វីៗនាពេលបច្ចុប្បន្ននេះ ដែលអង្គភាពជាច្រើនជ្រើសរើសគឺបង្កើត ឬប្រភពក្រុមការងារ Telesale ដើម្បីធ្វើការហៅទៅកាន់អតិថិជន។\n' +
    '\n' +
    'ការយល់ដឹងពីការលំបាកដែលអាជីវកម្មហិរញ្ញវត្ថុកំពុងប្រឈមមុខ ដំណោះស្រាយ Callbot Auto Call បានកើតមកដើម្បីទៅដល់អតិថិជនយ៉ាងឆាប់រហ័ស និងបង្កើនសមត្ថភាពអាជីវកម្មរបស់អាជីវកម្មហិរញ្ញវត្ថុយ៉ាងខ្លាំង។ \n',
  'call_center.use_cases.INSURANCE': 'ការធានារ៉ាប់រង',
  'use_cases.call_center.INSURANCE.detail':
    'នៅក្នុងទីផ្សារនាពេលបច្ចុប្បន្ននេះ ប្រភេទធានារ៉ាប់រងត្រូវបានបែងចែកយ៉ាងពិសេសដោយផ្អែកលើលក្ខណៈនៃមុខវិជ្ជា ហើយអាជីវកម្មធានារ៉ាប់រងជាច្រើនកំពុងអភិវឌ្ឍយ៉ាងខ្លាំង ដោយបន្តចេញកញ្ចប់ផលិតផលថ្មីៗ ដើម្បីបំពេញតម្រូវការចម្រុះ។ អតិថិជនត្រូវការ។ ទន្ទឹមនឹងនោះ ការកែលម្អគុណភាពសេវាកម្មគឺជាកត្តាសំខាន់មួយដើម្បីជួយអាជីវកម្មធានារ៉ាប់រងបង្កើនគុណសម្បត្តិប្រកួតប្រជែងរបស់ពួកគេ ទាក់ទាញអតិថិជនថ្មី និងរក្សាអតិថិជនស្មោះត្រង់។ \n' +
    '\n' +
    'ដូច្នេះបញ្ហាគឺថា ត្រូវតែមានដំណោះស្រាយសមស្របមួយ ដើម្បីគាំទ្រដល់អាជីវកម្ម ដើម្បីដោះស្រាយបញ្ហាទាំងអស់ខាងលើ។ នោះហើយជាមូលហេតុដែលដំណោះស្រាយ Callbot Ai Auto Call បានកើត។\n',
  'call_center.use_cases.EDUCATION': 'ការអប់រំ',
  'use_cases.call_center.EDUCATION.detail':
    'បច្ចុប្បន្ននេះ មានមជ្ឈមណ្ឌលអប់រំជាច្រើនដែលកំពុងតស៊ូជាមួយនឹងបញ្ហានៃការផ្ទុកទិន្នន័យលើសទម្ងន់ក្នុងរដូវកាលកំពូល ក៏ដូចជាការចំណាយលើបុគ្គលិកច្រើនដើម្បីចាត់ថ្នាក់ឯកសារអតិថិជនឆៅ។ មជ្ឈមណ្ឌលអប់រំតែងតែមាន Telesales ដើម្បីស្វែងរកសិស្សថ្មីជារៀងរាល់ថ្ងៃ។ លើសពីនេះ មជ្ឈមណ្ឌលក៏ត្រូវអំពាវនាវដល់សិស្សានុសិស្សអំពីកាលវិភាគនៃវគ្គបណ្តុះបណ្តាល និងវគ្គបណ្តុះបណ្តាល ក៏ដូចជាការប្រឹក្សា និងថែទាំសិស្សចាស់ ឱ្យយល់ដឹងបន្ថែមពីតម្រូវការរបស់សិស្ស។ ជាមួយនឹងការអនុវត្តការហៅទូរសព្ទតាម Telesales និងការថែទាំអតិថិជន មជ្ឈមណ្ឌលអប់រំប្រើប្រាស់ថវិកាយ៉ាងច្រើន។ \n' +
    '\n' +
    'ដើម្បីដោះស្រាយការលំបាកខាងលើ UCALL បានដាក់ឱ្យដំណើរការនូវដំណោះស្រាយ callbot ដោយស្វ័យប្រវត្តិ ដើម្បីដោះស្រាយបញ្ហាខាងលើយ៉ាងឆាប់រហ័ស និងងាយស្រួល។ បច្ចុប្បន្ន ដំណោះស្រាយ callbot ដោយស្វ័យប្រវត្តិកំពុងត្រូវបានចាប់អារម្មណ៍ និងជ្រើសរើសដោយស្ថាប័នអប់រំជាច្រើន ដើម្បីគាំទ្រអាជីវកម្មរបស់ពួកគេឱ្យកាន់តែប្រសើរឡើង\n',
  'call_center.use_cases.BEAUTY': 'Khác',
  'call_center.use_cases.BEAUTY2': 'សម្រស់',
  'call_center.use_cases.SOFTWARE': 'កម្មវិធី',
  'call_center.use_cases.ADVISORY': 'អ្នកប្រឹក្សាពន្ធ',
  'call_center.use_cases.MEDICAL': 'Y Tế',
  'call_center.use_cases.EVENT': 'ព្រឹត្តិការណ៍',
  'call_center.use_cases.HIRING': 'ភ្នាក់ងារជ្រើសរើសបុគ្គលិក',
  'call_center.use_cases.OTHER': 'Khác',
  'use_cases.call_center.OTHER.លម្អិត':
    'បច្ចុប្បន្ន នៅពេលដែលទីផ្សារបច្ចេកវិទ្យាកំពុងរីកចម្រើន។ នេះមានន័យថាការលេចឡើងនៃដំណោះស្រាយបច្ចេកវិទ្យាដើម្បីគាំទ្រដល់អាជីវកម្មកាន់តែច្រើនឡើង។ មួយក្នុងចំណោមពួកគេគឺជារូបរាងរបស់ Callbot ។ ជាមួយនឹងគោលបំណងដើម្បីឈានទៅដល់អតិថិជនយ៉ាងឆាប់រហ័ស និងបង្កើនសមត្ថភាពអាជីវកម្មយ៉ាងសំខាន់។ \n' +
    '\n' +
    'ដូច្នេះហើយ ដំណោះស្រាយ callbot ដោយស្វ័យប្រវត្តិកំពុងត្រូវបានចាប់អារម្មណ៍ និងជ្រើសរើសដោយអាជីវកម្មជាច្រើន ដើម្បីគាំទ្រអាជីវកម្មរបស់ពួកគេឱ្យកាន់តែប្រសើរឡើង។ ដើម្បីអាចដឹកនាំឧស្សាហកម្ម; ក្រុមហ៊ុនតែងតែព្យាយាមបំពេញចិត្តអតិថិជនរបស់ខ្លួន។ ដូច្នេះជម្រើសនៃ callbot ត្រូវបានចាត់ទុកថាជាដំណោះស្រាយមួយដើម្បីកែលម្អបទពិសោធន៍របស់អតិថិជន។ នាំអាជីវកម្មរបស់អ្នកទៅកម្រិតបន្ទាប់។\n',

  'uleads.use_case.title': 'កម្មវិធីដំណោះស្រាយ',
  'uleads.use_cases.REAL_ESTATE': 'អចលនទ្រព្យ',
  'use_cases.uleads.REAL_ESTATE.detail':
    'អចលន​ទ្រព្យ​ត្រូវ​បាន​គេ​ចាត់​ទុក​ថា​ជា​ផ្នែក​មួយ​ក្នុង​ចំណោម​វិស័យ​អាជីវកម្ម​ដែល​មាន​ការ​រីក​ចម្រើន​និង​ការ​អភិវឌ្ឍ​លឿន​បំផុត​នា​ពេល​បច្ចុប្បន្ន​នេះ។ ដូច្នេះហើយ វាជារឿងសំខាន់សម្រាប់វិនិយោគិន ក្នុងការស្វែងរកមធ្យោបាយផ្សព្វផ្សាយ និងទីផ្សារជានិច្ច។ មធ្យោបាយដ៏ពេញនិយមមួយក្នុងការធ្វើអ្វីៗនាពេលបច្ចុប្បន្ននេះ ដែលអង្គភាពជាច្រើនជ្រើសរើសគឺបង្កើត ឬប្រភពក្រុមការងារ Telesale ដើម្បីធ្វើការហៅទៅកាន់អតិថិជន។\n' +
    '\n' +
    'ជាមួយនឹងទីផ្សារបច្ចេកវិទ្យាដែលកំពុងរីកចម្រើន ការជ្រើសរើសកម្មវិធីបច្ចេកវិទ្យាសម្រាប់ការថែទាំអតិថិជនកាន់តែប្រសើរកំពុងត្រូវបានចាប់អារម្មណ៍ដោយអង្គភាពជាច្រើន។ មួយក្នុងចំណោមពួកគេគឺជាដំណោះស្រាយការហៅដោយស្វ័យប្រវត្តិនៃក្ដារចុចនិម្មិត Callbot \n',
  'uleads.use_cases.FINANCE': 'ហិរញ្ញវត្ថុ',
  'use_cases.uleads.FINANCE.detail':
    'សហគ្រាសហិរញ្ញវត្ថុគឺជាប្រភេទនៃគ្រឹះស្ថានឥណទានមិនមែនធនាគារ។ អង្គការនេះមានមុខងារប្រើប្រាស់ដើមទុនផ្ទាល់ខ្លួន ដើមទុនដែលបានកៀរគរ និងប្រភពដើមទុនផ្សេងទៀតដើម្បីផ្តល់ប្រាក់កម្ចី វិនិយោគ ផ្តល់សេវាប្រឹក្សាផ្នែកហិរញ្ញវត្ថុ និងរូបិយវត្ថុ និងអនុវត្តសេវាកម្មមួយចំនួនទៀតស្របតាមបទប្បញ្ញត្តិនៃច្បាប់នេះ។ ច្បាប់។ ដូច្នេះហើយ ស្ថាប័នឥណទានតែងតែស្វែងរកមធ្យោបាយផ្សព្វផ្សាយពាណិជ្ជកម្ម និងទីផ្សារដល់អតិថិជនរបស់ពួកគេ។ មធ្យោបាយដ៏ពេញនិយមមួយក្នុងការធ្វើអ្វីៗនាពេលបច្ចុប្បន្ននេះ ដែលអង្គភាពជាច្រើនជ្រើសរើសគឺបង្កើត ឬប្រភពក្រុមការងារ Telesale ដើម្បីធ្វើការហៅទៅកាន់អតិថិជន។\n' +
    '\n' +
    'ការយល់ដឹងពីការលំបាកដែលអាជីវកម្មហិរញ្ញវត្ថុកំពុងប្រឈមមុខ ដំណោះស្រាយ Callbot Auto Call បានកើតមកដើម្បីទៅដល់អតិថិជនយ៉ាងឆាប់រហ័ស និងបង្កើនសមត្ថភាពអាជីវកម្មរបស់អាជីវកម្មហិរញ្ញវត្ថុយ៉ាងខ្លាំង។ \n',
  'uleads.use_cases.INSURANCE': 'ការធានារ៉ាប់រង',
  'use_cases.uleads.INSURANCE.detail':
    'នៅក្នុងទីផ្សារនាពេលបច្ចុប្បន្ននេះ ប្រភេទធានារ៉ាប់រងត្រូវបានបែងចែកយ៉ាងពិសេសដោយផ្អែកលើលក្ខណៈនៃមុខវិជ្ជា ហើយអាជីវកម្មធានារ៉ាប់រងជាច្រើនកំពុងអភិវឌ្ឍយ៉ាងខ្លាំង ដោយបន្តចេញកញ្ចប់ផលិតផលថ្មីៗ ដើម្បីបំពេញតម្រូវការចម្រុះ។ តម្រូវការរបស់អតិថិជន។ ទន្ទឹមនឹងនោះ ការកែលម្អគុណភាពសេវាកម្មគឺជាកត្តាសំខាន់មួយដើម្បីជួយអាជីវកម្មធានារ៉ាប់រងបង្កើនអត្ថប្រយោជន៍ប្រកួតប្រជែងរបស់ពួកគេ ទាក់ទាញអតិថិជនថ្មី និងរក្សាអតិថិជនស្មោះត្រង់។ \n' +
    '\n' +
    'ដូច្នេះបញ្ហាគឺថា ត្រូវតែមានដំណោះស្រាយសមស្របមួយ ដើម្បីគាំទ្រដល់អាជីវកម្ម ដើម្បីដោះស្រាយបញ្ហាទាំងអស់ខាងលើ។ នោះហើយជាមូលហេតុដែលដំណោះស្រាយ Callbot Ai Auto Call បានកើត។\n',
  'uleads.use_cases.EDUCATION': 'ការអប់រំ',
  'use_cases.uleads.EDUCATION.detail':
    'បច្ចុប្បន្ននេះ មានមជ្ឈមណ្ឌលអប់រំជាច្រើនដែលកំពុងតស៊ូជាមួយនឹងបញ្ហានៃការផ្ទុកទិន្នន័យលើសទម្ងន់ក្នុងរដូវកាលកំពូល ក៏ដូចជាការចំណាយលើបុគ្គលិកច្រើនដើម្បីចាត់ថ្នាក់ឯកសារអតិថិជនឆៅ។ មជ្ឈមណ្ឌលអប់រំតែងតែមាន Telesales ដើម្បីស្វែងរកសិស្សថ្មីជារៀងរាល់ថ្ងៃ។ លើសពីនេះ មជ្ឈមណ្ឌលក៏ត្រូវអំពាវនាវដល់សិស្សានុសិស្សអំពីកាលវិភាគនៃវគ្គបណ្តុះបណ្តាល និងវគ្គបណ្តុះបណ្តាល ក៏ដូចជាការប្រឹក្សា និងថែទាំសិស្សចាស់ ឱ្យយល់ដឹងបន្ថែមពីតម្រូវការរបស់សិស្ស។ ជាមួយនឹងការអនុវត្តការហៅទូរសព្ទតាម Telesales និងការថែទាំអតិថិជន មជ្ឈមណ្ឌលអប់រំប្រើប្រាស់ថវិកាយ៉ាងច្រើន។ \n' +
    '\n' +
    'ដើម្បីដោះស្រាយការលំបាកខាងលើ UCALL បានដាក់ឱ្យដំណើរការនូវដំណោះស្រាយ callbot ដោយស្វ័យប្រវត្តិ ដើម្បីដោះស្រាយបញ្ហាខាងលើយ៉ាងឆាប់រហ័ស និងងាយស្រួល។ បច្ចុប្បន្ន ដំណោះស្រាយ callbot ដោយស្វ័យប្រវត្តិកំពុងត្រូវបានចាប់អារម្មណ៍ និងជ្រើសរើសដោយស្ថាប័នអប់រំជាច្រើន ដើម្បីគាំទ្រអាជីវកម្មរបស់ពួកគេឱ្យកាន់តែប្រសើរឡើង\n',
  'uleads.use_cases.BEAUTY': 'ផ្សេងទៀត',
  'uleads.use_cases.BEAUTY2': 'សម្រស់',
  'uleads.use_cases.SOFTWARE': 'កម្មវិធី',
  'uleads.use_cases.ADVISORY': 'ប្រឹក្សាពន្ធ',
  'uleads.use_cases.MEDICAL': 'ទឹក',
  'uleads.use_cases.EVENT': 'ព្រឹត្តិការណ៍',
  'uleads.use_cases.HIRING': 'ភ្នាក់ងារជ្រើសរើស',
  'uleads.use_cases.OTHER': 'Khác',
  'use_cases.uleads.OTHER.លម្អិត':
    'បច្ចុប្បន្ន នៅពេលដែលទីផ្សារបច្ចេកវិទ្យាកំពុងរីកចម្រើន។ នេះមានន័យថាការលេចឡើងនៃដំណោះស្រាយបច្ចេកវិទ្យាដើម្បីគាំទ្រដល់អាជីវកម្មកាន់តែច្រើនឡើង។ មួយក្នុងចំណោមពួកគេគឺជារូបរាងរបស់ Callbot ។ ជាមួយនឹងគោលបំណងដើម្បីឈានទៅដល់អតិថិជនយ៉ាងឆាប់រហ័ស និងបង្កើនសមត្ថភាពអាជីវកម្មយ៉ាងសំខាន់។ \n' +
    '\n' +
    'ដូច្នេះហើយ ដំណោះស្រាយ callbot ដោយស្វ័យប្រវត្តិកំពុងត្រូវបានចាប់អារម្មណ៍ និងជ្រើសរើសដោយអាជីវកម្មជាច្រើន ដើម្បីគាំទ្រអាជីវកម្មរបស់ពួកគេឱ្យកាន់តែប្រសើរឡើង។ ដើម្បីអាចដឹកនាំឧស្សាហកម្ម; ក្រុមហ៊ុនតែងតែព្យាយាមបំពេញចិត្តអតិថិជនរបស់ខ្លួន។ ដូច្នេះជម្រើសនៃ callbot ត្រូវបានចាត់ទុកថាជាដំណោះស្រាយមួយដើម្បីកែលម្អបទពិសោធន៍របស់អតិថិជន។ នាំអាជីវកម្មរបស់អ្នកទៅកម្រិតបន្ទាប់។\n',

  'use_cases.find_out': 'ស្វែងយល់បន្ថែម',
  'customers.title': 'អតិថិជន និងដៃគូ',
  'pros.title': 'អត្ថប្រយោជន៍',
  'feedbacks.title': 'មតិយោបល់របស់អតិថិជន',
  'cases.solution.title': 'ដំណោះស្រាយ UCALL',
  'cases.promotion_banner': 'បង្កើនប្រសិទ្ធភាពអាជីវកម្ម',
  'cases.REAL_ESTATE.title':
    'CALLBOT AI AUTO CALL\n' + '50% Cost OptiMIZE\n' + 'Real Estate TELESALE',
  'cases.REAL_ESTATE.subtitle':
    'Callbot AI ហៅដោយស្វ័យប្រវត្តិដើម្បីបំពេញតម្រូវការនៃការគ្រប់គ្រង និងបង្កើនប្រសិទ្ធភាពតម្លៃនៃការបំប្លែងអតិថិជនសក្តានុពលក្នុងវិស័យអចលនទ្រព្យ',
  'cases.REAL_ESTATE.problem1.title': 'បាត់ទិន្នន័យ',
  'cases.REAL_ESTATE.problem1.subtitle':
    'កង្វះប្រភពទិន្នន័យនៅពេលដ៏សំខាន់ ធ្វើឱ្យអាជីវកម្មអចលនទ្រព្យមានកម្រិត',
  'cases.REAL_ESTATE.problem2.title': 'ចំណាយពេលច្រើន',
  'cases.REAL_ESTATE.problem2.subtitle':
    'អាជីវកម្មត្រូវចំណាយពេលរាប់ម៉ោងជារៀងរាល់ថ្ងៃដើម្បីស្វែងរកការនាំមុខនៅក្នុងឯកសារទិន្នន័យឆៅ (ប្រហែល 70% នៃទិន្នន័យមិននាំមុខ)',
  'cases.REAL_ESTATE.problem3.title': 'តម្លៃខ្ពស់',
  'cases.REAL_ESTATE.problem3.subtitle':
    'ចំណាយ 50% នៃការចំណាយលើបុគ្គលិក Telesales លើការស្វែងរក ដោយមិនគិតពីការចំណាយប្រតិបត្តិការផ្សេងទៀត',
  'cases.REAL_ESTATE.problem4.title': 'ខកខានឱកាស',
  'cases.REAL_ESTATE.problem4.subtitle':
    'អាជីវកម្មអចលនទ្រព្យដែលមិនចូលទៅជិតអតិថិជនសក្តានុពលអាចបាត់បង់ឱកាសអាជីវកម្មនាពេលអនាគត',
  'cases.REAL_ESTATE.solution1': 'ការស្វែងរកទិន្នន័យអតិថិជនជាមួយនឹងតម្រូវការអចលនទ្រព្យ',
  'cases.REAL_ESTATE.solution2':
    'គាំទ្រដើម្បីអភិវឌ្ឍសេណារីយ៉ូ telesales សមរម្យសម្រាប់អតិថិជនអចលនទ្រព្យ',
  'cases.REAL_ESTATE.solution3': 'ធ្វើការហៅទូរស័ព្ទចំនួន 5000 ទៅកាន់អតិថិជនក្នុងរយៈពេល 1 ម៉ោង',
  'cases.REAL_ESTATE.solution4': 'ការគ្រប់គ្រងការរាយការណ៍ការហៅទូរសព្ទដែលងាយស្រួលយល់ និងងាយយល់',
  'cases.REAL_ESTATE.pros1':
    'ស្វែងរកអតិថិជនសក្តានុពលយ៉ាងឆាប់រហ័ស បង្កើនអត្រាប្តូរប្រាក់សម្រាប់អាជីវកម្មអចលនទ្រព្យ',
  'cases.REAL_ESTATE.pros2': 'សន្សំសំចៃថ្លៃដើម និងពេលវេលាសម្រាប់អាជីវកម្ម\n',
  'cases.REAL_ESTATE.pros3': 'កាត់បន្ថយបន្ទុកការងារ ជួយអាជីវកម្មផ្តោតលើការងារសំខាន់ៗ',
  'cases.REAL_ESTATE.pros4': 'បង្កើនប្រសិទ្ធភាពអាជីវកម្មសម្រាប់សហគ្រាស\n',
  'cases.FINANCE.title':
    'CALLBOT AI AUTO CALL \n' + '50% Cost Optimize \n' + 'FINANCIAL TELESALE\n',
  'cases.FINANCE.subtitle':
    'Callbot AI ហៅដោយស្វ័យប្រវត្តិដើម្បីបំពេញតម្រូវការនៃការគ្រប់គ្រង និងបង្កើនប្រសិទ្ធភាពនៃការចំណាយនៃការបំប្លែងអតិថិជនសក្តានុពលក្នុងវិស័យហិរញ្ញវត្ថុ',
  'cases.FINANCE.problem1.title': 'បាត់ទិន្នន័យ',
  'cases.FINANCE.problem1.subtitle':
    'កង្វះប្រភពទិន្នន័យនៅពេលដ៏សំខាន់ ធ្វើឱ្យអាជីវកម្មហិរញ្ញវត្ថុមានកម្រិត',
  'cases.FINANCE.problem2.title': 'ចំណាយពេលវេលា',
  'cases.FINANCE.problem2.subtitle':
    'អ្នកប្រឹក្សាហិរញ្ញវត្ថុម្នាក់ៗត្រូវចំណាយពេល 6 ម៉ោងក្នុងមួយថ្ងៃក្នុងការហៅទូរស័ព្ទ និងស្វែងរកអតិថិជនដែលមានតម្រូវការហិរញ្ញវត្ថុនៅក្នុងឯកសារទិន្នន័យឆៅ (ប្រហែល 70% នៃទិន្នន័យមិនមែនជាអតិថិជនសក្តានុពល) កាត់បន្ថយផលិតភាពការងារ។ ',
  'cases.FINANCE.problem3.title': 'ការចំណាយខ្ពស់',
  'cases.FINANCE.problem3.subtitle':
    'វាត្រូវចំណាយ 50% នៃការចំណាយលើបុគ្គលិក Telesales ដើម្បីស្វែងរកអតិថិជនសក្តានុពល មិនមែននិយាយពីថ្លៃប្រតិបត្តិការផ្សេងទៀតទេ។',
  'cases.FINANCE.problem4.title': 'ខកខានឱកាស',
  'cases.FINANCE.problem4.subtitle':
    'បុគ្គលិក Telesales អាចធ្វើការហៅទូរស័ព្ទបានតែ 1 ដងក្នុងមួយលើក។ នេះនាំឱ្យផលិតភាពទាប និងអសមត្ថភាពក្នុងការឈានដល់បរិមាណដ៏ច្រើននៃអតិថិជនដែលមានតម្រូវការហិរញ្ញវត្ថុ បាត់បង់ឱកាសលក់ជាច្រើន។',
  'cases.FINANCE.solution1': 'ការស្វែងរកទិន្នន័យអតិថិជនជាមួយនឹងតម្រូវការហិរញ្ញវត្ថុ',
  'cases.FINANCE.solution2':
    'គាំទ្រដើម្បីអភិវឌ្ឍសេណារីយ៉ូ Telesales សមរម្យសម្រាប់អតិថិជនហិរញ្ញវត្ថុ',
  'cases.FINANCE.solution3': 'ធ្វើការហៅទូរស័ព្ទចំនួន 5000 ទៅកាន់អតិថិជនក្នុងរយៈពេល 1 ម៉ោង',
  'cases.FINANCE.solution4': 'ការគ្រប់គ្រងការរាយការណ៍ការហៅទូរសព្ទដែលងាយយល់ និងងាយយល់',
  'cases.FINANCE.pros1':
    'ស្វែងរកអតិថិជនសក្តានុពលយ៉ាងឆាប់រហ័ស បង្កើនអត្រាប្តូរប្រាក់សម្រាប់អាជីវកម្មហិរញ្ញវត្ថុ',
  'cases.FINANCE.pros2': 'សន្សំសំចៃថ្លៃដើម និងពេលវេលាសម្រាប់អាជីវកម្ម\n',
  'cases.FINANCE.pros3': 'កាត់បន្ថយបន្ទុកការងារ ជួយអាជីវកម្មផ្តោតលើការងារសំខាន់ៗ',
  'cases.FINANCE.pros4': 'បង្កើនប្រសិទ្ធភាពអាជីវកម្មសម្រាប់សហគ្រាស\n',
  'cases.INSURANCE.title':
    'CALLBOT AI AUTO CALL \n' + '50% Cost Optimize \n' + 'TeleSALE Insurance\n',
  'cases.INSURANCE.subtitle':
    'Callbot AI ហៅដោយស្វ័យប្រវត្តិដើម្បីបំពេញតម្រូវការនៃការគ្រប់គ្រង និងបង្កើនប្រសិទ្ធភាពតម្លៃនៃការបំប្លែងអតិថិជនសក្តានុពលនៅក្នុងឧស្សាហកម្មធានារ៉ាប់រង',
  'cases.INSURANCE.problem1.title': 'បាត់ទិន្នន័យ',
  'cases.INSURANCE.problem1.subtitle':
    'កង្វះប្រភពទិន្នន័យនៅពេលដ៏សំខាន់ ធ្វើឱ្យអាជីវកម្មធានារ៉ាប់រងមានកម្រិត',
  'cases.INSURANCE.problem2.title': 'ចំណាយពេលវេលា',
  'cases.INSURANCE.problem2.subtitle':
    'អ្នកប្រឹក្សាធានារ៉ាប់រងម្នាក់ៗត្រូវចំណាយពេល 6 ម៉ោងក្នុងមួយថ្ងៃក្នុងការហៅទូរស័ព្ទ និងស្វែងរកអតិថិជនដែលមានតម្រូវការទិញធានារ៉ាប់រងក្នុងឯកសារទិន្នន័យឆៅ (ប្រហែល 70% នៃទិន្នន័យមិនមែនជាអតិថិជនសក្តានុពល) កាត់បន្ថយផលិតភាពការងារ។ .',
  'cases.INSURANCE.problem3.title': 'ថ្លៃដើមខ្ពស់',
  'cases.INSURANCE.problem3.subtitle':
    'វាត្រូវចំណាយ 50% នៃការចំណាយលើបុគ្គលិក Telesales ដើម្បីស្វែងរកអតិថិជនសក្តានុពល មិនមែននិយាយពីថ្លៃប្រតិបត្តិការផ្សេងទៀតទេ។',
  'cases.INSURANCE.problem4.title': 'ខកខានឱកាស',
  'cases.INSURANCE.problem4.subtitle':
    'បុគ្គលិក Telesales អាចធ្វើការហៅទូរស័ព្ទបានតែ 1 ដងក្នុងមួយលើក។ នេះនាំឱ្យផលិតភាពទាប និងអសមត្ថភាពក្នុងការឈានដល់បរិមាណដ៏ច្រើននៃអតិថិជនដែលមានតម្រូវការធានារ៉ាប់រង។',
  'cases.INSURANCE.solution1': 'ការស្វែងរកទិន្នន័យរបស់អតិថិជនដែលចង់ទិញធានារ៉ាប់រង',
  'cases.INSURANCE.solution2':
    'គាំទ្រដើម្បីអភិវឌ្ឍសេណារីយ៉ូ Telesales សមរម្យសម្រាប់អតិថិជនធានារ៉ាប់រង',
  'cases.INSURANCE.solution3': 'ធ្វើការហៅទូរស័ព្ទចំនួន 5000 ទៅកាន់អតិថិជនក្នុងរយៈពេល 1 ម៉ោង',
  'cases.INSURANCE.solution4': 'ការគ្រប់គ្រងការរាយការណ៍ការហៅទូរសព្ទដែលងាយយល់ និងងាយយល់',
  'cases.INSURANCE.pros1':
    'ស្វែងរកអតិថិជនសក្តានុពលយ៉ាងឆាប់រហ័ស បង្កើនអត្រាប្តូរប្រាក់សម្រាប់អាជីវកម្មធានារ៉ាប់រង',
  'cases.INSURANCE.pros2': 'សន្សំសំចៃថ្លៃដើម និងពេលវេលាសម្រាប់អាជីវកម្ម\n',
  'cases.INSURANCE.pros3': 'កាត់បន្ថយបន្ទុកការងារ ជួយអាជីវកម្មផ្តោតលើការងារសំខាន់ៗ',
  'cases.INSURANCE.pros4': 'បង្កើនប្រសិទ្ធភាពអាជីវកម្មសម្រាប់សហគ្រាស\n',
  'cases.EDUCATION.title':
    'CALLBOT AI ការហៅដោយស្វ័យប្រវត្តិ \n' +
    'ការបង្កើនប្រសិទ្ធភាពតម្លៃ \n' +
    'TLESALE & Education Sector\n',
  'cases.EDUCATION.subtitle':
    'Callbot AI ហៅដោយស្វ័យប្រវត្តិដើម្បីបំពេញតម្រូវការនៃការគ្រប់គ្រង ការថែទាំអតិថិជន និងបង្កើនប្រសិទ្ធភាពតម្លៃនៃការបំប្លែងអតិថិជនសក្តានុពលនៅក្នុងឧស្សាហកម្មអប់រំ',
  'cases.EDUCATION.problem1.title': 'បាត់ទិន្នន័យ',
  'cases.EDUCATION.problem1.subtitle':
    'កង្វះប្រភពទិន្នន័យនៅពេលដ៏សំខាន់ ធ្វើឱ្យអាជីវកម្មកណ្តាលមានកម្រិត',
  'cases.EDUCATION.problem2.title': 'ចំណាយពេលច្រើន',
  'cases.EDUCATION.problem2.subtitle':
    'មជ្ឈមណ្ឌលអប់រំត្រូវចំណាយពេលរាប់ម៉ោងជារៀងរាល់ថ្ងៃដើម្បីមើលថែសិស្សចាស់ និងស្វែងរកសិស្សថ្មីក្នុងឯកសារទិន្នន័យឆៅ (ប្រហែល 65% នៃទិន្នន័យមិនមែនជាបេក្ខជនដែលត្រូវការ)',
  'cases.EDUCATION.problem3.title': 'តម្លៃខ្ពស់',
  'cases.EDUCATION.problem3.subtitle':
    'វាមានតម្លៃ 50% នៃការចំណាយរបស់បុគ្គលិក Telesales និងការថែទាំសិស្ស ដោយមិនគិតពីការចំណាយប្រតិបត្តិការផ្សេងទៀត។',
  'cases.EDUCATION.problem4.title': 'ខកខានឱកាស',
  'cases.EDUCATION.problem4.subtitle':
    'លទ្ធភាពទទួលបាននិស្សិតសក្តានុពលមានភាពយឺតយ៉ាវ ដែលកាត់បន្ថយឱកាសអាជីវកម្មរបស់មជ្ឈមណ្ឌលអប់រំផងដែរ។ ការមិនមើលថែសិស្សទាន់ពេល ក៏ជាមូលហេតុដែលពួកគេផ្លាស់ទៅមណ្ឌលផ្សេង។',
  'cases.EDUCATION.solution1': 'ស្វែងរកទិន្នន័យអតិថិជនដែលត្រូវការ',
  'cases.EDUCATION.solution2':
    'គាំទ្រដើម្បីអភិវឌ្ឍសេណារីយ៉ូលក់តាមទូរស័ព្ទដែលសមរម្យសម្រាប់អតិថិជនការអប់រំ',
  'cases.EDUCATION.solution3': 'ធ្វើការហៅទូរស័ព្ទចំនួន 5000 ទៅកាន់អតិថិជនក្នុងរយៈពេល 1 ម៉ោង',
  'cases.EDUCATION.solution4': 'ការគ្រប់គ្រងការរាយការណ៍ការហៅទូរសព្ទដែលងាយយល់ និងងាយយល់',
  'cases.EDUCATION.pros1':
    'ស្វែងរកអតិថិជនសក្តានុពលយ៉ាងឆាប់រហ័ស បង្កើនអត្រាប្តូរប្រាក់សម្រាប់មជ្ឈមណ្ឌលអប់រំ',
  'cases.EDUCATION.pros2': 'ថែរក្សាសិស្សឱ្យបានទៀងទាត់ បង្កើនអត្រារក្សាអតិថិជន',
  'cases.EDUCATION.pros3': 'កាត់បន្ថយបន្ទុកការងារ ជួយអ្នកប្រឹក្សាផ្តោតលើការងារសំខាន់ៗ',
  'cases.EDUCATION.pros4':
    'បង្កើនប្រសិទ្ធភាពអាជីវកម្មសម្រាប់អាជីវកម្ម។ សន្សំប្រាក់ និងពេលវេលាសម្រាប់មជ្ឈមណ្ឌល។',
  'cases.OTHER.title':
    'CALLBOT AI AUTO CALL \n' + 'Cost Optimize \n' + 'TeleSALE & Customer Service\n',
  'cases.OTHER.ចំណងជើងរង':
    'Callbot AI ហៅដោយស្វ័យប្រវត្តិដើម្បីបំពេញតម្រូវការនៃការគ្រប់គ្រង ការថែទាំអតិថិជន និងបង្កើនប្រសិទ្ធភាពតម្លៃនៃការបំប្លែងអតិថិជនសក្តានុពលសម្រាប់វិស័យផ្សេងទៀតដូចជា ផ្នែកទន់ ការថែទាំសុខភាព ការប្រឹក្សាពន្ធ ភ្នាក់ងារជ្រើសរើសបុគ្គលិក ព្រឹត្តិការណ៍អង្គការ',
  'cases.OTHER.problem1.title': 'ទិន្នន័យ Thiếu',
  'cases.OTHER.problem1.subtitle': 'កង្វះប្រភពទិន្នន័យនៅពេលដ៏សំខាន់ ធ្វើឱ្យអាជីវកម្មមានកម្រិត',
  'cases.OTHER.problem2.title': 'ចំណាយពេលច្រើន',
  'cases.OTHER.problem2.subtitle':
    'អាជីវកម្មត្រូវចំណាយពេលរាប់ម៉ោងជារៀងរាល់ថ្ងៃដើម្បីថែរក្សាអតិថិជនចាស់ និងស្វែងរកអតិថិជនថ្មីនៅក្នុងឯកសារទិន្នន័យឆៅ (ប្រហែល 65% នៃទិន្នន័យមិនមែនជាបេក្ខជនដែលត្រូវការ)',
  'cases.OTHER.problem3.title': 'Chi phi cao',
  'cases.OTHER.problem3.subtitle':
    'វាមានតម្លៃ 50% នៃការចំណាយរបស់បុគ្គលិក Telesales និងការថែទាំសិស្ស ដោយមិនគិតពីការចំណាយប្រតិបត្តិការផ្សេងទៀត។',
  'cases.OTHER.problem4.title': 'ខកខានឱកាស',
  'cases.OTHER.problem4.subtitle':
    'វិធីសាស្រ្តទៅកាន់អតិថិជនសក្តានុពលមានភាពយឺតយ៉ាវ ដែលកាត់បន្ថយឱកាសអាជីវកម្មរបស់សហគ្រាសផងដែរ។ ការមិនយកចិត្តទុកដាក់លើអតិថិជនទាន់ពេលវេលាក៏ជាហេតុផលដែលពួកគេប្តូរទៅដៃគូប្រកួតប្រជែងផ្សេងទៀត។',
  'cases.OTHER.solution1': 'ស្វែងរកទិន្នន័យដែលអតិថិជនមានតម្រូវការ',
  'cases.OTHER.solution2':
    'ការ​គាំទ្រ​ក្នុង​ការ​បង្កើត​សេណារីយ៉ូ​ការ​លក់​តាម​ទូរស័ព្ទ​ដែល​សមរម្យ​សម្រាប់​អតិថិជន',
  'cases.OTHER.solution3': 'ធ្វើការហៅទូរស័ព្ទចំនួន 5000 ទៅកាន់អតិថិជនក្នុងរយៈពេល 1 ម៉ោង',
  'cases.OTHER.solution4': 'ការគ្រប់គ្រងការរាយការណ៍ការហៅទូរសព្ទដែលងាយយល់ និងងាយយល់',
  'cases.OTHER.pros1': 'ស្វែងរកអតិថិជនសក្តានុពលយ៉ាងឆាប់រហ័ស បង្កើនអត្រាប្តូរប្រាក់សម្រាប់អាជីវកម្ម',
  'cases.OTHER.pros2': 'ថែរក្សាសិស្សឱ្យបានទៀងទាត់ បង្កើនអត្រារក្សាអតិថិជន',
  'cases.OTHER.pros3':
    'សន្សំសំចៃថ្លៃដើម និងពេលវេលាសម្រាប់មជ្ឈមណ្ឌល។ បង្កើនប្រសិទ្ធភាពអាជីវកម្មសម្រាប់មជ្ឈមណ្ឌល',
  'cases.OTHER.pros4': 'កាត់បន្ថយបន្ទុកការងារ ជួយអ្នកប្រឹក្សាផ្តោតលើការងារសំខាន់ៗ',

  'callbot.features.feature1.image': 'giao-dien-nhap-khach-hang.png',
  'callbot.features.feature2.image': 'giao-dien-sua-kich-ban.png',
  'callbot.features.feature3.image': 'giao-dien-cai-dat-chien-dich.png',
  'callbot.features.feature4.image': 'giao-dien-bao-cao.png',

  'call_center.features.feature1.image': 'giao-dien-nhap-khach-hang.png',
  'call_center.features.feature2.image': 'giao-dien-sua-kich-ban.png',
  'call_center.features.feature3.image': 'giao-dien-cai-dat-chien-dich.png',
  'call_center.features.feature4.image': 'giao-dien-bao-cao.png',

  'uleads.features.feature1.image': 'giao-dien-nhap-khach-hang.png',
  'uleads.features.feature2.image': 'giao-dien-sua-kich-ban.png',
  'uleads.features.feature3.image': 'giao-dien-cai-dat-chien-dich.png',
  'uleads.features.feature4.image': 'giao-dien-bao-cao.png',
};
