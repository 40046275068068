import { List, Avatar, Card, Spin, Typography } from 'antd';
import Button from 'antd-button-color';
import useNotifications from '@/services/api/notification/useNotifications';
import { BellTwoTone, CheckCircleOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { INotification } from '@/services/api/types';
import useMarkAsRead from '@/services/api/notification/useMarkAsRead';
import './index.less';
import { formatDateFromString } from '@/services/api/util';
import { useMutation } from 'react-query';
import axiosInstance from '@/services/api/axiosInstance';
import useCompanyId from '@/hooks/useCompanyId';
import { useDrawerHelper } from '@/components/DrawerHelperProvider';
import { HelperSlugs } from '../../../config/routes';
import { useIntl } from 'umi';
interface NotificationCardProps {
  item: INotification;
  callback: () => void;
}

export const NotificationCard: React.FC<NotificationCardProps> = ({ item, callback }) => {
  const { mutateAsync, isLoading } = useMarkAsRead(item?.id?.toString() || '');

  const { setCurrentSlug } = useDrawerHelper();

  useEffect(() => {
    setCurrentSlug(HelperSlugs.thong_bao);
  }, []);
  return (
    <Card
      style={{ marginBottom: '10px' }}
      bodyStyle={{ padding: '8px 16px' }}
      hoverable
      onClick={() => {
        mutateAsync().then(() => {
          callback();
        });
      }}
    >
      <List.Item
        extra={[
          item?.is_read && (
            <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: '20px' }} />
          ),
          isLoading && <Spin />,
        ]}
      >
        <List.Item.Meta
          avatar={
            item.image ? <Avatar src={item.image} /> : <BellTwoTone style={{ fontSize: '36px' }} />
          }
          title={<div className="text-capitalize">{item.title}</div>}
          description={
            <div>
              <div dangerouslySetInnerHTML={{ __html: item.body }} />
              <div className="mt-3" style={{ opacity: 0.6 }}>
                <Typography.Text>{formatDateFromString(item?.created_at)}</Typography.Text>
              </div>
            </div>
          }
        />
      </List.Item>
    </Card>
  );
};

const NotificationPage = () => {
  const { formatMessage } = useIntl();
  const { apiRoutes } = useCompanyId();

  const [currentPage, setCurrentPage] = useState(1);
  const { data: notificationsData, isLoading, refetch } = useNotifications({ page: currentPage });
  const { mutateAsync: markAll, isLoading: isMarking } = useMutation(
    async ({ ids }: { ids: string[] }) => {
      return await Promise.all(
        ids.map((id) =>
          axiosInstance.patch(apiRoutes.notifications.detail(id), {
            is_read: true,
          }),
        ),
      );
    },
  );

  const markAllAsRead = async () => {
    const ids = notificationsData?.data?.results?.map((item) => item.id);
    await markAll({ ids });
    await refetch();
  };

  return (
    <div className="d-flex flex-column align-items-end justify-end ">
      <div className="mb-2" style={{ alignSelf: 'flex-end' }}>
        <Button onClick={markAllAsRead} type="success">
          <CheckCircleOutlined />
          {formatMessage({ id: 'nh_du_tt_c__c' })}
        </Button>
      </div>
      <div className="w-100">
        <List
          itemLayout="horizontal"
          dataSource={notificationsData?.data?.results}
          loading={isLoading || isMarking}
          pagination={{
            onChange: (page) => {
              setCurrentPage(page);
            },
            pageSize: 50,
          }}
          renderItem={(item) => {
            return (
              <NotificationCard
                item={item}
                callback={() => {
                  refetch().then();
                }}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

export default NotificationPage;
