import { Badge, Button, Popover, Space, Timeline } from 'antd';
import {
  BellFilled,
  CheckCircleOutlined,
  CloseCircleOutlined,
  PhoneFilled,
  ThunderboltOutlined,
  WalletOutlined,
  WarningFilled,
} from '@ant-design/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { SelectLang, useAccess, useModel } from 'umi';
import Avatar from './AvatarDropdown';
import styles from './index.less';
import CompanyDropdown from '@/components/RightContent/CompanyDropdown';
import useWallet from '@/services/api/wallet/useWallet';
import { history } from '@@/core/history';
import { routesPath } from '../../../config/routes';
import useCompanyId from '@/hooks/useCompanyId';
import { daysRemaining, formatCurrency } from '@/services/api/util';
import useNotifications from '@/services/api/notification/useNotifications';
import useDisclosure from '@/hooks/useDisclosure';
import useMyAgent from '@/services/api/agent/useMyAgent';
import DialPad from '@/components/DialPad';
import { useIntl } from '@@/plugin-locale/localeExports';
import DetectRTC from 'detectrtc';
import { each } from 'lodash';
import { useMediaQuery } from 'react-responsive';
import useEditUserInfo from '@/services/api/user/useEditUserInfo';
import useUserInfo, { mapLanguageToCountryCode } from '@/services/api/user/useUserInfo';

export type SiderTheme = 'light' | 'dark';

const GlobalHeaderRight: React.FC = () => {
  // const { data } = useWallet();
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' });
  const { data: userInfoData, refetch } = useUserInfo();
  const userInfo = userInfoData?.data;
  const { mutateAsync } = useEditUserInfo(userInfo?.user.id);

  const [capabilities, setCapabilities] = useState({});
  const { COMPANY_ID } = useCompanyId();
  const { data: agentData } = useMyAgent();
  const callBarState = useDisclosure();
  const { isOpen, toggle } = callBarState;
  const [open, setOpen] = useState(false);
  const { data: notificationsData } = useNotifications({ page: 1 });
  const { initialState } = useModel('@@initialState');
  const unread = notificationsData?.data?.results?.filter((r) => !r.is_read)?.length;
  const { formatMessage } = useIntl();
  const {isUcall } = useAccess?.();
  

  const navTheme = initialState?.settings?.navTheme;
  const layout = initialState?.settings?.layout;
  let className = styles.right;

  const handleClick = () => {
    window.open('https://app.ucall.vn/', '_blank');
  };
  if ((navTheme === 'dark' && layout === 'top') || layout === 'mix') {
    className = `${styles.right}  ${styles.dark}`;
  }

  // const remainingTime =
  //   daysRemaining(data?.data?.expire_at) < 0
  //     ? formatMessage({ id: 'ht_hn' })
  //     : formatMessage({ id: 'cn_ngy' }, { daysRemaining: daysRemaining(data?.data?.expire_at) });

  const isAbleToCall = useMemo(() => {
    let flag = true;
    if (capabilities) {
      each(capabilities, (val, key) => {
        if (!val) {
          flag = false;
        }
      });
    } else {
      flag = false;
    }
    return flag;
    // if (!data) {
    //   flag = false;
    // }
  }, [capabilities]);

  const handleOpenChange = (value: any) => setOpen(value);

  useEffect(() => {
    DetectRTC.load(function () {
      setCapabilities({
        // hasWebcam: DetectRTC.hasWebcam,
        hasMicrophone: DetectRTC.hasMicrophone,
        hasSpeakers: DetectRTC.hasSpeakers,
        isAudioContextSupported: DetectRTC.isAudioContextSupported,
        isWebRTCSupported: DetectRTC.isWebRTCSupported,
        isWebsiteHasMicrophonePermissions: DetectRTC.isWebsiteHasMicrophonePermissions,
        isChrome: DetectRTC.browser.isChrome,
        isCanvasSupportsStreamCapturing: DetectRTC.isCanvasSupportsStreamCapturing,
      });
    });
  }, [isOpen]);

  if (!initialState || !initialState.settings) {
    return null;
  }
  return (
    <Space className={className}>
      {isUcall === true && (
        <>
          <Button
            style={{ backgroundColor: 'white', color: '#1E276C' }}
            onClick={handleClick}
            icon={<ThunderboltOutlined style={{ color: '#1E276C' }} />}
            type="primary"
            ghost
          >
            Trải nghiệm ngay UCall V3
          </Button>
        </>
      )}
      {agentData?.data?.ext_username && (
        <Popover
          content={
            <>
              {' '}
              {!isAbleToCall && (
                <Timeline>
                  {capabilities &&
                    Object.keys(capabilities).map((key) => {
                      if (capabilities?.[key]) {
                        return <></>;
                      }
                      return (
                        <Timeline.Item
                          dot={
                            <CloseCircleOutlined
                              style={{ fontSize: '20px', color: 'var(--ant-error-color)' }}
                            />
                          }
                        >
                          {key}
                        </Timeline.Item>
                      );
                    })}
                </Timeline>
              )}
              <DialPad toggle={toggle} />
            </>
          }
          title={formatMessage({ id: 'call_title' })}
          trigger="click"
          visible={open}
          onVisibleChange={handleOpenChange}
        >
          <div
            onClick={toggle}
            className="d-flex flex-row align-items-center"
            style={{ cursor: 'pointer' }}
          >
            {isAbleToCall && <PhoneFilled style={{ marginRight: '8px' }} />}
            {!isAbleToCall && <WarningFilled style={{ marginRight: '8px' }} />}
            <div style={{ color: 'white' }}>
              {!isMobile && formatMessage({ id: 'dial' })}
              {` `}
              {/* {!isAbleToCall && <span>(!)</span>} */}
            </div>
          </div>
        </Popover>
      )}

      <span
        className={styles.action}
        onClick={() => {
          history.push(routesPath.notification(COMPANY_ID));
        }}
      >
        <Badge size="small" count={unread || 0}>
          <BellFilled />
        </Badge>
      </span>
      {/*<div*/}
      {/*  onClick={() => {*/}
      {/*    history.push(routesPath.wallet(COMPANY_ID).detail);*/}
      {/*  }}*/}
      {/*  className="d-flex flex-row text-white align-items-center justify-content-center"*/}
      {/*  style={{ cursor: 'pointer' }}*/}
      {/*>*/}
      {/*  <WalletOutlined style={{ marginRight: '8px' }} /> {formatCurrency(data?.data?.amount || 0)}{' '}*/}
      {/*  VNĐ ({remainingTime})*/}
      {/*</div>*/}

      <Avatar />
      <CompanyDropdown />
      <SelectLang
        className={styles.action}
        onItemClick={async (click) => {
          await mutateAsync({
            language: mapLanguageToCountryCode(click.key),
          });
          window.localStorage.setItem('umi_locale', click.key);
          window.location.reload();
        }}
      />
    </Space>
  );
};
export default GlobalHeaderRight;
