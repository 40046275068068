import React, { useCallback, useEffect } from 'react';
import {
  DownOutlined,
  EditOutlined,
  LogoutOutlined,
  MailFilled,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Menu, Spin } from 'antd';
import { history } from 'umi';
import HeaderDropdown from '../HeaderDropdown';
import styles from './index.less';
import type { MenuInfo } from 'rc-menu/lib/interface';
import useMyProfile from '@/services/api/account/useMyProfile';
import { logout } from '@/services/api/account/useSignIn';
import useCompanyId from '@/hooks/useCompanyId';
import * as Sentry from '@sentry/browser';
import { useIntl } from '@@/plugin-locale/localeExports';
import { useMediaQuery } from 'react-responsive';

export type GlobalHeaderRightProps = {
  menu?: boolean;
};

const AvatarDropdown: React.FC<GlobalHeaderRightProps> = ({ menu }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' });
  const { data, isLoading } = useMyProfile();
  const { routes } = useCompanyId();
  const { formatMessage: $t } = useIntl();
  const onMenuClick = useCallback((event: MenuInfo) => {
    const { key } = event;
    if (key === 'logout') {
      // setInitialState((s) => ({ ...s, currentUser: undefined }));
      logout();
      return;
    }
    if (key === 'change-password') {
      history.push(routes.changePassword);
      return;
    }
    history.push(`./account/${key}`);
  }, []);

  const menuHeaderDropdown = (
    <Menu className={styles.menu} selectedKeys={[]} onClick={onMenuClick}>
      {menu && (
        <Menu.Item key="center">
          <UserOutlined />
          个人中心
        </Menu.Item>
      )}
      {menu && (
        <Menu.Item key="settings">
          <SettingOutlined />
          个人设置
        </Menu.Item>
      )}
      {menu && <Menu.Divider />}

      <Menu.Item key="change-password">
        <EditOutlined />
        {$t({ id: 'i_mt_khu' })}
      </Menu.Item>
      <Menu.Item key="logout">
        <LogoutOutlined />
        {$t({ id: 'ng_xut' })}
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (!data?.data?.email) {
      Sentry.configureScope((scope) => scope.setUser(null));
      return;
    }
    Sentry.setUser({ email: data?.data?.email });
  }, [data?.data?.email]);

  if (isLoading) {
    return (
      <span className={`${styles.action} ${styles.account}`}>
        <Spin
          size="small"
          style={{
            marginLeft: 8,
            marginRight: 8,
          }}
        />
      </span>
    );
  }

  return (
    <HeaderDropdown overlay={menuHeaderDropdown}>
      <span className={`${styles.action} ${styles.account}`}>
        <MailFilled style={{ paddingRight: '8px' }} />
        {!isMobile && <span className={`${styles.name} anticon`}>{data?.data?.email}</span>}
        <DownOutlined style={{ paddingLeft: '20px' }} />
      </span>
    </HeaderDropdown>
  );
};

export default AvatarDropdown;
