import { REMEMBER_COMPANY_KEY } from '@/pages/onboard';
import { envs, isDisplayUleads, isLandingPage } from '@/constants';

/**
 * @see https://umijs.org/zh-CN/plugins/plugin-access
 * */
export default function access(initialState: any) {
  // const current_partner = initialState?.current_partner;
  // const isPartner =
  //   !current_partner?.is_default ||
  //   initialState?.profile?.types?.some((e: string) => e === 'partner');
  const isPartner = initialState?.profile?.types?.some((e: string) => e === 'partner');

  const currentCompanyId = window.localStorage.getItem(REMEMBER_COMPANY_KEY) ?? '';
  const currentCompany = initialState?.profile?.companies?.find((company) => {
    return company?.slug === currentCompanyId;
  });
  const isUcall = currentCompany?.partner?.is_default;

  return {
    canAdmin: true,
    displayInvitePartner: currentCompany?.partner?.is_default && !isPartner,
    isPartner: isPartner,
    displayHelp: isPartner || isUcall,
    isUcall: isUcall,
    isDisplayUleads: isDisplayUleads,
    isLandingPage: isLandingPage,
    isDev: envs.IS_DEV,
  };
}
