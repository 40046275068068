import { useQuery } from 'react-query';
import axiosInstance from '@/services/api/axiosInstance';
import type { ServerFormError } from '@/services/api/types';
import { ILabel } from '@/services/api/types';
import type { AxiosResponse } from 'axios';
import useCompanyId from '@/hooks/useCompanyId';

export const useCustomerLabelsKey = 'labels';

export function useCompanyCustomerLabels() {
  const { COMPANY_ID, apiRoutes } = useCompanyId();
  return useQuery<AxiosResponse<ILabel[]>, ServerFormError>(
    [useCustomerLabelsKey, COMPANY_ID],
    () => {
      return axiosInstance.get(apiRoutes.labels, {});
    },
  );
}

function useCustomerLabels(AGENT_CAMPAIGN_ID: string) {
  const { COMPANY_ID, apiRoutes } = useCompanyId();
  return useQuery<AxiosResponse<ILabel[]>, ServerFormError>(
    [useCustomerLabelsKey, COMPANY_ID, AGENT_CAMPAIGN_ID],
    () => {
      return axiosInstance.get(apiRoutes.agent_campaign.labels(AGENT_CAMPAIGN_ID).list, {});
    },
  );
}

export default useCustomerLabels;
