import { message, Spin } from 'antd';
import { routesPath } from '../../config/routes';
import { useEffect } from 'react';
import { history } from '@@/core/history';
import { useLocation } from 'umi';
import useVerifyEmail from '@/services/api/account/useVerifyEmail';
import { useLocalstorage } from 'rooks';
import { CONFIRMED_ACCOUNT_KEY } from '@/constants';
import { useIntl } from '@@/plugin-locale/localeExports';

const ConfirmationPage = () => {
  const { remove } = useLocalstorage(CONFIRMED_ACCOUNT_KEY, true);
  const { mutateAsync } = useVerifyEmail();
  const { query } = useLocation();
  const { formatMessage } = useIntl();

  const email = query?.email;
  const token = query?.token;
  const confirmSuccess = () => {
    message.success(formatMessage({ id: 'account_confirmed' }));
    remove();
    window.location.href = routesPath.onboard;
  };

  const confirmFailed = () => {
    history.push(routesPath.login);
    message.error(formatMessage({ id: 'confirm_code_incorrect' }), 7);
  };

  useEffect(() => {
    if (email && token) {
      mutateAsync({ email, token })
        .then(() => {
          confirmSuccess();
        })
        .catch(() => {
          confirmFailed();
        });
    }
  }, [email, token]);

  return (
    <div className="d-flex align-items-center justify-content-center h-100">
      <Spin />
    </div>
  );
};

export default ConfirmationPage;
