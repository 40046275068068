import type { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import axiosInstance from '@/services/api/axiosInstance';
import useCompanyId from '@/hooks/useCompanyId';
import type { ServerFormError } from '@/services/api/types';
export const useUserInfoKey = 'user-detail';

export interface IUserInfo {
  user: {
    id: string;
    full_name: string;
    email: string;
    phone_number: string;
    apiKey: string;
    identification_card_front: any;
    identification_card_back: any;
    language: string;
  };
  company: {
    id: string;
    name: string;
    slug: string;
    partner: {
      id: string;
      ratio: number;
      is_default: boolean;
      name: string;
      logo: string;
      dark_logo: string;
      color: string;
      language: string;
      autocall_block: number;
      xfercall_block: number;
      scenariocall_block: number;
      price_voice_record: number;
      price_voice_basic: number;
      price_voice_advance: number;
      price_hotline_hub: number;
      price_hotline_own: number;
      price_social_start_crawl: number;
      price_social_call_success: number;
      bank_name: string;
      bank_number: string;
      bank_user: string;
      bank_content: string;
      bank_qr: string;
      bank_id: string;
      vietqr_template: string;
    };
    is_tax: boolean;
    company_name: string;
    tax: string;
    tax_email: string;
    location: string;
  };
}

export const mapCountryCodeToLanguage = (dbCountryCode: string) => {
  if (dbCountryCode === 'US') {
    return 'en-US';
  }
  if (dbCountryCode === 'CN') {
    return 'zh-CN';
  }
  if (dbCountryCode === 'KH') {
    return 'km-KH';
  }
  return 'vi-VN';
};

export const mapLanguageToCountryCode = (language: string | null) => {
  if (language === 'en-US') {
    return 'US';
  }
  if (language === 'zh-CN') {
    return 'CN';
  }
  if (language === 'km-KH') {
    return 'KH';
  }
  return 'VN';
};

function useUserInfo() {
  const { apiRoutes, COMPANY_ID } = useCompanyId();
  return useQuery<AxiosResponse<IUserInfo>, ServerFormError>(
    [useUserInfoKey, COMPANY_ID],
    () => {
      return axiosInstance.get(apiRoutes.user.details);
    },
    {
      onSuccess: async (data) => {
        const dbCountryCode = data?.data?.user?.language;
        const language = mapCountryCodeToLanguage(dbCountryCode);

        window.localStorage.setItem('umi_locale', language);
      },
    },
  );
}

export default useUserInfo;
