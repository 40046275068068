// @ts-nocheck

  import StarOutlined from '@ant-design/icons/es/icons/StarOutlined';
import TeamOutlined from '@ant-design/icons/es/icons/TeamOutlined';
import PhoneOutlined from '@ant-design/icons/es/icons/PhoneOutlined';
import ImportOutlined from '@ant-design/icons/es/icons/ImportOutlined';
import FileOutlined from '@ant-design/icons/es/icons/FileOutlined';
import NotificationOutlined from '@ant-design/icons/es/icons/NotificationOutlined';
import WalletOutlined from '@ant-design/icons/es/icons/WalletOutlined';
import BellOutlined from '@ant-design/icons/es/icons/BellOutlined';
import SettingOutlined from '@ant-design/icons/es/icons/SettingOutlined';
import QuestionCircleOutlined from '@ant-design/icons/es/icons/QuestionCircleOutlined';
import GiftOutlined from '@ant-design/icons/es/icons/GiftOutlined'
  export default {
    StarOutlined,
TeamOutlined,
PhoneOutlined,
ImportOutlined,
FileOutlined,
NotificationOutlined,
WalletOutlined,
BellOutlined,
SettingOutlined,
QuestionCircleOutlined,
GiftOutlined
  }