import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import { Button, Image, Drawer, Spin, Modal, Divider } from 'antd';
import useDisclosure from '@/hooks/useDisclosure';
import useGuide from '@/services/api/guide/useGuide';
import isImage from 'is-image';
import { QuestionOutlined } from '@ant-design/icons';
import { BiLinkExternal } from 'react-icons/bi';
import useCurrentCompany from '@/services/api/company/useCurrentCompany';
import { MenuItemCompanySocial } from '../MenuItemCompanySocial';

interface DrawerHelperState {
  setCurrentSlug: (x: string) => void;
}

interface DrawerHelperProviderProps {
  children: ReactNode;
}

const DrawerHelperContext = createContext<DrawerHelperState | undefined>(undefined);

const DrawerHelperProvider = ({ children }: DrawerHelperProviderProps) => {
  const [currentSlug, setCurrentSlug] = useState<string | undefined>(undefined);
  const { isOpen, toggle, close: onClose } = useDisclosure(false);
  const { isOpen: isOpenChild, toggle: toggleChild, close: onCloseChild } = useDisclosure(false);
  const [selectingIndex, setSelectingIndex] = useState<number | undefined>(undefined);
  const { data, isLoading } = useGuide(currentSlug);
  const { data: companyData } = useCurrentCompany();
  const defaultPartner = companyData?.data?.partner?.is_default;
  const item = data?.data?.find((_item) => _item?.id === selectingIndex);
  const links = data?.data?.filter((type) => type.type === 'LINK');
  const demos = data?.data?.filter((type) => type.type === 'DEMO');

  useEffect(() => {
    setSelectingIndex(undefined);
  }, [data]);

  return (
    <DrawerHelperContext.Provider value={{ setCurrentSlug }}>
      {children}
      <div
        onClick={toggle}
        style={{
          cursor: 'pointer',
          borderRadius: '50%',
          backgroundColor: 'rgba(0,0,0,0.65)',
          width: 24,
          height: 24,
          position: 'fixed',
          zIndex: 1000,
          right: 160,
          bottom: 8,
          boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
          visibility: defaultPartner ? 'unset' : 'hidden',
        }}
        className="d-flex justify-content-center align-items-center"
      >
        <QuestionOutlined style={{ color: 'white', fontSize: '16px' }} />
      </div>
      <Drawer title="Hướng dẫn sử dụng" placement="right" onClose={onClose} visible={isOpen}>
        {isLoading && <Spin />}
        {!isLoading && (!data || data?.data?.length === 0) && (
          <div>Nội dung đang được cập nhật</div>
        )}
        <div className="row">
          {demos?.map((guide) => (
            <div
              className="col-6"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setSelectingIndex(guide.id);
                toggleChild();
              }}
            >
              <img
                src={guide.image}
                width="100%"
                className="rounded"
                style={{ maxHeight: 120, objectFit: 'cover' }}
              />
              <div className="mt-2">{guide.name}</div>
            </div>
          ))}
        </div>
        {links && links?.length !== 0 && (
          <>
            <Divider />
            <div className="row">
              {links?.map((guide) => (
                <div className="col-12 mt-1">
                  <a
                    href={guide.link}
                    target="_blank"
                    rel="noreferrer"
                    className="d-flex align-items-center "
                  >
                    <BiLinkExternal style={{ marginRight: '6px' }} />
                    {guide.name}
                  </a>
                </div>
              ))}
            </div>
          </>
        )}
        <MenuItemCompanySocial />
        <Modal
          title={item?.name}
          cancelButtonProps={{ hidden: 'true' }}
          okButtonProps={{ hidden: 'true' }}
          visible={isOpenChild}
          onOk={onCloseChild}
          onCancel={onCloseChild}
          width={800}
        >
          <div>
            <p>{item?.description}</p>
            {isImage(item?.file) ? (
              <Image src={item?.file} />
            ) : (
              <video width="100%" height="240" controls>
                <source src={item?.file} type="video/mp4" />
              </video>
            )}
          </div>
        </Modal>
      </Drawer>
    </DrawerHelperContext.Provider>
  );
};

export function useDrawerHelper(): DrawerHelperState {
  const context = useContext<any>(DrawerHelperContext);

  if (!context) {
  }
  return context;
}

export default DrawerHelperProvider;
