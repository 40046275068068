import useNotifications from '@/services/api/notification/useNotifications';
import { Alert, Modal } from 'antd';
import Button from 'antd-button-color';
import useDisclosure from '@/hooks/useDisclosure';
import { NotificationCard } from '@/pages/notification';
import CountUp from 'react-countup';
import { useEffect } from 'react';

const formatter = (value: number) => <CountUp end={value} separator="," />;

const ListImportantNotifications = () => {
  const { isOpen, open, close } = useDisclosure();
  const { data: notificationsData, refetch } = useNotifications({ page_size: 500, page: 1 });
  const notifications = notificationsData?.data?.results;

  const priorityNotifications = notifications?.filter((e) => e.priority === 0 && !e?.is_read);

  useEffect(() => {
    if (priorityNotifications && priorityNotifications?.length > 0) {
      open();
    } else {
      close();
    }
  }, [priorityNotifications]);

  if (priorityNotifications?.length === 0 || !priorityNotifications) {
    return <></>;
  }
  return (
    <>
      <Alert
        message={
          <b>Bạn có {formatter(priorityNotifications?.length || 0)} thông báo quan trọng </b>
        }
        onClick={open}
        showIcon
        type="warning"
        style={{ cursor: 'pointer' }}
        action={
          <Button onClick={open} type="primary" size="middle">
            Chi tiết
          </Button>
        }
      />

      <Modal
        visible={isOpen}
        closable
        onCancel={close}
        bodyStyle={{ backgroundColor: '#f0f2f5' }}
        width={800}
        title={<b>Bạn có {formatter(priorityNotifications?.length || 0)} thông báo quan trọng </b>}
        cancelButtonProps={{ hidden: 'true' }}
        okButtonProps={{ hidden: 'true' }}
      >
        <div style={{ marginBottom: '12px' }}>
          <i>Bấm vào thông báo để đánh dấu đã đọc</i>
        </div>
        {priorityNotifications?.map((noti) => {
          return <NotificationCard item={noti} callback={refetch} />;
        })}
      </Modal>
    </>
  );
};

export default ListImportantNotifications;
