import React, { useState } from 'react';
import { Input, Row, Switch, Typography, message } from 'antd';
import parsePhoneNumber from 'libphonenumber-js';
import { PhoneOutlined } from '@ant-design/icons';
import './index.less';
import Button from 'antd-button-color';
import { useIntl } from '@@/plugin-locale/localeExports';
import useInterval from 'use-interval';

const numpads = [
  {
    number: '1',
    text: '\n',
  },
  {
    number: '2',
    text: 'ABC',
  },
  {
    number: '3',
    text: 'DEF',
  },
  {
    number: '4',
    text: 'GHI',
  },
  {
    number: '5',
    text: 'JKL',
  },
  {
    number: '6',
    text: 'MNO',
  },
  {
    number: '7',
    text: 'PQRS',
  },
  {
    number: '8',
    text: 'TUV',
  },
  {
    number: '9',
    text: 'WXYZ',
  },
  {
    number: '0',
    text: '',
  },
];

const DialPad = ({ toggle }) => {
  const [readyReceiveCall, setReadyReceiveCall] = useState(true);

  const [phoneNumber, setPhoneNumber] = useState('');

  const { formatMessage } = useIntl();

  useInterval(() => {
    setReadyReceiveCall(window.readyReceiveCall);
  }, 1000);

  return (
    <div className="d-flex flex-column" style={{ minWidth: '320px' }}>
      <div
        className="d-flex flex-row align-items-center justify-content-between w-100"
        style={{ marginBottom: '12px' }}
      >
        <Typography.Text>
          <b>{formatMessage({ id: 'sn_sng_nhn_cuc_gi' })}</b>
        </Typography.Text>
        <Switch
          // disabled={isOnCall}
          checked={readyReceiveCall}
          onChange={(value) => {
            window.ToggleDoNoDisturb();
          }}
        />
      </div>
      <div className="w-100">
        <Input
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          onBlur={(e) => {
            if (e.target.value === null || e.target.value === '') {
              return;
            }
            const phoneNumber = parsePhoneNumber(e.target.value, 'VN');
            setPhoneNumber(`0${phoneNumber?.nationalNumber || ''}`);
          }}
          placeholder={formatMessage({ id: 'vui_lng_nhp_s_in_thoi_cn_gi' })}
        />
      </div>

      <Row align="middle" justify="center" style={{ marginTop: '16px' }}>
        <Button
          type="success"
          onClick={() => {
            const regex = /^(0|84)(\d{9,10})$/;
            if (!regex.test(phoneNumber)) {
              message.error('Số điện thoại không hợp lệ');
              setPhoneNumber('');
              return;
            } else if (phoneNumber === '') {
              message.error('Vui lòng nhập số điện thoại');
              setPhoneNumber('');
              return;
            } else {
              window.makeCallToPhoneBridge(phoneNumber);
              toggle();
            }
          }}
        >
          <PhoneOutlined />
          {formatMessage({ id: 'gi_in' })}
        </Button>
      </Row>
    </div>
  );
};

export default DialPad;
