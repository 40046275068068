import { formatDateFromString } from '@/services/api/util';
import { Alert, Card, DatePicker, Form, Input, Popconfirm, Select, Space, Tag } from 'antd';
import Button from 'antd-button-color';
import React, { useEffect, useRef } from 'react';
import useDeleteCustomerNote from '@/services/api/customer_note/useDeleteCustomerNote';
import useCustomerNotes from '@/services/api/customer_note/useCustomerNotes';
import useDisclosure from '@/hooks/useDisclosure';
import useUpdateCustomerNote from '@/services/api/customer_note/useUpdateCustomerNote';
import moment from 'moment';
import { UnmountClosed } from 'react-collapse';
import { useIntl } from 'umi';
import { FormattedMessage } from '@@/plugin-locale/localeExports';
import { useCompanyCustomerLabels } from '@/services/api/customer_labels/useCustomerLabels';

const WrappedSelect = (props) => {
  const ref = useRef();
  return (
    <Select
      {...props}
      ref={ref}
      onChange={(_prop) => {
        props.onChange(_prop);
        ref.current.blur();
      }}
    />
  );
};

const CustomerNoteAlert = ({ CUSTOMER_ID, customerNote }) => {
  const { isOpen, open, close } = useDisclosure(false);
  const [form] = Form.useForm();
  const t = useIntl();
  const { formatMessage } = t;
  const { refetch: refetchCustomerNote } = useCustomerNotes(CUSTOMER_ID);
  const { mutateAsync: deleteCustomer, isLoading: isDeleting } = useDeleteCustomerNote(
    CUSTOMER_ID,
    customerNote?.id,
  );
  const { mutateAsync: updateCustomerNote, isLoading: isUpdating } = useUpdateCustomerNote(
    CUSTOMER_ID,
    customerNote?.id,
  );
  const { data: customerLabels, isLoading: isLoadingCustomerLabel } = useCompanyCustomerLabels();

  const onFinish = async (values) => {
    await updateCustomerNote({
      message: values?.message,
      labels: values?.labels,
      // remind_at: values?.remind_at?.toISOString(),
    });
    await refetchCustomerNote();
    close();
  };

  useEffect(() => {
    form.setFieldsValue({
      message: customerNote?.message,
      remind_at: moment(customerNote?.remind_at),
    });
  }, []);

  const noteLabels = customerNote?.labels;
  return (
    <>
      <Alert
        type="warning"
        message={
          <span>
            <FormattedMessage
              id="li_nhn"
              defaultMessage="Lời nhắn: <i>{{message}} </i>"
              values={{
                message: customerNote?.message,
                i: (chunks) => <i>{chunks}</i>,
              }}
            />
          </span>
        }
        description={
          <div className="d-flex flex-row align-items-center">
            <Space size={[0, 8]} wrap>
              {noteLabels?.map((e) => (
                <Tag color="blue">{e?.text}</Tag>
              ))}
            </Space>
          </div>
          // <span className="text-muted">
          //   {formatMessage({ id: 'nhc_ti_vo_lc' })} {formatDateFromString(customerNote?.remind_at)}
          // </span>
        }
        action={
          <Space direction="vertical" style={{ width: '100%' }}>
            <Button
              onClick={() => {
                open();
              }}
              size="small"
              style={{ width: '100%' }}
              type="primary"
            >
              {formatMessage({ id: 'cp_nht' })}
            </Button>
            <Popconfirm
              onConfirm={() => {
                deleteCustomer().then(() => {
                  refetchCustomerNote();
                });
              }}
              title={formatMessage({ id: 'bn_c_chc_khng' })}
              okText={formatMessage({ id: 'agree' })}
              cancelText={formatMessage({ id: 'close' })}
            >
              <Button
                loading={isDeleting}
                size="small"
                style={{ width: '100%' }}
                danger
                type="ghost"
              >
                {formatMessage({ id: 'xo' })}
              </Button>
            </Popconfirm>
          </Space>
        }
      />

      <UnmountClosed isOpened={isOpen}>
        <Card>
          <Form form={form} layout="vertical" onFinish={onFinish} autoComplete="off">
            <Form.Item
              name="message"
              label={formatMessage({ id: 'li_nhn2' })}
              rules={[{ required: true, message: formatMessage({ id: 'field_required' }) }]}
            >
              <Input placeholder={formatMessage({ id: 'khch_hn_lin_h_vo_th_3_9_gi' })} />
            </Form.Item>
            <Form.Item
              name="labels"
              label="Chọn nhãn khách hàng"
              rules={[{ required: true, message: formatMessage({ id: 'vui_lng_nhp' }) }]}
            >
              <WrappedSelect
                loading={isLoadingCustomerLabel}
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                defaultValue={noteLabels?.map((label) => label?.id)}
                onChange={(values) => {
                  form.setFieldsValue({
                    labels: values,
                  });
                }}
              >
                {customerLabels?.data?.map((label) => {
                  return <Option value={label?.id}>{label?.text}</Option>;
                })}
              </WrappedSelect>
            </Form.Item>
            {/*<Form.Item*/}
            {/*  name="remind_at"*/}
            {/*  label={formatMessage({ id: 'nhc_ti_vo_lc2' })}*/}
            {/*  rules={[{ required: true, message: formatMessage({ id: 'field_required' }) }]}*/}
            {/*>*/}
            {/*  <DatePicker style={{ width: '100%' }} showTime format="DD-MM-YYYY HH:mm:ss" />*/}
            {/*</Form.Item>*/}

            <Button type="primary" loading={isUpdating} htmlType="submit">
              {formatMessage({ id: 'lu' })}
            </Button>
          </Form>
        </Card>
      </UnmountClosed>
    </>
  );
};

export default CustomerNoteAlert;
