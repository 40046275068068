import en from './app/en.json';

export default {
  ...en,
  'use_cases.REAL_ESTATE': 'Real Estate',
  'use_cases.INSURANCE': 'Insurance',
  'use_cases.FINANCE': 'Finance',
  'use_cases.EDUCATION': 'Education',
  'use_cases.BEAUTY': 'Other',
  'use_cases.SOFTWARE': 'Software',
  'use_cases.ADVISORY': 'Tax Consultant',
  'use_cases.MEDICAL': 'Medical',
  'use_cases.EVENT': 'Event',
  'use_cases.HIRING': 'Recruiting Agents',
  'use_cases.OTHER': 'Other',
  'problems.title': 'Business problems',
  'header.homepage': 'Homepage',
  'header.solution': 'Solution',
  'header.product': 'Product',
  'header.customer': 'Customer',
  'header.help': 'User Guide',
  'header.pricing': 'Price',
  'header.blog': 'Blog',
  'header.start_button': 'Try it out',
  'header.login': 'Login',
  'header.contact': 'Contact',
  'header.use_cases': 'Use Cases',
  'footer.contact': 'Contact info',
  'footer.contact_number': 'Sales Department: 0249 9999 468',
  'footer.company_name': 'UCALL TECHNOLOGY CORPORATION',
  'footer.address': 'Address',
  'footer.company_address':
    'No. 221 Tran Quang Khai, Tan Dinh Ward, District 1\nHo Chi Minh City, Vietnam',
  'footer.company_license':
    'Certificate of Business Registration No. 0109690915 First issued by Business Registration Office - Hanoi Department of Planning and Investment on July 1, 2021',
  'footer.terms_and_conditions': 'Policies & terms',
  'footer.terms_using': 'Terms of Use',
  'footer.terms_privacy': 'Privacy Policy',
  'footer.terms_delivery': 'Shipping policy',
  'footer.terms_refund': 'Refund Policy',
  'footer.terms_payment': 'Payment Policy',
  'banner1.title': 'Automatic Callbot Platform',
  'banner1.subtitle':
    'Auto call solution Telesales, Telemarketing & Customer care\nhelp businesses optimize costs, time and increase sales efficiency.',
  'banner1.button1': 'Contact consultant',
  'banner1.button2': 'Free experience',
  'banner1.button2b': 'Experience it now',
  'use_case.title': 'Solution application',
  'use_case.subtitle': 'Automatic Telesales, Telemarketing & Customer Care Solution',
  'demo.title': 'Callbot Experience',
  'demo.select_script': 'Screen Selection',
  'demo.real_estate': 'Real estate (recording)',
  'demo.finance': 'Finance (recording)',
  'demo.education': 'Education (personalization)',
  'demo.beauty': 'Beauty salon (personalization)',
  'demo.form.name.required_message': 'Please enter your name',
  'demo.form.name.placeholder': 'Your name',
  'demo.form.phone_number.required_message': 'Please enter phone number',
  'demo.form.phone_number.placeholder': 'Phone number',
  'demo.form.gender.placeholder': 'Please choose a title',
  'demo.form.gender.male': 'Brother',
  'demo.form.gender.female': 'Sister',
  'demo.form.call': 'Test call',
  'products.CALLBOT_AI_AUTO_CALL': 'Callbot AI calls automatically',
  'products.CALL_CENTER': 'Call Center',
  'products.CUSTOMERS_DATA': 'Search for customers',
  'solution.title': 'UCALL solution',
  'solution.subtitle': 'Telesales, Telemarketing & Automated Customer Care',
  'solution.data.title': '',
  'solution.data.description1': '',
  'solution.data.description2': '',
  'solution.call.title': 'Callbot automatic call',
  'solution.call.description1': '• Make thousands of simultaneous calls to customers',
  'solution.call.description2': '• Smart script design, natural interaction',
  'solution.call.description3': '• Intuitive, easy-to-understand campaign results reports',
  'solution.cta': 'Learn More',

  'callbot.solution1.title': 'Telesales solution',
  'callbot.solution1.subtitle': 'Callbot Auto Call solution increases business efficiency',
  'callbot.solution1.part_1.text':
    'Auto Call software helps businesses call thousands of automatic and smart calls with just 1 mouse click',
  'callbot.solution1.part_2.text':
    'Callbot AI script is flexible with natural voice creating and responding to smart customers like 99% of callers',

  'call_center.solution1.title': 'Call center solution',
  'call_center.solution1.subtitle': 'Smart Smart Call Center for Business',
  'call_center.solution1.part_1.text':
    'Smart virtual switchboard service helps businesses manage and operate the Telesales team and effective customer care',
  'call_center.solution1.part_2.text':
    'Telesales software helps increase 200% of labor productivity of Telesales, Telemarketing and customer care',

  'uleads.solution1.title': 'Customer data solution',
  'uleads.solution1.subtitle': 'Solution to find smart customer data for businesses',
  'uleads.solution1.part_1.text':
    'Solution to find B2B business customer data and customer data scanning phone numbers from Facebook for Telesales',
  'uleads.solution1.part_2.text':
    'Large data enriched customer data helps businesses understand Insight customers',

  'callbot.problems.title': 'Telesales problems of the business',
  'callbot.problems.problem1.title': 'It takes a lot of time Telesales',
  'callbot.problems.problem1.description':
    "Low employee calling ability and time -consuming call quality management of staff leads to losing customers in the opponent's hands.",
  'callbot.problems.problem2.title': "Can't recruit Telesales for a large project",
  'callbot.problems.problem2.description':
    'With large projects, businesses need to recruit a number of Telesales. However, the recruitment and training process takes a lot of time, Telesales services are expensive and difficult to manage call quality.',
  'callbot.problems.problem3.title': 'Wasting cost of operation',
  'callbot.problems.problem3.description':
    'Businesses cost a lot to pay for telecommunications, employees, offices, management and purchase of expensive switchboard equipment but ineffective.',
  'call_center.problems.title': "The problems of the enterprise's switchboard",
  'call_center.problems.problem1.title': 'Operating the switchboard system inefficiently',
  'call_center.problems.problem1.description':
    'Businesses cost a lot of money for telecommunications, personnel, management and switchboard equipment expensive but have not optimized the effectiveness of the switchboard staff.',
  'call_center.problems.problem2.title': 'Difficulties in managing call quality',
  'call_center.problems.problem2.description':
    "Businesses are difficult to evaluate the quality of the staff's calls. Since then, businesses have not optimized effectively Telesales, Telemarketing and customer care.",
  'call_center.problems.problem3.title': 'Customer experience has not been optimized',
  'call_center.problems.problem3.description':
    'Businesses have not optimized the operator effectively, has not synchronized customer data and the sales process makes customers not care in time and appropriately.',
  'uleads.problems.title': 'Customer data problems of the business',
  'uleads.problems.problem1.title': 'Lack of potential customers',
  'uleads.problems.problem1.description':
    'Businesses have difficulty creating quality potential customers because of the lack of search engines and filtering new potential customers.',
  'uleads.problems.problem2.title': 'Do not understand the customer insight',
  'uleads.problems.problem2.description':
    'Customer data of businesses must not be enriched and exploited effectively. Since then, have not taken advantage of customer data to optimize business efficiency.',
  'uleads.problems.problem3.title': 'Customer search cost increases',
  'uleads.problems.problem3.description':
    'The cost of running advertising finds potential customers is increasing. Businesses have not accessed solutions to find smart and economical customers.',

  'callbot.features.title': 'Features',
  'callbot.features.feature1.title': 'Search Customer Data',
  'callbot.features.feature1.0.title': 'B2B Client',
  'callbot.features.feature1.0.description': 'Select B2B customers by smart filter',
  'callbot.features.feature1.1.title': 'Social network data',
  'callbot.features.feature1.1.description': 'Search for customer data from social networks',
  'callbot.features.feature2.title': 'Build script',
  'callbot.features.feature2.0.title': 'Scenario design',
  'callbot.features.feature2.0.description':
    'Easy, flexible build or change the scenario to suit your needs',
  'callbot.features.feature2.1.title': 'Scenarios sample',
  'callbot.features.feature2.1.description':
    '200+ scenario templates available in more than 10 different fields (B2B, real estate, finance, insurance, education, healthcare, beauty care, travel, hotel, retail, software) ,...)',
  'callbot.features.feature3.title': 'Natural interaction',
  'callbot.features.feature3.0.title': 'Machine voice',
  'callbot.features.feature3.0.description':
    'Diverse male and female voices from different regions.',
  'callbot.features.feature3.1.title': 'Voice recording',
  'callbot.features.feature3.1.description': 'Can build scripts with recorded real human voices',
  'callbot.features.feature3.2.title': 'Speech recognition',
  'callbot.features.feature3.2.description':
    'Automatically identify and respond to flexible customer answers',
  'callbot.features.feature4.title': 'Campaign report',
  'callbot.features.feature4.0.title': 'Overview report',
  'callbot.features.feature4.0.description': 'Intuitive call campaign overview report statistics',
  'callbot.features.feature4.1.title': 'Scenarios sample',
  'callbot.features.feature4.1.description':
    'Store call content from call recording, recognize text and automatically classify customers',

  'call_center.features.title': 'Features',
  'call_center.features.feature1.title': 'Search Customer Data',
  'call_center.features.feature1.0.title': 'B2B Customer',
  'call_center.features.feature1.0.description': 'Select B2B customers by smart filter',
  'call_center.features.feature1.1.title': 'Social network data',
  'call_center.features.feature1.1.description': 'Search for customer data from social networks',
  'call_center.features.feature2.title': 'Build script',
  'call_center.features.feature2.0.title': 'Scenario design',
  'call_center.features.feature2.0.description':
    'Easy, flexible build or change the scenario to suit your needs',
  'call_center.features.feature2.1.title': 'Scenario sample',
  'call_center.features.feature2.1.description':
    '200+ scenario templates available in more than 10 different fields (B2B, real estate, finance, insurance, education, healthcare, beauty care, travel, hotel, retail, software) ,...)',
  'call_center.features.feature3.title': 'Natural interaction',
  'call_center.features.feature3.0.title': 'Machine voice',
  'call_center.features.feature3.0.description':
    'Diverse male and female voices from different regions.',
  'call_center.features.feature3.1.title': 'Voice recording',
  'call_center.features.feature3.1.description':
    'Can build scripts with real human voices recorded',
  'call_center.features.feature3.2.title': 'Speech recognition',
  'call_center.features.feature3.2.description':
    'Automatically identify and respond to flexible customer answers',
  'call_center.features.feature4.title': 'Campaign report',
  'call_center.features.feature4.0.title': 'Overview report',
  'call_center.features.feature4.0.description':
    'Intuitive call campaign overview report statistics',
  'call_center.features.feature4.1.title': 'Screen sample',
  'call_center.features.feature4.1.description':
    'Store call content from call recording, recognize text and automatically classify customers',

  'uleads.features.title': 'Features',
  'uleads.features.feature1.title': 'Search Customer Data',
  'uleads.features.feature1.0.title': 'B2B Client',
  'uleads.features.feature1.0.description': 'Select B2B customers by smart filter',
  'uleads.features.feature1.1.title': 'Social network data',
  'uleads.features.feature1.1.description': 'Search for customer data from social networks',
  'uleads.features.feature2.title': 'Build script',
  'uleads.features.feature2.0.title': 'Scenario design',
  'uleads.features.feature2.0.description':
    'Easy, flexible build or change the scenario to suit your needs',
  'uleads.features.feature2.1.title': 'Screen sample',
  'uleads.features.feature2.1.description':
    '200+ scenario templates available in more than 10 different fields (B2B, real estate, finance, insurance, education, healthcare, beauty care, travel, hotel, retail, software) ,...)',
  'uleads.features.feature3.title': 'Natural interaction',
  'uleads.features.feature3.0.title': 'Machine voice',
  'uleads.features.feature3.0.description':
    'Diverse male and female voices from different regions.',
  'uleads.features.feature3.1.title': 'Recorded Voice',
  'uleads.features.feature3.1.description': 'Can build scripts with recorded real human voices',
  'uleads.features.feature3.2.title': 'Speech recognition',
  'uleads.features.feature3.2.description':
    'Automatically identify and respond to flexible customer answers',
  'uleads.features.feature4.title': 'Campaign report',
  'uleads.features.feature4.0.title': 'Overview report',
  'uleads.features.feature4.0.description': 'Intuitive call campaign overview report statistics',
  'uleads.features.feature4.1.title': 'Scenario sample',
  'uleads.features.feature4.1.description':
    'Store call content from call recording, recognize text and automatically classify customers',

  'contact_form.modal_title': 'Contact consultation',
  'contact_form.form.name.label': 'First and Last Name',
  'contact_form.thank_you': 'We will contact you as soon as possible',
  'contact_form.form.email.label': 'Email address',
  'contact_form.form.phone_number.label': 'Phone number',
  'contact_form.form.industry.label': 'Industry',
  'contact_form.form.demands.label': 'Calls/month',
  'contact_form.form.notes.label': 'Notes',
  'contact_form.form.send_button': 'Gửi',
  'contact_form.industries.REAL_ESTATE': 'Real Estate',
  'contact_form.industries.FINANCE': 'Finance',
  'contact_form.industries.INSURANCE': 'Insurance',
  'contact_form.industries.EDUCATION': 'Education',
  'contact_form.industries.OTHER': 'Khác',
  'contact_form.industries.NULL': 'Unknown',
  'error_messages.required': 'Cannot be blank',
  'success_messages.calling': 'Well call you shortly...',
  'pricing.voice.title': 'Pricelist',
  'pricing.promotion.title': 'Promotion',
  'pricing.expire.title': 'Term',
  'pricing.voice.price': 'Cost',
  'pricing.voice.subtitle':
    'The actual cost of calls is calculated on block 6s to optimize costs for businesses.',
  'pricing.voice.unit': '/min',
  'pricing.voice.ucall_fee': 'UCALL platform price',
  'pricing.voice.carrier_fee': 'Telecommunications rates',
  'pricing.voice.personalize_fee': 'Personalized pricing',
  'pricing.voice.custom_fee':
    'Telecommunication charges when using the enterprise own switchboard number',
  'pricing.voice.custom_fee_description':
    'Customers pay directly to the carrier, depending on the carrier the customer registers',
  'pricing.plan.title': 'Package for sale',
  'pricing.plan.subtitle': '1M = 1,000,000 VND',

  'callbot.solution_banner.title': 'Callbot Auto Call service',
  'callbot.solution_banner.text1':
    'Callbot Auto Call is an artificial intelligence solution to help businesses',
  'callbot.solution_banner.text2': '• Optimize personnel costs and operate',
  'callbot.solution_banner.text3': '• Save Telesales time and customer care',
  'callbot.solution_banner.text4': '• Increase business efficiency',
  'call_center.solution_banner.title': 'Smart Callet Software',
  'call_center.solution_banner.text1':
    'Smart switchboard is a virtual switchboard solution to help businesses',
  'call_center.solution_banner.text2': '• Optimize management and operation costs',
  'call_center.solution_banner.text3': '• Save Telesales time and customer care',
  'call_center.solution_banner.text4': '• Increase business efficiency',
  'uleads.solution_banner.title': 'Uleads customer data search solution',
  'uleads.solution_banner.text1':
    'Search for customer data (Uleads) is a solution to help businesses',
  'uleads.solution_banner.text2': '• Search for thousands of potential customers in 1 minute',
  'uleads.solution_banner.text3': '• Big data filter with smart search filter',
  'uleads.solution_banner.text4': '• Help businesses understand customer insight',

  'callbot.use_case.title': 'Solution application',
  'callbot.use_cases.REAL_ESTATE': 'Property',
  'use_cases.callbot.REAL_ESTATE.detail':
    'Real estate is considered as one of the fastest growing and developing business fields today. Therefore, it is important for investors to always find ways to promote advertising and marketing. One of the popular ways of doing things today that many units choose is to build or outsource Telesale team to make calls to customers.\n' +
    '\n' +
    'With the growing technology market, choosing technology applications for better customer care is being interested by many units. One of them is the Auto call solution of the Callbot virtual switchboard \n',
  'callbot.use_cases.FINANCE': 'Finance',
  'use_cases.callbot.FINANCE.detail':
    'A financial enterprise is a type of non-banking credit institution. This organization has the function of using its own capital, mobilized capital and other capital sources to lend, invest, provide financial and monetary consulting services and perform a number of other services in accordance with the provisions of this Law. law. Therefore, credit institutions are always looking for ways to promote advertising and marketing to their customers. One of the popular ways of doing things today that many units choose is to build or outsource Telesale team to make calls to customers.\n' +
    '\n' +
    'Understanding the difficulties that financial businesses are facing, Callbot Auto Call solution was born to reach customers quickly and increase the business ability of financial businesses significantly. \n',
  'callbot.use_cases.INSURANCE': 'Insurance',
  'use_cases.callbot.INSURANCE.detail':
    'In today market, insurance types are divided very specifically based on the characteristics of the subjects and many insurance businesses are developing extremely strongly, continuously launching new product packages to meet diverse needs. customer need. Along with that, improving service quality is one of the important factors to help insurance businesses increase their competitive advantage, attract new customers and retain loyal customers. \n' +
    '\n' +
    'So the problem is that there needs to be a suitable solution to support businesses to solve all of the above problems. Thats why Callbot Ai Auto Call solution was born.\n',
  'callbot.use_cases.EDUCATION': 'Education',
  'use_cases.callbot.EDUCATION.detail':
    'Currently, there are many education centers that are struggling with the problems of data overload during the peak season as well as the large personnel costs to classify raw customer files. Education centers often have to Telesales to find new students every day. In addition, the centers also have to call to remind students of the schedule of courses and training sessions as well as to consult and take care of old students to understand more students needs. With the implementation of Telesales calls and customer care, Education centers consume a large amount of budget. \n' +
    '\n' +
    'To solve the above difficulties, UCALL has launched an automatic callbot solution to solve the above problem quickly and easily. Currently, the automatic callbot solution is being interested and chosen by many educational institutions to better support their business\n',

  'callbot.use_cases.BEAUTY': 'Yes',
  'callbot.use_cases.BEAUTY2': 'Beauty',
  'callbot.use_cases.SOFTWARE': 'Software',
  'callbot.use_cases.ADVISORY': 'Tax Consultant',
  'callbot.use_cases.MEDICAL': 'WATER',
  'callbot.use_cases.EVENT': 'Event',
  'callbot.use_cases.HIRING': 'Recruiting Agents',
  'callbot.use_cases.OTHER': 'Yes',
  'use_cases.callbot.OTHER.detail':
    'Currently, when the technology market is growing; This means the emergence of technology solutions to support businesses more and more. One of them is the appearance of Callbot. With the aim to reach customers quickly and increase business ability significantly. \n' +
    '\n' +
    'Therefore, the automatic callbot solution is being interested and chosen by many businesses to better support their business. To be able to lead the industry; The company always tries to satisfy its customers. Therefore, the choice of callbot is considered as a solution to improve customer experience; take your business to the next level.\n',

  'call_center.use_case.title': 'Solution application',
  'call_center.use_cases.REAL_ESTATE': 'Property',
  'use_cases.call_center.REAL_ESTATE.detail':
    'Real estate is considered as one of the fastest growing and developing business fields today. Therefore, it is important for investors to always find ways to promote advertising and marketing. One of the popular ways of doing things today that many units choose is to build or outsource Telesale team to make calls to customers.\n' +
    '\n' +
    'With the growing technology market, choosing technology applications for better customer care is being interested by many units. One of them is the Auto call solution of the Callbot virtual switchboard \n',
  'call_center.use_cases.FINANCE': 'Finance',
  'use_cases.call_center.FINANCE.detail':
    'A financial enterprise is a type of non-banking credit institution. This organization has the function of using its own capital, mobilized capital and other capital sources to lend, invest, provide financial and monetary consulting services and perform a number of other services in accordance with the provisions of this Law. law. Therefore, credit institutions are always looking for ways to promote advertising and marketing to their customers. One of the popular ways of doing things today that many units choose is to build or outsource Telesale team to make calls to customers.\n' +
    '\n' +
    'Understanding the difficulties that financial businesses are facing, Callbot Auto Call solution was born to reach customers quickly and increase the business ability of financial businesses significantly. \n',
  'call_center.use_cases.INSURANCE': 'Insurance',
  'use_cases.call_center.INSURANCE.detail':
    'In todays market, insurance types are divided very specifically based on the characteristics of the subjects and many insurance businesses are developing extremely strongly, continuously launching new product packages to meet diverse needs. customers need. Along with that, improving service quality is one of the important factors to help insurance businesses increase their competitive advantage, attract new customers and retain loyal customers. \n' +
    '\n' +
    'So the problem is that there needs to be a suitable solution to support businesses to solve all of the above problems. Thats why Callbot Ai Auto Call solution was born.\n',
  'call_center.use_cases.EDUCATION': 'Education',
  'use_cases.call_center.EDUCATION.detail':
    'Currently, there are many education centers that are struggling with the problems of data overload during the peak season as well as the large personnel costs to classify raw customer files. Education centers often have to Telesales to find new students every day. In addition, the centers also have to call to remind students of the schedule of courses and training sessions as well as to consult and take care of old students to understand more students needs. With the implementation of Telesales calls and customer care, Education centers consume a large amount of budget. \n' +
    '\n' +
    'To solve the above difficulties, UCALL has launched an automatic callbot solution to solve the above problem quickly and easily. Currently, the automatic callbot solution is being interested and chosen by many educational institutions to better support their business\n',
  'call_center.use_cases.BEAUTY': 'Khác',
  'call_center.use_cases.BEAUTY2': 'Beauty',
  'call_center.use_cases.SOFTWARE': 'Software',
  'call_center.use_cases.ADVISORY': 'Tax Consultant',
  'call_center.use_cases.MEDICAL': 'Y Tế',
  'call_center.use_cases.EVENT': 'Event',
  'call_center.use_cases.HIRING': 'Recruiting Agents',
  'call_center.use_cases.OTHER': 'Khác',
  'use_cases.call_center.OTHER.detail':
    'Currently, when the technology market is growing; This means the emergence of technology solutions to support businesses more and more. One of them is the appearance of Callbot. With the aim to reach customers quickly and increase business ability significantly. \n' +
    '\n' +
    'Therefore, the automatic callbot solution is being interested and chosen by many businesses to better support their business. To be able to lead the industry; The company always tries to satisfy its customers. Therefore, the choice of callbot is considered as a solution to improve customer experience; take your business to the next level.\n',

  'uleads.use_case.title': 'Solution application',
  'uleads.use_cases.REAL_ESTATE': 'Property',
  'use_cases.uleads.REAL_ESTATE.detail':
    'Real estate is considered as one of the fastest growing and developing business fields today. Therefore, it is important for investors to always find ways to promote advertising and marketing. One of the popular ways of doing things today that many units choose is to build or outsource Telesale team to make calls to customers.\n' +
    '\n' +
    'With the growing technology market, choosing technology applications for better customer care is being interested by many units. One of them is the Auto call solution of the Callbot virtual switchboard \n',
  'uleads.use_cases.FINANCE': 'Finance',
  'use_cases.uleads.FINANCE.detail':
    'A financial enterprise is a type of non-banking credit institution. This organization has the function of using its own capital, mobilized capital and other capital sources to lend, invest, provide financial and monetary consulting services and perform a number of other services in accordance with the provisions of this Law. law. Therefore, credit institutions are always looking for ways to promote advertising and marketing to their customers. One of the popular ways of doing things today that many units choose is to build or outsource Telesale team to make calls to customers.\n' +
    '\n' +
    'Understanding the difficulties that financial businesses are facing, Callbot Auto Call solution was born to reach customers quickly and increase the business ability of financial businesses significantly. \n',
  'uleads.use_cases.INSURANCE': 'Insurance',
  'use_cases.uleads.INSURANCE.detail':
    'In today market, insurance types are divided very specifically based on the characteristics of the subjects and many insurance businesses are developing extremely strongly, continuously launching new product packages to meet diverse needs. customer need. Along with that, improving service quality is one of the important factors to help insurance businesses increase their competitive advantage, attract new customers and retain loyal customers. \n' +
    '\n' +
    'So the problem is that there needs to be a suitable solution to support businesses to solve all of the above problems. That why Callbot Ai Auto Call solution was born.\n',
  'uleads.use_cases.EDUCATION': 'Education',
  'use_cases.uleads.EDUCATION.detail':
    'Currently, there are many education centers that are struggling with the problems of data overload during the peak season as well as the large personnel costs to classify raw customer files. Education centers often have to Telesales to find new students every day. In addition, the centers also have to call to remind students of the schedule of courses and training sessions as well as to consult and take care of old students to understand more students needs. With the implementation of Telesales calls and customer care, Education centers consume a large amount of budget. \n' +
    '\n' +
    'To solve the above difficulties, UCALL has launched an automatic callbot solution to solve the above problem quickly and easily. Currently, the automatic callbot solution is being interested and chosen by many educational institutions to better support their business\n',
  'uleads.use_cases.BEAUTY': 'Other',
  'uleads.use_cases.BEAUTY2': 'Beauty',
  'uleads.use_cases.SOFTWARE': 'Software',
  'uleads.use_cases.ADVISORY': 'Tax Advice',
  'uleads.use_cases.MEDICAL': 'WATER',
  'uleads.use_cases.EVENT': 'Event',
  'uleads.use_cases.HIRING': 'Recruiting Agents',
  'uleads.use_cases.OTHER': 'Khác',
  'use_cases.uleads.OTHER.detail':
    'Currently, when the technology market is growing; This means the emergence of technology solutions to support businesses more and more. One of them is the appearance of Callbot. With the aim to reach customers quickly and increase business ability significantly. \n' +
    '\n' +
    'Therefore, the automatic callbot solution is being interested and chosen by many businesses to better support their business. To be able to lead the industry; The company always tries to satisfy its customers. Therefore, the choice of callbot is considered as a solution to improve customer experience; take your business to the next level.\n',

  'use_cases.find_out': 'Learn more',
  'customers.title': 'Customers and Partners',
  'pros.title': 'Benefits',
  'feedbacks.title': 'Customer Feedback',
  'cases.solution.title': 'UCALL Solution',
  'cases.promotion_banner': 'Improve business efficiency',
  'cases.REAL_ESTATE.title':
    'CALLBOT AI AUTO CALL\n' + '50% COST OPTIMIZE\n' + 'Real Estate TELESALE',
  'cases.REAL_ESTATE.subtitle':
    'Callbot AI automatically calls to meet the needs of management and optimize the cost of converting potential customers in the field of Real Estate',
  'cases.REAL_ESTATE.problem1.title': 'Missing data',
  'cases.REAL_ESTATE.problem1.subtitle':
    'Lack of data sources at important times, making real estate business limited',
  'cases.REAL_ESTATE.problem2.title': 'Time consuming',
  'cases.REAL_ESTATE.problem2.subtitle':
    'Businesses have to spend hours every day to find leads in the raw data file (about 70% of non-lead data)',
  'cases.REAL_ESTATE.problem3.title': 'High cost',
  'cases.REAL_ESTATE.problem3.subtitle':
    'Spend 50% of Telesales personnel costs on prospecting, not to mention other operating costs',
  'cases.REAL_ESTATE.problem4.title': 'Missed opportunity',
  'cases.REAL_ESTATE.problem4.subtitle':
    'Real estate businesses that do not promptly approach potential customers may lose business opportunities in the future',
  'cases.REAL_ESTATE.solution1': 'Searching for customer data with real estate needs',
  'cases.REAL_ESTATE.solution2':
    'Support to develop telesales scenarios suitable for Real Estate customers',
  'cases.REAL_ESTATE.solution3': 'Make 5000 calls to customers within 1 hour',
  'cases.REAL_ESTATE.solution4': 'Intuitive, easy-to-understand call reporting management',
  'cases.REAL_ESTATE.pros1':
    'Quickly find potential customers, increase conversion rate for real estate businesses',
  'cases.REAL_ESTATE.pros2': 'Save cost and time for businesses\n',
  'cases.REAL_ESTATE.pros3': 'Reduce workload, help businesses focus on important jobs',
  'cases.REAL_ESTATE.pros4': 'Improve business efficiency for enterprises\n',
  'cases.FINANCE.title':
    'CALLBOT AI AUTO CALL \n' + '50% COST OPTIMIZE \n' + 'FINANCIAL TELESALE\n',
  'cases.FINANCE.subtitle':
    'Callbot AI calls automatically to meet the needs of managing and optimizing the cost of converting potential customers in the field of Finance',
  'cases.FINANCE.problem1.title': 'Missing data',
  'cases.FINANCE.problem1.subtitle':
    'Lack of data sources at important times, making Finance business limited',
  'cases.FINANCE.problem2.title': 'Time consuming',
  'cases.FINANCE.problem2.subtitle':
    'Each Financial consultant has to spend 6 hours a day calling and finding customers with financial needs in the raw data file (about 70% of the data is not potential customers), reducing work productivity. ',
  'cases.FINANCE.problem3.title': 'High cost',
  'cases.FINANCE.problem3.subtitle':
    'It costs 50% of Telesales staff cost to find potential customers, not to mention other operating costs.',
  'cases.FINANCE.problem4.title': 'Missed opportunity',
  'cases.FINANCE.problem4.subtitle':
    'Telesales staff can only handle 1 call at a time. This leads to low productivity and inability to reach a large volume of customers with financial needs, losing many sales opportunities.',
  'cases.FINANCE.solution1': 'Searching for customer data with financial needs',
  'cases.FINANCE.solution2':
    'Support to develop Telesales scenarios suitable for Financial customers',
  'cases.FINANCE.solution3': 'Make 5000 calls to customers within 1 hour',
  'cases.FINANCE.solution4': 'Intuitive, easy-to-understand call reporting management',
  'cases.FINANCE.pros1':
    'Quickly find potential customers, increase conversion rate for Finance businesses',
  'cases.FINANCE.pros2': 'Save cost and time for businesses\n',
  'cases.FINANCE.pros3': 'Reduce workload, help businesses focus on important jobs',
  'cases.FINANCE.pros4': 'Improve business efficiency for enterprises\n',
  'cases.INSURANCE.title':
    'CALLBOT AI AUTO CALL \n' + '50% COST OPTIMIZE \n' + 'TELESALE INSURANCE\n',
  'cases.INSURANCE.subtitle':
    'Callbot AI automatically calls to meet the needs of management and optimize the cost of converting potential customers in the insurance industry',
  'cases.INSURANCE.problem1.title': 'Missing data',
  'cases.INSURANCE.problem1.subtitle':
    'Lack of data source at important times, making insurance business limited',
  'cases.INSURANCE.problem2.title': 'Time consuming',
  'cases.INSURANCE.problem2.subtitle':
    'Each insurance consultant has to spend 6 hours a day calling and finding customers who have the need to buy Insurance in the raw data file (about 70% of the data is not potential customers), reducing work productivity. .',
  'cases.INSURANCE.problem3.title': 'High cost',
  'cases.INSURANCE.problem3.subtitle':
    'It costs 50% of Telesales staff cost to find potential customers, not to mention other operating costs.',
  'cases.INSURANCE.problem4.title': 'Missed opportunity',
  'cases.INSURANCE.problem4.subtitle':
    'Telesales staff can only handle 1 call at a time. This leads to low productivity and inability to reach a large volume of customers with Insurance needs.',
  'cases.INSURANCE.solution1': 'Searching for data of customers who want to buy Insurance',
  'cases.INSURANCE.solution2':
    'Support to develop Telesales scenarios suitable for Insurance customers',
  'cases.INSURANCE.solution3': 'Make 5000 calls to customers within 1 hour',
  'cases.INSURANCE.solution4': 'Intuitive, easy-to-understand call reporting management',
  'cases.INSURANCE.pros1':
    'Quickly find potential customers, increase the conversion rate for insurance businesses',
  'cases.INSURANCE.pros2': 'Save cost and time for businesses\n',
  'cases.INSURANCE.pros3': 'Reduce workload, help businesses focus on important jobs',
  'cases.INSURANCE.pros4': 'Improve business efficiency for enterprises\n',
  'cases.EDUCATION.title':
    'CALLBOT AI AUTO CALL \n' + 'COST OPTIMIZATION \n' + 'TELESALE & EDUCATION SECTOR\n',
  'cases.EDUCATION.subtitle':
    'Callbot AI automatically calls to meet the needs of management, customer care and optimize the cost of converting potential customers in the Education industry',
  'cases.EDUCATION.problem1.title': 'Missing data',
  'cases.EDUCATION.problem1.subtitle':
    'Lack of data source at important times, making the center business limited',
  'cases.EDUCATION.problem2.title': 'Time consuming',
  'cases.EDUCATION.problem2.subtitle':
    'Education centers have to spend hours every day taking care of old students and searching for new students in the raw data file (about 65% of the data is not candidates in need).',
  'cases.EDUCATION.problem3.title': 'High cost',
  'cases.EDUCATION.problem3.subtitle':
    'It costs 50% of the cost of Telesales staff and student care, not to mention other operating costs.',
  'cases.EDUCATION.problem4.title': 'Missed opportunity',
  'cases.EDUCATION.problem4.subtitle':
    'The access to potential students is slow, which also reduces the business opportunities of the Education center. Not taking care of the students in time is also the reason why they move to other centers.',
  'cases.EDUCATION.solution1': 'Searching for customer data in need',
  'cases.EDUCATION.solution2':
    'Support to develop telesales scenarios suitable for Education customers',
  'cases.EDUCATION.solution3': 'Make 5000 calls to customers within 1 hour',
  'cases.EDUCATION.solution4': 'Intuitive, easy-to-understand call reporting management',
  'cases.EDUCATION.pros1':
    'Quickly find potential customers, increase conversion rate for Education centers',
  'cases.EDUCATION.pros2': 'Take care of students regularly, increase customer retention rate',
  'cases.EDUCATION.pros3': 'Reduce workload, help consultants focus on important work',
  'cases.EDUCATION.pros4':
    'Improve business efficiency for businesses. Save money and time for the center.',
  'cases.OTHER.title':
    'CALLBOT AI AUTO CALL \n' + 'COST OPTIMIZE \n' + 'TELESALE & Customer Service\n',
  'cases.OTHER.subtitle':
    'Callbot AI calls automatically to meet the needs of management, customer care and optimize the cost of converting potential customers for other fields such as Software, Healthcare, Tax Consulting, Recruiting Agents, Organizations event',
  'cases.OTHER.problem1.title': 'Thiếu data',
  'cases.OTHER.problem1.subtitle':
    'Lack of data source at important times, making business limited',
  'cases.OTHER.problem2.title': 'Time consuming',
  'cases.OTHER.problem2.subtitle':
    'Businesses have to spend hours every day taking care of old customers and finding new customers in the raw data file (about 65% of the data is not the candidates in need).',
  'cases.OTHER.problem3.title': 'Chi phí cao',
  'cases.OTHER.problem3.subtitle':
    'It costs 50% of the cost of Telesales staff and student care, not to mention other operating costs.',
  'cases.OTHER.problem4.title': 'Missed opportunity',
  'cases.OTHER.problem4.subtitle':
    'The approach to potential customers is slow, which also reduces the business opportunities of the Enterprise. Not taking care of customers in time is also the reason why they switch to other competitors.',
  'cases.OTHER.solution1': 'Search for data customers have needs',
  'cases.OTHER.solution2': 'Support to build telesales scenarios suitable for customers',
  'cases.OTHER.solution3': 'Make 5000 calls to customers within 1 hour',
  'cases.OTHER.solution4': 'Intuitive, easy-to-understand call reporting management',
  'cases.OTHER.pros1': 'Quickly find potential customers, increase conversion rates for businesses',
  'cases.OTHER.pros2': 'Take care of students regularly, increase customer retention rate',
  'cases.OTHER.pros3':
    'Save cost and time for the center. Improve business efficiency for the center',
  'cases.OTHER.pros4': 'Reduce workload, help consultants focus on important work',

  'callbot.features.feature1.image': 'giao-dien-nhap-khach-hang.png',
  'callbot.features.feature2.image': 'giao-dien-sua-kich-ban.png',
  'callbot.features.feature3.image': 'giao-dien-cai-dat-chien-dich.png',
  'callbot.features.feature4.image': 'giao-dien-bao-cao.png',

  'call_center.features.feature1.image': 'giao-dien-nhap-khach-hang.png',
  'call_center.features.feature2.image': 'giao-dien-sua-kich-ban.png',
  'call_center.features.feature3.image': 'giao-dien-cai-dat-chien-dich.png',
  'call_center.features.feature4.image': 'giao-dien-bao-cao.png',

  'uleads.features.feature1.image': 'giao-dien-nhap-khach-hang.png',
  'uleads.features.feature2.image': 'giao-dien-sua-kich-ban.png',
  'uleads.features.feature3.image': 'giao-dien-cai-dat-chien-dich.png',
  'uleads.features.feature4.image': 'giao-dien-bao-cao.png',
};
