import type { FunctionComponent, ReactNode } from 'react';
import { createContext, useContext, useEffect } from 'react';
import { Divider, message, Modal, notification, Spin } from 'antd';
import styles from '../../pages/user/login/index.less';
import Animation from './78062-email-tailwind.json';
import Lottie from 'lottie-react';
import Button from 'antd-button-color';
import axiosInstance, { cookies, getAccessToken } from '@/services/api/axiosInstance';
import { CookiesConstant } from '@/services/api/types';
import { logout } from '@/services/api/account/useSignIn';
import { routesPath } from '../../../config/routes';
import { useToken } from 'react-firebase-hooks/messaging';
import { firebaseApp, firebaseMessaging, VAPID_KEY } from '@/services/api/util';
import {
  channel,
  CONFIRMED_ACCOUNT_KEY,
  isLandingPage,
  LAST_SIGNED_IN_KEY,
  SAVED_SCENARIO_KEY,
} from '@/constants';
import { useLocalstorage } from 'rooks';
import { history } from 'umi';
import { onMessage } from 'firebase/messaging';
import useResendEmail from '@/services/api/account/useResendEmail';
import ConfirmationPage from '@/pages/confirmation';
import useRegisterDevice from '@/services/api/notification/useRegisterDevice';
import { useCallBotDetailPreview } from '@/services/api/call_bot_scenario/useCallBotDetail';
import useCreateCallBotScenario from '@/services/api/call_bot_scenario/useCreateCallBotScenario';
import useDisclosure from '@/hooks/useDisclosure';
import { format } from 'date-fns';
import useCompanyId from '@/hooks/useCompanyId';
import { FormattedMessage, getLocale, useIntl } from '@@/plugin-locale/localeExports';
import { initializeAnalytics } from '@firebase/analytics';
import { constants } from '@/pages/constants';
import useCreateCallBotScenarioCompany from '@/services/api/call_bot_scenario_company_sample/useCreateCallBotScenarioCompany';

interface ConfirmationAccountState {
  isLoggedIn: boolean;
}

const ConfirmationAccountContext = createContext<ConfirmationAccountState | undefined>(undefined);

interface ConfirmationAccountProviderProps {
  children: ReactNode;
}

const CheckingAccountState = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className="d-flex align-items-center justify-content-center">
          <Spin />
        </div>
      </div>
    </div>
  );
};
const AccountNotConfirmed = () => {
  const { mutateAsync, isLoading } = useResendEmail();
  const { formatMessage } = useIntl();
  const email = window.localStorage.getItem(LAST_SIGNED_IN_KEY);

  useEffect(() => {
    window.location.hash = 'success';
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className="d-flex align-items-center justify-content-center flex-column">
          <div style={{ maxWidth: '320px' }}>
            <Lottie animationData={Animation} loop autoplay />
          </div>
          <h4>{formatMessage({ id: 'xc_nhn_a_ch_email' })}</h4>
          <p style={{ maxWidth: '560px' }} className="text-center">
            <FormattedMessage
              id="chng_ti_gi_email_xc_nhn_n_hm_th_vui_lng_kim_tra_v"
              defaultMessage="Chúng tôi đã gửi email xác nhận đến hòm thư <b>{{email}} </b> , vui lòng kiểm tra và nhấn vào đường dẫn xác thực để tiếp tục."
              values={{
                email: email,
                b: (chunks) => <b>{chunks}</b>,
              }}
            />
          </p>
          <Button
            type="primary"
            with="dashed"
            onClick={async () => {
              if (!email) {
                return;
              }
              await mutateAsync({
                email: email,
              });
              message.success({ formatMessage: { id: 'ucall_sent_email' } });
            }}
            loading={isLoading}
          >
            {formatMessage({ id: 'gi_li_email' })}
          </Button>
          <Button
            onClick={() => {
              logout();
            }}
            type="danger"
            with="link"
            style={{ marginTop: '48px' }}
          >
            {formatMessage({ id: 'ng_xut' })}
          </Button>
        </div>
      </div>
    </div>
  );
};

const LoggedInWrapped: React.FunctionComponent = ({ children }) => {
  const [token, loading, error] = useToken(firebaseMessaging, VAPID_KEY);
  const { mutateAsync } = useRegisterDevice(token || '');

  useEffect(() => {
    if (!loading && !error && token) {
      mutateAsync().then();
      onMessage(firebaseMessaging, (payload) => {
        notification.open({
          message: payload?.notification?.title,
          description: payload?.notification?.body,
        });
      });
    }
  }, [loading, error, token]);

  return children;
};

export const ConfirmationAccountProvider: FunctionComponent<ConfirmationAccountProviderProps> = ({
  children,
}: ConfirmationAccountProviderProps) => {
  // const intl = useIntl();
  const { COMPANY_ID } = useCompanyId();
  const { open, isOpen, close } = useDisclosure();
  const access = getAccessToken();
  const refresh = cookies.get(CookiesConstant.REFRESH_TOKEN);
  const apiKey = cookies.get(CookiesConstant.API_KEY);
  const { value: confirmed, set } = useLocalstorage(CONFIRMED_ACCOUNT_KEY, true);
  const { value: savedScenario, remove: removeSavedScenario } = useLocalstorage(
    SAVED_SCENARIO_KEY,
    undefined,
  );
  const { formatMessage } = useIntl();
  const { TransposePermissionObjects } = constants();

  const { mutateAsync: createCallbotScenario, isLoading: isCreatingCallbot } =
    useCreateCallBotScenarioCompany();
  const savedScenarioParsed = JSON.parse(savedScenario);
  const { data: callBotDetail } = useCallBotDetailPreview(
    savedScenarioParsed?.COMPANY_ID,
    savedScenarioParsed?.CALL_BOT_SCENARIO_ID,
  );

  const isConfirmationScreen = window.location.pathname === routesPath.confirmation;
  const isLoggedIn =
    getAccessToken() !== undefined ||
    cookies.get(CookiesConstant.REFRESH_TOKEN) !== undefined ||
    cookies.get(CookiesConstant.API_KEY) !== undefined;

  useEffect(() => {
    if (!confirmed && isLoggedIn && window.location.pathname !== routesPath.onboard) {
      // window.location.href = routesPath.onboard;
    }
  }, [confirmed]);

  useEffect(() => {
    setTimeout(() => {
      if (isLandingPage) {
        window.setInitialState((preInitialState) => {
          return {
            ...preInitialState,
            settings: {
              ...preInitialState.settings,
              primaryColor: '#1E276C',
            },
          };
        });
      }
      if (firebaseApp) {
        initializeAnalytics(firebaseApp);
      }
    }, 1000);

    if (access || apiKey || refresh) {
      axiosInstance.interceptors.response.eject(0);
      axiosInstance.interceptors.response.use(
        function (response) {
          return response;
        },
        function (error) {
          const response = error.response;

          if (
            response?.data?.error_messages === 'Chưa có gói đăng ký nào với sản phẩm này' &&
            window.location.pathname.includes('/calls')
          ) {
            return;
          }

          if (response?.status === 403 && response?.data?.require_permissions) {
            notification.open({
              key: JSON.stringify(response?.data?.require_permissions),
              message: (
                <b style={{ color: 'red' }}>
                  🚨 Bạn không có quyền truy cập vào tài nguyên. Vui lòng kiểm tra các quyền sau
                </b>
              ),
              description: (
                <div>
                  {response?.data?.require_permissions?.map((permission) => {
                    const transposed = TransposePermissionObjects[permission];
                    return (
                      <>
                        <Divider style={{ margin: '12px 0' }} />
                        <div>
                          <h6>👉 {permission}</h6>
                          <div>
                            {transposed.group} {'>'} {transposed.permission} {'>'}{' '}
                            {transposed.privilege || '--'} {'>'} {permission}
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              ),
              duration: 20,
            });
          }
          if (response?.status === 403 && response?.data?.code === 'user_inactive') {
            set(false);
          }
          if (response?.status === 403 && response?.data?.detail === 'WALLET_EXPIRED') {
            message.error(formatMessage({ id: 'wallet_expired' }));
            history.push(routesPath.wallet(COMPANY_ID).payment);
            return true;
            // return Promise.reject(error);
          }
          if (
            response?.status === 401 ||
            response?.data?.code === 'token_not_valid' ||
            response?.data?.detail === 'Authentication credentials were not provided.'
          ) {
            if (!access && refresh) {
              history.push(routesPath.refresh);
              return Promise.reject(error);
            }
            logout();
          } else {
            console.error(error);
          }
          if (response?.status === 400 && response?.data?.error_messages) {
            message.error({
              content: response.data?.error_messages,
              duration: 6,
            });
            if (response?.data?.error_messages === 'Domain không hợp lệ') {
              return true;
            }
          }
          if (response?.status === 500) {
            const text = formatMessage({
              id: 'c_li_xy_ra_vui_lng_lin_h_k_thut_vin_nu_khng_th_tip',
            });
            message.error({
              key: text,
              content: text,
              duration: 6,
            });
          }

          return Promise.reject(error);
        },
      );
    }
  }, []);

  useEffect(() => {
    if (
      savedScenarioParsed &&
      savedScenarioParsed?.COMPANY_ID &&
      callBotDetail &&
      window.location.pathname !== routesPath.onboard &&
      COMPANY_ID
    ) {
      open();
    }
  }, [savedScenarioParsed, callBotDetail]);

  useEffect(() => {
    const handler = (msg) => {
      console.log('MSG', msg);
      if (msg.event === 'LOGOUT') {
        window.location.href = '/';
      }
      if (msg.event === 'LOGIN') {
        window.location.href = msg.url;
      }
    };
    channel.addEventListener('message', handler);

    return () => channel.removeEventListener('message', handler);
  }, []);

  if (location.pathname === `${routesPath.confirmation}/`) {
    // location.pathname = location.pathname.replace('/confirmation/', '/confirmation');
    return <ConfirmationPage />;
  }

  if (!isLoggedIn) {
    return (
      <ConfirmationAccountContext.Provider
        value={{
          isLoggedIn: false,
        }}
      >
        <div>{children}</div>
      </ConfirmationAccountContext.Provider>
    );
  }

  return (
    <ConfirmationAccountContext.Provider
      value={{
        isLoggedIn:
          isLoggedIn &&
          location.pathname !== routesPath.login &&
          location.pathname !== routesPath.register &&
          location.pathname !== routesPath.register &&
          location.pathname !== routesPath.confirmation &&
          !location.pathname?.includes(routesPath.preview),
      }}
    >
      {(confirmed || isConfirmationScreen) && (
        <LoggedInWrapped>
          {children}
          <Modal
            title={formatMessage({ id: 'save_script' })}
            visible={isOpen}
            onOk={async () => {
              await createCallbotScenario({
                title: ` ${callBotDetail?.data?.title}`,
                content: callBotDetail?.data?.content,
              });
              message.success(formatMessage({ id: 'script_saved' }));
              removeSavedScenario();
              window.location.reload();
            }}
            confirmLoading={isCreatingCallbot}
            onCancel={() => {
              close();
              removeSavedScenario();
            }}
            okText={formatMessage({ id: 'agree' })}
            cancelText={formatMessage({ id: 'close' })}
          >
            <p>
              {formatMessage({ id: 'tap_agree_to_save' })} <b>{callBotDetail?.data?.title}</b>{' '}
              {formatMessage({ id: 'to_your_account' })}
            </p>
          </Modal>
        </LoggedInWrapped>
      )}
      {!confirmed && <AccountNotConfirmed />}
    </ConfirmationAccountContext.Provider>
  );
};
export function useConfirmationAccount(): ConfirmationAccountState {
  const context = useContext<any>(ConfirmationAccountContext);

  if (!context) {
  }
  return context;
}
