import { isUcallURL } from '@/constants';
export const MenuItemCompanySocial = () => {
  if (isUcallURL || window?.location?.hostname?.includes('localhost')) {
    return (
      <div>
        <div
          style={{
            width: '100%',
            paddingTop: '10px',
            paddingLeft: '10px',
            paddingBottom: '10px',
            height: 'auto',
            maxWidth: '100%',
            alignItems: 'center',
          }}
          className="d-flex gap-3 align-items-center footer_logo"
        >
          <div>
            <a target='_blank' href="https://www.facebook.com/ucall.vn">
              <img style={{ borderRadius: '80px' }} width={32} src={'/fb.png'} alt="facebook" />
            </a>
          </div>
          <div>
            <a target='_blank' href="https://bit.ly/ucalltiktok">
              <img style={{ borderRadius: '80px' }} width={32} src={'/tiktok.png'} alt="tiktok" />
            </a>
          </div>
          <div>
            <a target='_blank' href="https://www.youtube.com/@ucall.autocall?sub_confirmation=1">
              <img style={{ borderRadius: '80px' }} width={32} src={'/youtobe.png'} alt="youtube" />
            </a>
          </div>
          <div>
            <a target='_blank' href="https://bit.ly/3pSuo5m">
              <img style={{ borderRadius: '80px' }} width={32} src={'/in.png'} alt="linkedin" />
            </a>
          </div>
        </div>
      </div>
    );
  }
  return <></>;
};
