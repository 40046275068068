import { useQuery } from 'react-query';
import axiosInstance from '@/services/api/axiosInstance';
import type { ServerFormError } from '@/services/api/types';
import type { IProfile } from '@/services/api/types';
import type { AxiosResponse } from 'axios';

export const useMyProfileKey = 'current-profile';

export function useMyProfileWithParams({ enabled }: any) {
  return useQuery<AxiosResponse<IProfile>, ServerFormError>(
    [useMyProfileKey],
    () => {
      return axiosInstance.get('/profile/me');
    },
    {
      enabled: enabled,
      retry: false,
    },
  );
}

function useMyProfile() {
  return useQuery<AxiosResponse<IProfile>, ServerFormError>(
    [useMyProfileKey],
    () => {
      return axiosInstance.get('/profile/me');
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchInterval: false,
      retry: false,
      onSuccess: (data) => {
        window.setInitialState((preInitialState) => {
          return {
            ...preInitialState,
            profile: data?.data,
          };
        });
      },
    },
  );
}

export default useMyProfile;
