import React, { useRef } from 'react';
import Button from 'antd-button-color';
import useDisclosure from '@/hooks/useDisclosure';
import { Alert, Card, Col, DatePicker, Form, Input, List, Modal, Row, Select, Spin } from 'antd';
import useListCallsByCustomer from '@/services/api/customer/useListCallsByCustomer';
import { Collapse, Space } from 'antd';
import useListCallCampaignCustomersByCustomer from '@/services/api/customer/useListCallCampaignCustomersByCustomer';
import { formatDateFromString } from '@/services/api/util';
import './DetailCustomerButton.less';
import PhoneRecordButton from '@/pages/campaigns/calls/[CAMPAIGN_CALL_ID]/components/Step 4/components/PhoneRecordButton';
import { MessageBox } from 'react-chat-elements';
import useCustomerNotes from '@/services/api/customer_note/useCustomerNotes';
import useCreateCustomerNote from '@/services/api/customer_note/useCreateCustomerNote';
import CustomerNoteAlert from '@/pages/customers/components/CustomerNoteAlert';
import { useIntl } from '@@/plugin-locale/localeExports';
import { SaveOutlined } from '@ant-design/icons';
import useCustomer from '@/services/api/customer/useCustomer';
import { transform } from 'lodash';
import FieldCustomerEditable from '@/pages/customers/components/FieldCustomerEditable';
import useCustomerLabels, {
  useCompanyCustomerLabels,
} from '@/services/api/customer_labels/useCustomerLabels';
import { usePermission } from '@/services/api/permission/usePermissions';

const { Panel } = Collapse;

const WrappedSelect = (props) => {
  const ref = useRef();
  return (
    <Select
      {...props}
      ref={ref}
      onChange={(_prop) => {
        props.onChange(_prop);
        ref.current.blur();
      }}
    />
  );
};

const DetailCustomerButton = ({ customerID, buttonOverride, isMaskingNumber = false }) => {
  const [form] = Form.useForm();
  const { data, isRefetching: isLoadingCustomer } = useCustomer(customerID);
  const change_customer = usePermission('change_customer');
  const change_customer_own = usePermission('change_customer_own');
  const change_customer_group = usePermission('change_customer_group');
  const { formatMessage } = useIntl();
  const { data: customerLabels, isLoading: isLoadingCustomerLabel } = useCompanyCustomerLabels();
  const { isOpen, open, close } = useDisclosure(
    // customerID === '92b65120-a0e9-4e14-a2c6-8ca814af3ad3',
    false,
  );
  const {
    data: listNotes,
    isLoading: isLoadingNotes,
    refetch: refetchCustomerNote,
  } = useCustomerNotes(customerID);
  const { mutateAsync: createCustomerNote, isLoading: isCreatingCustomerNote } =
    useCreateCustomerNote(customerID);
  const { data: listCalls, isLoading: isLoadingCalls } = useListCallsByCustomer(customerID, isOpen);
  const { data: listCallCampaignCustomers, isLoadingCCC } = useListCallCampaignCustomersByCustomer(
    customerID,
    isOpen,
  );
  const customer = data?.data;

  const isLoading = isLoadingCalls || isLoadingCCC || isLoadingNotes || isLoadingCustomer;

  const onFinish = async (values) => {
    await createCustomerNote({
      message: values?.message,
      // remind_at: values?.remind_at?.toISOString(),
      labels: values?.labels,
    });
    await refetchCustomerNote();
    form.resetFields();
  };

  const customerAttributes = {
    phone_number: {
      value: isMaskingNumber ? '*********' : customer?.phone_number,
      editable: false,
    },
    email: {
      value: customer?.email,
      editable: true,
    },
    name: {
      value: customer?.name,
      editable: true,
    },
    updated_at: {
      value: formatDateFromString(customer?.updated_at),
      editable: false,
    },
    ...transform(customer?.other_attributes, function (result, val, key) {
      result[key] = {
        value: val,
        editable: true,
        isOtherAttributes: true,
      };
    }),
  };

  return (
    <div>
      {buttonOverride && (
        <div
          onClick={() => {
            open();
          }}
        >
          {buttonOverride}
        </div>
      )}
      {!buttonOverride && (
        <Button
          onClick={open}
          type="success"
          danger
          key="link"
          disabled={!(change_customer || change_customer_group || change_customer_own)}
        >
          {formatMessage({ id: 'chi_tit' })}
        </Button>
      )}
      <Modal
        title={formatMessage({ id: 'thng_tin_khch_hng' })}
        centered
        visible={isOpen}
        onOk={close}
        onCancel={close}
        width="100%"
        footer={<></>}
        bodyStyle={{
          backgroundColor: '#ececec',
        }}
        wrapClassName="detail-customer"
      >
        {isLoading && (
          <div className="w-100 d-flex flex-row align-items-center justify-content-center">
            <Spin />
          </div>
        )}
        <Row gutter={12}>
          <Col span={12}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Card title={`${formatMessage({ id: 'information' })}`}>
                <List
                  size="small"
                  dataSource={Object.keys(customerAttributes)}
                  renderItem={(item) => {
                    const field = customerAttributes?.[item];
                    return (
                      <List.Item style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <FieldCustomerEditable
                          customerID={customerID}
                          fieldName={item}
                          fieldValue={field?.value}
                          editable={field?.editable}
                          isOtherAttributes={field?.isOtherAttributes}
                        />
                      </List.Item>
                    );
                  }}
                />
              </Card>
              {/*{listCallCampaignCustomers?.data?.results?.map((e, index) => (*/}
              {/*  <Card title={`${formatMessage({ id: 'information' })} ${index + 1}`}>*/}
              {/*    <List*/}
              {/*      size="small"*/}
              {/*      style={{ padding: 0 }}*/}
              {/*      dataSource={Object.keys(e?.other_attributes)}*/}
              {/*      renderItem={(item) => {*/}
              {/*        return (*/}
              {/*          <List.Item>*/}
              {/*            <Row style={{ width: '100%' }} align="center" justify="space-between">*/}
              {/*              <div>*/}
              {/*                <b>{item?.toUpperCase()}</b>*/}
              {/*              </div>*/}
              {/*              <div>{e?.other_attributes?.[item]}</div>*/}
              {/*            </Row>*/}
              {/*          </List.Item>*/}
              {/*        );*/}
              {/*      }}*/}
              {/*    />*/}
              {/*  </Card>*/}
              {/*))}*/}
            </Space>
          </Col>
          <Col span={12} style={{ paddingTop: '12px' }}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Collapse collapsible="header">
                <Panel header={formatMessage({ id: 'thm_ghi_ch_mi' })} key="1">
                  <Form form={form} layout="vertical" onFinish={onFinish} autoComplete="off">
                    <Form.Item
                      name="labels"
                      label={formatMessage({ id: 'chn_nhn_khch_hng' })}
                      rules={[{ required: true, message: formatMessage({ id: 'vui_lng_nhp' }) }]}
                    >
                      <WrappedSelect
                        loading={isLoadingCustomerLabel}
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        onChange={(values) => {
                          form.setFieldsValue({
                            labels: values,
                          });
                        }}
                      >
                        {customerLabels?.data?.map((label) => {
                          return <Option value={label?.id}>{label?.text}</Option>;
                        })}
                      </WrappedSelect>
                    </Form.Item>
                    <Form.Item
                      name="message"
                      label={formatMessage({ id: 'ghi_ch' })}
                      rules={[{ required: true, message: formatMessage({ id: 'vui_lng_nhp' }) }]}
                    >
                      <Input.TextArea
                        placeholder={formatMessage({ id: 'khch_hn_lin_h_vo_th_3_9_gi' })}
                        rows={4}
                      />
                    </Form.Item>
                    {/*<Form.Item*/}
                    {/*  name="remind_at"*/}
                    {/*  label={formatMessage({ id: 'nhc_ti_vo_lc2' })}*/}
                    {/*  rules={[{ required: true, message: formatMessage({ id: 'field_required' }) }]}*/}
                    {/*>*/}
                    {/*  <DatePicker style={{ width: '100%' }} showTime format="DD-MM-YYYY HH:mm:ss" />*/}
                    {/*</Form.Item>*/}

                    <Button
                      type="primary"
                      loading={isCreatingCustomerNote || isLoadingNotes}
                      htmlType="submit"
                      block
                      style={{ marginTop: '12px' }}
                    >
                      <SaveOutlined />
                      {formatMessage({ id: 'lu' })}
                    </Button>
                  </Form>
                </Panel>
              </Collapse>
              {listNotes?.data?.results?.map((e) => {
                return <CustomerNoteAlert CUSTOMER_ID={customerID} customerNote={e} />;
              })}
              {listCalls?.data?.results?.map((e, index) => {
                return (
                  <Card
                    title={
                      <div className="d-flex flex-row align-items-center justify-content-between">
                        <div className="d-flex flex-column">
                          <div>
                            {`${formatMessage({ id: 'call' })} ${index + 1} `}
                            {`(${Math.round(e?.duration)} ${formatMessage({ id: 'second' })})`}
                          </div>
                          <div className="text-muted">{formatDateFromString(e?.created_at)}</div>
                        </div>
                        <div>
                          {e?.other_attributes?.full_record && (
                            <PhoneRecordButton url={e?.other_attributes?.full_record} />
                          )}
                        </div>
                      </div>
                    }
                    bodyStyle={{
                      backgroundColor: '#708090',
                      boxShadow: 'inset 2px 2px 19px 3px rgba(0,0,0,0.37)',
                    }}
                  >
                    <div className="d-flex flex-column">
                      {e?.other_attributes?.log_chat?.map((chat) => {
                        const position = chat.user === 'Bot' ? 'left' : 'right';
                        const title = chat.user === 'Bot' ? 'Bot' : 'Người';
                        return (
                          <>
                            <MessageBox
                              position={position}
                              title={title}
                              type="text"
                              text={chat?.message}
                              date={new Date(chat?.timestamp)}
                            />
                            <MessageBox
                              reply={{
                                title: title,
                                titleColor: '#8717ae',
                                message: chat?.message,
                              }}
                              position={position}
                              type={'audio'}
                              title={formatMessage({ id: 'recording' })}
                              data={{
                                audioURL: chat?.audio_url,
                              }}
                            />
                          </>
                        );
                      })}
                      {/*<Row style={{ width: '100%' }} align="center" justify="space-between">*/}
                      {/*  <div>{item?.toUpperCase()}</div>*/}
                      {/*  <div>{JSON.stringify(e?.other_attributes?.[item])}</div>*/}
                      {/*</Row>*/}
                    </div>
                  </Card>
                );
              })}
            </Space>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default DetailCustomerButton;
