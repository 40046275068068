import { useMutation, useQueryClient } from 'react-query';
import type { DefaultServerError, ICampaign } from '@/services/api/types';
import axiosInstance from '@/services/api/axiosInstance';
import type { AxiosResponse } from 'axios';
import useCompanyId from '@/hooks/useCompanyId';
import { ICall } from '@/services/api/types';

function useCreateManualCall() {
  const { apiRoutes } = useCompanyId();

  return useMutation<AxiosResponse<ICall>, DefaultServerError>(
    (input) => {
      return axiosInstance.post(apiRoutes.call.list, {
        ...input,
      });
    }
  );
}

export default useCreateManualCall;
