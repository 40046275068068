import useMyAgent from '@/services/api/agent/useMyAgent';
import useMyProfile from '@/services/api/account/useMyProfile';
import { iframe_id } from '@/components/PhoneBridgeProvider';
import React, { useEffect, useMemo } from 'react';
import useCompanyId from '@/hooks/useCompanyId';
import { Card, message } from 'antd';
// import { isNotHackViaTcpPartner } from '@/constants';

const PhoneBridgeIframe = () => {
  const { COMPANY_ID } = useCompanyId();
  const { data: myAgent, isRefetching: isLoadingMyAgent } = useMyAgent();
  const { data: myProfile, isRefetching: isLoadingMyProfile } = useMyProfile();
  const isLoading = isLoadingMyAgent || isLoadingMyProfile;

  // const wss_server = useMemo(() => {
  //   if (isNotHackViaTcpPartner) {
  //     return myAgent?.data?.ext_domain + ':7443';
  //   }

  //   if (myAgent?.data?.wss_server && myAgent?.data?.wss_server !== '') {
  //     return myAgent?.data?.wss_server;
  //   }
  //   return 'wssvietnam2.callbot.io.vn';
  // }, [myAgent?.data]);

  const remappedMyAgent = {
    SipDomain: myAgent?.data?.ext_domain,
    SipUsername: myAgent?.data?.ext_username,
    SipPassword: myAgent?.data?.ext_password,
    wssServer: myAgent?.data?.wss_server,
    profileName: myProfile?.data?.email,
  };

  const params = new URLSearchParams({
    ...remappedMyAgent,
    WebSocketPort: 7443,
    IceStunServerJson: '[{ "urls": ["stun:stun-vn-01.phonenet.io"] }]',
    WelcomeScreenAccept: 'yes',
    // hackViaTcp: !isNotHackViaTcpPartner,
  }).toString();

  useEffect(() => {
    if (!COMPANY_ID || !myAgent?.data) {
      return;
    }
    window.error = message.error;
    return () => {
      delete window.error;
    };
  }, [COMPANY_ID, myAgent?.data]);

  if (!COMPANY_ID || !myAgent?.data) {
    return <></>;
  }

  return (
    // <Card>
    <iframe
      id={iframe_id}
      src={`${
        process.env.NODE_ENV === 'development' ? `http://localhost:3000/index.html` : `/phone`
      }?${params}`}
      width="100%"
      height={1000}
      allow="camera *; microphone *"
      style={process.env.NODE_ENV === 'production' ? { display: 'none' } : {}}
    />
    // </Card>
  );
};

export default React.memo(PhoneBridgeIframe);
