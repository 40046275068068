import { useQuery } from 'react-query';
import axiosInstance from '@/services/api/axiosInstance';
import type { IPaginate, ServerFormError } from '@/services/api/types';
import type { AxiosResponse } from 'axios';
import useCompanyId from '@/hooks/useCompanyId';
import type { INotification } from '@/services/api/types';
export const useNotificationsKey = 'notifications';

interface useNotificationsProps {
  page_size?: number;
  page?: number;
}

function useNotifications({ page_size = 50, page = 1 }: useNotificationsProps) {
  const { apiRoutes } = useCompanyId();
  return useQuery<AxiosResponse<IPaginate<INotification>>, ServerFormError>(
    [useNotificationsKey, page_size, page],
    () => {
      return axiosInstance.get(apiRoutes.notifications.list, {
        params: { size: page_size, page, ordering: '-created_at' },
      });
    },
  );
}

export default useNotifications;
