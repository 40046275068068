import { useQuery } from 'react-query';
import axiosInstance from '@/services/api/axiosInstance';
import type { ServerFormError } from '@/services/api/types';
import type { AxiosResponse } from 'axios';
import useCompanyId from '@/hooks/useCompanyId';
import type { ICustomer } from '@/services/api/types';

export const useCustomerKey = 'customer';

function useCustomer(CUSTOMER_ID: string) {
  const { COMPANY_ID, apiRoutes } = useCompanyId();

  return useQuery<AxiosResponse<ICustomer>, ServerFormError>(
    [COMPANY_ID, useCustomerKey, CUSTOMER_ID],
    () => {
      return axiosInstance.get(apiRoutes.customer.detail(CUSTOMER_ID));
    },
    {
      enabled: CUSTOMER_ID !== undefined && CUSTOMER_ID !== null,
    },
  );
}

export default useCustomer;
