import useListCompany from '@/services/api/company/useListCompany';
import { Button, Card, Row, Spin } from 'antd';
import { BankOutlined } from '@ant-design/icons';
import { routesPath } from '../../config/routes';
import { useEffect } from 'react';
import { history } from 'umi';
import { logout } from '@/services/api/account/useSignIn';
import { useIntl } from '@@/plugin-locale/localeExports';

export const REMEMBER_COMPANY_KEY = 'company';

const OnboardPage = () => {
  const { isLoading, data } = useListCompany();
  const { formatMessage } = useIntl();

  useEffect(() => {
    const prevCompany = window.localStorage.getItem(REMEMBER_COMPANY_KEY);
    if (prevCompany && prevCompany !== '') {
      history.replace(routesPath.company(prevCompany));
    }
  }, []);

  return (
    <div style={{ backgroundColor: '#f0f2f5' }}>
      <div className="container align-items-center justify-content-center w-100">
        <BankOutlined size={128} style={{ paddingRight: '4px', fontSize: '128px' }} />
        <h2 style={{ opacity: 0.5 }}>{formatMessage({ id: 'workspace_ca_bn' })}</h2>
        <Row className="align-items-center justify-content-center">
          {isLoading && <Spin />}
          {!isLoading &&
            data?.data.map((company) => {
              return (
                <Card
                  headStyle={{ paddingBottom: '0px' }}
                  title={
                    <>
                      <div style={{ fontSize: '12px' }}>{formatMessage({ id: 'cnt_ty' })}</div>
                      <h3>{company.name}</h3>
                    </>
                  }
                  bordered
                  style={{ marginRight: '16px', marginBottom: '16px', minWidth: '280px' }}
                >
                  <div
                    onClick={() => {
                      window.localStorage.setItem(REMEMBER_COMPANY_KEY, company.slug);
                      history.push(routesPath.company(company.slug));
                    }}
                  >
                    <Button type="primary" style={{ width: '100%' }}>
                      {formatMessage({ id: 'access' })}
                    </Button>
                  </div>
                </Card>
              );
            })}
        </Row>
        <Button
          onClick={() => {
            logout();
          }}
          type="danger"
          with="link"
          style={{ marginTop: '48px' }}
        >
          {formatMessage({ id: 'ng_xut' })}
        </Button>
      </div>
    </div>
  );
};

export default OnboardPage;
