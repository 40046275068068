import { useMutation } from 'react-query';
import type { ServerFormError } from '@/services/api/types';
import { CookiesConstant } from '@/services/api/types';
import axiosInstance, { removeCookie, setCookie } from '@/services/api/axiosInstance';
import { history } from '@@/core/history';
import type { AxiosResponse } from 'axios';
import { routesPath } from '../../../../config/routes';
import { message } from 'antd';
import { queryClient } from '@/app';
import { channel, LAST_SIGNED_IN_KEY } from '@/constants';

export interface SignInParams {
  username: string;
  password: string;
}

export interface SignInResponse {
  refresh: string;
  access: string;
}

export const logout = async () => {
  const text = 'Vui lòng đăng nhập';
  message.error({ content: text, key: text });
  removeCookie(CookiesConstant.REFRESH_TOKEN);
  removeCookie(CookiesConstant.ACCESS_TOKEN);
  removeCookie(CookiesConstant.API_KEY);
  window.localStorage.clear();
  window.sessionStorage.clear();
  history.push(routesPath.login);
  channel.postMessage({
    event: 'LOGOUT',
  });
};

function useSignIn() {
  return useMutation<AxiosResponse<SignInResponse>, ServerFormError, SignInParams>(
    (formData) => {
      return axiosInstance.post('/signin', {
        ...formData,
      });
    },
    {
      onSuccess: (response, variables) => {
        setCookie(CookiesConstant.ACCESS_TOKEN, response.data.access);
        setCookie(CookiesConstant.REFRESH_TOKEN, response.data.refresh);
        if (!history) return;
        const { query } = history.location;
        const { redirect } = query as { redirect: string };
        window.localStorage.setItem(LAST_SIGNED_IN_KEY, variables.username);
        channel
          .postMessage({
            event: 'LOGIN',
            url: redirect || routesPath.onboard,
          })
          .then(() => {
            window.location.href = redirect || routesPath.onboard;
          })
          .catch(() => {
            window.location.href = redirect || routesPath.onboard;
          });
      },
    },
  );
}

export default useSignIn;
