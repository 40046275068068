import { useQuery } from 'react-query';
import axiosInstance from '@/services/api/axiosInstance';
import type { ServerFormError } from '@/services/api/types';
import type { AxiosResponse } from 'axios';
import type { ICompany } from '@/services/api/types';
import { useListCompanyKey } from '@/services/api/company/useListCompany';
import useCompanyId from '@/hooks/useCompanyId';
import { message } from 'antd';
import { routesPath } from '../../../../config/routes';
import { REMEMBER_COMPANY_KEY } from '@/pages/onboard';
import { history } from '@@/core/history';

function useCurrentCompany() {
  const { COMPANY_ID, apiRoutes } = useCompanyId();

  return useQuery<AxiosResponse<ICompany>, ServerFormError>(
    [useListCompanyKey, COMPANY_ID],
    () => {
      return axiosInstance.get(apiRoutes.company.current_company);
    },
    {
      enabled: COMPANY_ID !== undefined,
      retry: 1,
      onError: (err) => {
        if (err?.response?.status === 404) {
          message.error('Không tìm thấy workspace');
          window.localStorage.removeItem(REMEMBER_COMPANY_KEY);
          history.push(routesPath.home);
        }
      },
    },
  );
}

export default useCurrentCompany;
