import { useQuery } from 'react-query';
import axiosInstance from '@/services/api/axiosInstance';
import type { IPaginate, ServerFormError } from '@/services/api/types';
import type { AxiosResponse } from 'axios';
import useCompanyId from '@/hooks/useCompanyId';
import type { ICustomer } from '@/services/api/types';
import { useCustomersKey } from '@/services/api/customer/useCustomers';

function useListCallsByCustomer(CUSTOMER_ID: string, enabled = false) {
  const { COMPANY_ID, apiRoutes } = useCompanyId();

  return useQuery<AxiosResponse<IPaginate<ICustomer>>, ServerFormError>(
    [COMPANY_ID, useCustomersKey, CUSTOMER_ID, 'calls'],
    () => {
      return axiosInstance.get(apiRoutes.customer.calls(CUSTOMER_ID), {
        params: {
          size: 10000,
          page: 1,
        },
      });
    },
    {
      enabled: enabled,
    },
  );
}

export default useListCallsByCustomer;
