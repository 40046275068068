import { BroadcastChannel } from 'broadcast-channel';

export const DEMO_XLSX = '/Example.xlsx';
export const RECORD_PREFIX = 'ucall-data.s3';
export const LAST_SIGNED_IN_KEY = 'last_signed_in_email';
export const CONFIRMED_ACCOUNT_KEY = 'confirmed_account';
export const OVERRIDE_ENV_KEY = 'override_env';
export const SAVED_SCENARIO_KEY = 'saved_scenario_key';
export const PREFER_LANGUAGE_LANDING_PAGE = 'prefer_language_lp';
export const DISPLAYED_POPUP = 'displayed_popup';

export const ENABLED_IFRAME_INTERVAL_SYNC = !window?.location?.hostname?.includes('localhost');

export const isLandingPage =
  window?.location?.href?.includes('https://ucall.vn') ||
  window?.location?.href?.includes('https://www.ucall.vn') ||
  window?.location?.href?.includes('https://ucall.asia') ||
  window?.location?.href?.includes('https://www.ucall.asia') ||
  window?.location?.href?.includes('https://new.ucall.vn') ||
  window?.location?.href?.includes('https://dev.new.ucall.vn') ||
  window?.location?.href?.includes('http://ucall.vn') ||
  window?.location?.href?.includes('http://www.ucall.vn') ||
  window?.location?.href?.includes('http://new.ucall.vn') ||
  window?.location?.href?.includes('http://dev.new.ucall.vn') ||
  window?.location?.href?.includes('https://dev.ucall.vn') ||
  window?.location?.href?.includes('https://stag.ucall.vn') ||
  window?.location?.href?.includes('http://dev.ucall.vn') ||
  window?.location?.href?.includes('http://stag.ucall.vn') ||
  window?.location?.href?.includes('http://integ.new.ucall.vn:8000/');

export const isUcallAsia =
  window?.location?.href?.includes('https://ucall.asia') ||
  window?.location?.href?.includes('https://www.ucall.asia');

export const isUcallURL =
  window?.location?.href?.includes('growth.ucall.vn') ||
  window?.location?.href?.includes('stag.growth.ucall.vn') ||
  window?.location?.href?.includes('dev.growth.ucall.vn');

export const isNotHackViaTcpPartner =
  window?.location?.href?.includes('https://callbot.ttsolutions.com.vn') ||
  window?.location?.hostname?.includes('localhost');

export const isExclusivePartner1 = window?.location?.href?.includes(
  'https://zone1.growth.ucall.vn',
);
export const isExclusivePartner2 = window?.location?.href?.includes(
  'https://zone2.growth.ucall.vn',
);
export const isExclusivePartner3 = window?.location?.href?.includes(
  'https://callbot.ttsolutions.com.vn',
);
export const isExclusivePartner4 = window?.location?.href?.includes(
  'https://mascomgrowth.ucall.asia',
);

export const isDisplayUleads = !window?.location?.href?.includes('ttsolutions.com.vn');

export const maybeUcallPartner = window?.location?.href?.includes('ucall.vn');

export const envs =
  window?.location?.hostname?.includes('.stag') ||
  window?.location?.hostname?.includes('stag.') ||
  window?.location?.hostname?.includes('.dev') ||
  window?.location?.hostname?.includes('localhost') ||
  window?.location?.hostname?.includes('dev.') ||
  window?.location?.hostname?.includes('integ.')
    ? window?.location?.hostname?.includes('.stag') || window?.location?.hostname?.includes('stag.')
      ? {
          IS_DEV: true,
          IS_STAG: true,
          API_URL: 'https://stag.admin.ucall.vn/api',
          APP_REGISTER_URL: 'https://stag.growth.ucall.vn/user/register',
          APP_URL: 'https://stag.growth.ucall.vn/',
          LANDING_PAGE_URL: 'https://stag.ucall.vn/',
          RECAPTCHA_KEY: '6LdebFYdAAAAAKwFmzUMUx8EUVLZi_cqEA01-Db9',
        }
      : {
          IS_DEV: true,
          API_URL: 'https://dev.admin.ucall.vn/api',
          APP_REGISTER_URL: 'https://dev.growth.ucall.vn/user/register',
          APP_URL: 'https://dev.growth.ucall.vn/',
          LANDING_PAGE_URL: 'https://dev.ucall.vn/',
          RECAPTCHA_KEY: '6LdebFYdAAAAAKwFmzUMUx8EUVLZi_cqEA01-Db9',
        }
    : isExclusivePartner1
    ? {
        IS_DEV: false,
        API_URL: 'https://zone1.admin.ucall.vn/api',
        APP_REGISTER_URL: 'https://zone1.growth.ucall.vn/user/register',
        APP_URL: 'https://zone1.growth.growth.ucall.vn/',
        LANDING_PAGE_URL: 'https://ucall.vn/',
        RECAPTCHA_KEY: '6LdebFYdAAAAAKwFmzUMUx8EUVLZi_cqEA01-Db9',
      }
    : isExclusivePartner2
    ? {
        IS_DEV: false,
        API_URL: 'https://zone2.admin.ucall.vn/api',
        APP_REGISTER_URL: 'https://zone2.growth.ucall.vn/user/register',
        APP_URL: 'https://zone2.growth.growth.ucall.vn/',
        LANDING_PAGE_URL: 'https://ucall.vn/',
        RECAPTCHA_KEY: '6LdebFYdAAAAAKwFmzUMUx8EUVLZi_cqEA01-Db9',
      }
    : isExclusivePartner3
    ? {
        IS_DEV: false,
        API_URL: 'https://adminttsolution.callbot.io.vn/api',
        APP_REGISTER_URL: 'https://zone3.growth.ucall.vn/user/register',
        APP_URL: 'https://zone3.growth.growth.ucall.vn/',
        LANDING_PAGE_URL: 'https://ucall.vn/',
        RECAPTCHA_KEY: '6LdebFYdAAAAAKwFmzUMUx8EUVLZi_cqEA01-Db9',
      }
    : isExclusivePartner4
    ? {
        IS_DEV: false,
        API_URL: 'https://mascomadmin.ucall.asia/api',
        APP_REGISTER_URL: 'https://mascomgrowth.ucall.asia/user/register',
        APP_URL: 'https://mascomgrowth.ucall.asia/',
        LANDING_PAGE_URL: 'https://mascomgrowth.ucall.asia',
        RECAPTCHA_KEY: '6LdebFYdAAAAAKwFmzUMUx8EUVLZi_cqEA01-Db9',
      }
    : {
        IS_DEV: false,
        API_URL: 'https://admin.ucall.vn/api',
        APP_REGISTER_URL: 'https://growth.ucall.vn/user/register',
        APP_URL: 'https://growth.ucall.vn/',
        LANDING_PAGE_URL: 'https://ucall.vn/',
        RECAPTCHA_KEY: '6LdebFYdAAAAAKwFmzUMUx8EUVLZi_cqEA01-Db9',
      };

export enum EVENTS_KEYS {
  'OPEN_APP' = 'OPEN_APP',
  'CALL_DEMO_BUTTON' = 'CALL_DEMO_BUTTON',
  'SUBMIT_CONTACT_FORM' = 'SUBMIT_CONTACT_FORM',
  'HEADER' = 'HEADER',
  'EXPLORE_DATA_SOLUTION' = 'EXPLORE_DATA_SOLUTION',
  'EXPLORE_CALL_SOLUTION' = 'EXPLORE_CALL_SOLUTION',
  'EXPLORE_CASES' = 'EXPLORE_CASES',
  'OPEN_REGISTRATION' = 'OPEN_REGISTRATION',
  'SIGN_UP' = 'SIGN_UP',
}

export const cacheProvider = {
  get: (language, key) =>
    ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[language],
  set: (language, key, value) => {
    const existing = JSON.parse(localStorage.getItem('translations')) || {
      [key]: {},
    };
    existing[key] = { ...existing[key], [language]: value };
    localStorage.setItem('translations', JSON.stringify(existing));
  },
};

export const channel = new BroadcastChannel('app');
