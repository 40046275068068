import useCompanyId from '@/hooks/useCompanyId';
import { useMutation } from 'react-query';
import type { AxiosResponse } from 'axios';
import type { DefaultServerError, ICampaign } from '@/services/api/types';
import axiosInstance from '@/services/api/axiosInstance';

function useUpdateCustomerNote(CUSTOMER_ID: string, CUSTOMER_NOTE_ID: string) {
  const { apiRoutes } = useCompanyId();

  return useMutation<AxiosResponse<ICampaign>, DefaultServerError>((input) => {
    return axiosInstance.put(apiRoutes.customer_note(CUSTOMER_ID).detail(CUSTOMER_NOTE_ID), {
      ...input,
    });
  });
}

export default useUpdateCustomerNote;
