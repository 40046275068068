import { useQuery } from 'react-query';
import axiosInstance from '@/services/api/axiosInstance';
import type { IPaginate, ServerFormError } from '@/services/api/types';
import type { AxiosResponse } from 'axios';
import useCompanyId from '@/hooks/useCompanyId';
import type { ICustomer } from '@/services/api/types';

export const useCustomersKey = 'customers';

interface useCustomersProps {
  size?: number;
  page?: number;
  customer_group__in?: string;
  filters: any;
}

function useCustomers({ size, page, customer_group__in, filters }: useCustomersProps) {
  const { COMPANY_ID, apiRoutes } = useCompanyId();

  return useQuery<AxiosResponse<IPaginate<ICustomer>>, ServerFormError>(
    [COMPANY_ID, useCustomersKey, customer_group__in, filters, size, page],
    () => {
      return axiosInstance.get(apiRoutes.customer.list, {
        params: { size, page, customer_group__in, ...filters },
      });
    },
  );
}

export default useCustomers;
