import React, { useContext, useEffect, useState } from 'react';
import useDisclosure from '@/hooks/useDisclosure';
import parsePhoneNumber from 'libphonenumber-js';
import { Alert, message, notification, Row, Space } from 'antd';
import Button from 'antd-button-color';
import useInterval from 'use-interval';
import LineCall from './components/LineCall';
import { UnmountClosed } from 'react-collapse';
import Sticky from 'react-stickynode';
import useMyAgent from '@/services/api/agent/useMyAgent';
import useUpdateMyAgent from '@/services/api/agent/useUpdateMyAgent';
import { useHistory, useIntl } from 'umi';
import { ENABLED_IFRAME_INTERVAL_SYNC } from '@/constants';
import { debounce } from 'lodash';
import { isHideLayout } from '@/services/api/util';

const PhoneBridgeContext = React.createContext({});

interface IPhoneBridgeProvider {}

function wait(milliseconds) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

export const iframe_id = `phone_bridge_iframe`;

const PhoneBridgeProvider: React.FunctionComponent<IPhoneBridgeProvider> = React.memo(
  ({ children }) => {
    const { data: myAgent, isRefetching: isLoadingMyAgent } = useMyAgent();
    const [currentStatus, setCurrentStatus] = useState('');
    const [agentStatus, setAgentStatus] = useState<'ONLINE' | 'OFFLINE' | 'BUSY'>('OFFLINE');
    const readyReceiveCall = agentStatus === 'ONLINE';
    const { isOpen: isReady, open: setReady } = useDisclosure(false);
    const [activeLineNumber, setActiveLineNumber] = React.useState(undefined);
    const [lines, setLines] = React.useState([]);
    const url = useHistory();
    const hasAgent = myAgent?.data && !isLoadingMyAgent;
    const { formatMessage } = useIntl();
    const getIframe = () => {
      return window?.document?.getElementById(iframe_id)?.contentWindow;
    };

    const _makeCall = async (phoneNumber: string, extraHeaders?: string[]) => {
      let _phoneNumber = parsePhoneNumber(phoneNumber, 'VN')?.nationalNumber || '';
      _phoneNumber = `0${_phoneNumber}`;
      if (lines?.find((e) => e?.DisplayNumber === _phoneNumber)) {
        message.error('Số điện thoại đang được gọi');
        return;
      }

      getIframe()?.DialByLine('audio', null, _phoneNumber, null, extraHeaders);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const makeCall = debounce(async (phoneNumber: string, extraHeaders?: string[]) => {
      _makeCall(phoneNumber, extraHeaders);
    }, 1000);

    const wrappedSetActiveLine = (LineNumber: string) => {
      getIframe()?.SelectLine(LineNumber);
      setActiveLineNumber(LineNumber);
    };

    const ToggleDoNoDisturb = () => {
      window.getPhoneBridgeIframe()?.ToggleDoNoDisturb();
    };

    const somethingWentWrong = () => {
      if (!isReady) {
        return;
      }
      const key = `open${Date.now()}`;

      const btn = (
        <Space>
          <Button type="link" size="small" onClick={() => notification.close(key)}>
            {formatMessage({ id: 'close' })}
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              getIframe()?.location?.reload();
              setLines([]);
            }}
          >
            {formatMessage({ id: 'khi_ng_li' })}
          </Button>
        </Space>
      );
      notification.warn({
        key,
        message: formatMessage({ id: 'li_h_thng_gi_in' }),
        description: formatMessage({ id: 'bn_c_mun_th_li_khng' }),
        btn: btn,
        duration: 6,
      });
    };

    useInterval(
      () => {
        if (!ENABLED_IFRAME_INTERVAL_SYNC) {
          return;
        }
        if (getIframe()?.Lines) {
          setLines([...getIframe().Lines]);
        }
        if (!getIframe()?.$) {
          return;
        }
        if (getIframe()?.$('#regStatus')) {
          const _status = getIframe()?.$('#regStatus')?.text();
          setCurrentStatus(_status);
          if (_status === 'Registered') {
            setReady();
          }
        }
        if (getIframe()?.$('#dndStatus')) {
          const _status = getIframe()?.$('#dndStatus')?.text();
          setAgentStatus(_status !== '(DND)' ? 'ONLINE' : 'BUSY');
        }
      },
      process.env.NODE_ENV === 'development' ? 1000 : 300,
    );

    useEffect(() => {
      if (lines && lines?.length === 1) {
        setActiveLineNumber(lines[0]?.LineNumber);
      }
    }, [lines]);

    useEffect(() => {
      return () => {
        delete window.makeCallToPhoneBridge;
        delete window.getPhoneBridgeIframe;
        delete window.ToggleDoNoDisturb;
        delete window.readyReceiveCall;
        delete window.somethingWentWrong;
      };
    }, []);

    useEffect(() => {
      if (
        url?.location?.pathname?.includes('campaigns/dials') &&
        url?.location?.pathname?.includes('/start')
      ) {
        setAgentStatus('ONLINE');
        return;
      } else {
        setAgentStatus('OFFLINE');
      }
    }, [url.location.pathname]);

    window.makeCallToPhoneBridge = makeCall;
    window.getPhoneBridgeIframe = getIframe;
    window.ToggleDoNoDisturb = ToggleDoNoDisturb;
    window.readyReceiveCall = readyReceiveCall;
    window.somethingWentWrong = somethingWentWrong;

    return (
      <PhoneBridgeContext.Provider
        value={{
          makeCall,
          activeLineNumber,
          setActiveLineNumber: wrappedSetActiveLine,
          getIframe,
          lines,
          agentStatus,
          setAgentStatus,
        }}
      >
        <div className="d-flex flex-column">
          <div>
            <UnmountClosed isOpened={!isReady && currentStatus?.trim() !== '' && hasAgent}>
              <Alert message={currentStatus} type="warning" style={{ margin: '8px 0' }} />
            </UnmountClosed>
            {/*<UnmountClosed isOpened={lines?.length !== 0}>*/}

            {isHideLayout() ? (
              <Row gutter={[16, 16]} className="mt-2">
                {lines?.map((line, index) => {
                  return <LineCall lineObj={line} key={`lineObj-${line?.LineNumber}`} />;
                })}
              </Row>
            ) : (
              <Sticky
                innerActiveClass="active-line-call"
                activeClass="active-line-call-shadow"
                enabled={true}
                top={70}
                bottomBoundary={1200}
              >
                <Row gutter={[16, 16]} className="mt-2">
                  {lines?.map((line, index) => {
                    return <LineCall lineObj={line} key={`lineObj-${line?.LineNumber}`} />;
                  })}
                </Row>
              </Sticky>
            )}
            {/*</UnmountClosed>*/}
            {children}
          </div>
        </div>
      </PhoneBridgeContext.Provider>
    );
  },
);

export function usePhoneBridge(): any {
  const context = useContext<any>(PhoneBridgeContext);

  if (!context) {
  }
  return context;
}

export default React.memo(PhoneBridgeProvider);
