import { Spin } from 'antd';
import { Menu, Dropdown } from 'antd';
import {
  BankOutlined,
  DesktopOutlined,
  DownOutlined,
  HomeFilled,
  ShopOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { IoMdBriefcase } from 'react-icons/io';
import { history } from '@@/core/history';
import { routesPath } from '../../../config/routes';
import useCurrentCompany from '@/services/api/company/useCurrentCompany';
import { REMEMBER_COMPANY_KEY } from '@/pages/onboard';
import { useEffect } from 'react';
import { useIntl } from '@@/plugin-locale/localeExports';
import { useQueryClient } from 'react-query';
import { useMyAgentKey } from '@/services/api/agent/useMyAgent';
import { useMediaQuery } from 'react-responsive';

const CompanyDropdown = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' });
  const { isLoading, data } = useCurrentCompany();
  const { formatMessage: $t } = useIntl();

  const menu = (
    <Menu
      onClick={() => {
        window.localStorage.removeItem(REMEMBER_COMPANY_KEY);
        history.push(routesPath.onboard);
      }}
    >
      <Menu.Item>
        <UserSwitchOutlined style={{ opacity: 0.5 }} /> {$t({ id: 'i_workspace' })}
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (!data?.data?.partner) {
      return;
    }
    const { color, name: title, logo } = data?.data?.partner;
    window.initialState.title = title;

    window.setInitialState((preInitialState) => {
      return {
        ...preInitialState,
        title: title,
        settings: {
          primaryColor: color,
          title: title,
          logo: logo,
        },
      };
    });
  }, [data]);

  if (isLoading) {
    return <Spin />;
  }

  return (
    <Dropdown overlay={menu}>
      <a
        className="d-flex flex-row text-white align-items-center justify-content-center"
        onClick={(e) => e.preventDefault()}
      >
        <ShopOutlined style={{ paddingRight: '4px' }} />

        {!isMobile && $t({ id: 'cng_ty' }, { name: data?.data?.name })}
        <DownOutlined style={{ paddingLeft: '20px' }} />
      </a>
    </Dropdown>
  );
};

export default CompanyDropdown;
