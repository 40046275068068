import { useMutation } from 'react-query';
import type { DefaultServerError, ICallBotScenario } from '@/services/api/types';
import axiosInstance from '@/services/api/axiosInstance';
import type { AxiosResponse } from 'axios';
import useCompanyId from '@/hooks/useCompanyId';

function useMarkAsRead(NOTIFICATION_ID: string) {
  const { apiRoutes } = useCompanyId();

  return useMutation<AxiosResponse<ICallBotScenario>, DefaultServerError>((input) => {
    return axiosInstance.patch(apiRoutes.notifications.detail(NOTIFICATION_ID), {
      is_read: true,
    });
  });
}

export default useMarkAsRead;
