import { useMutation } from 'react-query';
import type { DefaultServerError, ICampaign } from '@/services/api/types';
import axiosInstance from '@/services/api/axiosInstance';
import type { AxiosResponse } from 'axios';
import useCompanyId from '@/hooks/useCompanyId';

function useCreateCustomerNote(CUSTOMER_ID: string) {
  const { apiRoutes } = useCompanyId();

  return useMutation<AxiosResponse<ICampaign>, DefaultServerError>((input) => {
    return axiosInstance.post(apiRoutes.customer_note(CUSTOMER_ID).list, {
      ...input,
    });
  });
}

export default useCreateCustomerNote;
