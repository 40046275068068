import { useQuery } from 'react-query';
import axiosInstance from '@/services/api/axiosInstance';
import type { ServerFormError } from '@/services/api/types';
import { IGuide, ILabel } from '@/services/api/types';
import type { AxiosResponse } from 'axios';
import useCompanyId from '@/hooks/useCompanyId';

export const useGuideKey = 'guides';

function useGuide(guideSlug: string | undefined) {
  const { apiRoutes } = useCompanyId();
  return useQuery<AxiosResponse<IGuide[]>, ServerFormError>(
    [useGuideKey, guideSlug],
    () => {
      return axiosInstance.get(apiRoutes.guide.detail, {
        params: {
          feature: guideSlug,
        },
      });
    },
    {
      enabled: !!guideSlug,
    },
  );
}

export default useGuide;
