import { useMutation } from 'react-query';
import { baseURL } from '@/services/api/axiosInstance';
import type { ServerFormError } from '@/services/api/types';
import type { AxiosResponse } from 'axios';
import axios from 'axios';

export interface useResendEmailParams {
  email: string;
}

function useResendEmail() {
  return useMutation<AxiosResponse, ServerFormError, useResendEmailParams>((formData) => {
    return axios.post(`${baseURL}/resend_email`, {
      email: formData.email,
    });
  });
}

export default useResendEmail;
