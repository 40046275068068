import { matchPath, useLocation } from 'umi';
import { nestedRoutesPath } from '../../config/routes';
import { nestedApiRoutes } from '@/services/api/axiosInstance';

function useCompanyId() {
  const params = useLocation();
  const match1 = matchPath<any>(params.pathname, {
    path: '/company/:companyId+/*',
    exact: true,
    strict: false,
  });
  const match2 = matchPath<any>(params.pathname, {
    path: '/company/:companyId+',
    exact: true,
    strict: false,
  });
  const companyId = (match1?.params?.companyId || match2?.params?.companyId)?.split('/')?.[0];
  const routes = nestedRoutesPath(companyId);
  const apiRoutes = nestedApiRoutes(companyId);

  return {
    COMPANY_ID: companyId?.split('/')?.[0],
    routes,
    apiRoutes,
  };
}

export default useCompanyId;
