import { MenuDataItem } from '@ant-design/pro-layout/lib/typings';
import React from 'react';
import { Link } from '@umijs/preset-dumi/lib/theme';
import useCompanyId from '@/hooks/useCompanyId';

interface MenuItemCompanyResolverProps {
  menuItemProps: MenuDataItem & {
    isUrl: boolean;
    onClick: () => void;
  };
  defaultDom: React.ReactNode;
}

const MenuItemCompanyResolver = ({ menuItemProps, defaultDom }: MenuItemCompanyResolverProps) => {
  const { COMPANY_ID } = useCompanyId();

  return <Link to={menuItemProps.path?.replaceAll(':companyID', COMPANY_ID)}>{defaultDom}</Link>;
};

export default MenuItemCompanyResolver;
