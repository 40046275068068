import { useQuery } from 'react-query';
import axiosInstance from '@/services/api/axiosInstance';
import type { ServerFormError } from '@/services/api/types';
import type { AxiosResponse } from 'axios';
import useCompanyId from '@/hooks/useCompanyId';
import type { ICallBotDetail } from '@/services/api/types';
import { isNil } from 'lodash';

export const useCallBotDetailKey = 'call_bot_detail';

export function useCallBotDetailPreview(COMPANY_ID: string, CALL_BOT_SCENARIO_ID: string) {
  return useQuery<AxiosResponse<ICallBotDetail>, ServerFormError>(
    [useCallBotDetailKey, CALL_BOT_SCENARIO_ID],
    () => {
      return axiosInstance.get(`/company/${COMPANY_ID}/call_bot_scenario/${CALL_BOT_SCENARIO_ID}`);
    },
    {
      enabled: !isNil(COMPANY_ID) && !isNil(CALL_BOT_SCENARIO_ID),
    },
  );
}

function useCallBotDetail(CALL_BOT_SCENARIO_ID: string | undefined) {
  const { apiRoutes } = useCompanyId();

  return useQuery<AxiosResponse<ICallBotDetail>, ServerFormError>(
    [useCallBotDetailKey, CALL_BOT_SCENARIO_ID],
    () => {
      return axiosInstance.get(apiRoutes.call_bot_scenario.detail(CALL_BOT_SCENARIO_ID || ''));
    },
    {
      enabled: !isNil(CALL_BOT_SCENARIO_ID),
      refetchOnWindowFocus: false,
    },
  );
}

export default useCallBotDetail;
