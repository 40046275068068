// @ts-nocheck

import StarOutlined from '@ant-design/icons/StarOutlined';
import TeamOutlined from '@ant-design/icons/TeamOutlined';
import PhoneOutlined from '@ant-design/icons/PhoneOutlined';
import ImportOutlined from '@ant-design/icons/ImportOutlined';
import FileOutlined from '@ant-design/icons/FileOutlined';
import NotificationOutlined from '@ant-design/icons/NotificationOutlined';
import WalletOutlined from '@ant-design/icons/WalletOutlined';
import BellOutlined from '@ant-design/icons/BellOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined';
import GiftOutlined from '@ant-design/icons/GiftOutlined'

export default {
  StarOutlined,
TeamOutlined,
PhoneOutlined,
ImportOutlined,
FileOutlined,
NotificationOutlined,
WalletOutlined,
BellOutlined,
SettingOutlined,
QuestionCircleOutlined,
GiftOutlined
}
    