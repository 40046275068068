import { useMutation } from 'react-query';
import type { DefaultServerError, ICallBotScenario } from '@/services/api/types';
import axiosInstance from '@/services/api/axiosInstance';
import type { AxiosResponse } from 'axios';
import useCompanyId from '@/hooks/useCompanyId';
import { message } from 'antd';

export interface useCreateCallBotScenarioCompanyInput {
  title: string;
  content: string;
}

function useCreateCallBotScenarioCompany() {
  const { apiRoutes } = useCompanyId();

  return useMutation<
    AxiosResponse<ICallBotScenario>,
    DefaultServerError,
    useCreateCallBotScenarioCompanyInput
  >((input) => {
    return axiosInstance.post(apiRoutes.call_bot_scenario_company_sample.list, {
      ...input,
    });
  }, {onSuccess: () => {
    message.success("Thêm kịch bản mẫu thành công");
  },});
}

export default useCreateCallBotScenarioCompany;
