import { useQuery } from 'react-query';
import axiosInstance from '@/services/api/axiosInstance';
import type { ServerFormError } from '@/services/api/types';
import type { AxiosResponse } from 'axios';
import type { ICompany } from '@/services/api/types';

export const useListCompanyKey = 'companies';

function useListCompany() {
  return useQuery<AxiosResponse<ICompany[]>, ServerFormError>([useListCompanyKey], () => {
    return axiosInstance.get('/company');
  });
}

export default useListCompany;
