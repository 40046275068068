import { useEffect } from 'react';
import { isLandingPage } from '@/constants';
import useCurrentCompany from '@/services/api/company/useCurrentCompany';

const ChatProvider = ({ children }) => {
  const { data: companyData } = useCurrentCompany();

  useEffect(() => {
    if (isLandingPage) {
      // Facebook Chat initialize, only on landing page
      window.fbAsyncInit = function () {
        FB.init({
          xfbml: true,
          version: 'v14.0',
        });
      };

      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://connect.facebook.net/vi_VN/sdk/xfbml.customerchat.js';
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
      return;
    }
  }, []);

  useEffect(() => {
    // Zalo init
    if (isLandingPage || companyData?.data?.partner?.is_default) {
      (function () {
        var s1 = document.createElement('script'),
          s0 = document.getElementsByTagName('script')[0];
        s1.async = true;
        s1.src = '/zalosdk.js';
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        s0.parentNode.insertBefore(s1, s0);
      })();
    }
  }, [companyData]);

  return (
    <>
      <div
        className="zalo-chat-widget"
        data-oaid="167003496959089065"
        data-welcome-message="Rất vui khi được hỗ trợ bạn!"
        data-autopopup="0"
        data-width=""
        data-height=""
      ></div>
      {children}
    </>
  );
};

export default ChatProvider;
