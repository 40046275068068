import { Divider, Input, Row, Spin } from 'antd';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import useUpdateCustomer from '@/services/api/customer/useUpdateCustomer';
import { UnmountClosed } from 'react-collapse';
import useCustomer from '@/services/api/customer/useCustomer';
import { useIntl } from 'umi';

interface FieldCustomerEditableProps {
  customerID: string;
  fieldName: string;
  fieldValue: any;
  editable: boolean;
  isOtherAttributes: boolean;
}

const FieldCustomerEditable = ({
  customerID,
  fieldName,
  fieldValue,
  editable,
  isOtherAttributes,
}: FieldCustomerEditableProps) => {
  const [originalValue, setOriginalValue] = useState(fieldValue);
  const { data, refetch } = useCustomer(customerID);
  const { mutateAsync: updateCustomer, isLoading } = useUpdateCustomer(customerID);
  const changed = originalValue !== fieldValue;
  const customer = data?.data;
  const { formatMessage } = useIntl();

  const boldStyle = changed ? { color: 'var(--ant-primary-color)', fontWeight: 'bold' } : {};

  const onSubmit = async () => {
    let requestData = {
      [fieldName]: originalValue,
    };
    if (isOtherAttributes) {
      requestData = {
        other_attributes: {
          ...customer?.other_attributes,
          [fieldName]: originalValue,
        },
      };
    }
    await updateCustomer(requestData);
    await refetch();
  };
  return (
    <Row style={{ width: '100%' }} align="middle" justify="space-between">
      <div>
        <b>{fieldName?.toUpperCase()}</b>
      </div>
      <div
        className="d-flex flex-row align-items-center"
        style={{ width: '60%', textAlign: 'right' }}
      >
        {editable && (
          <>
            <Input
              value={originalValue}
              onChange={(e) => {
                setOriginalValue(e.currentTarget.value);
              }}
              style={{ width: '100%' }}
            />
            <div style={{ cursor: 'pointer' }}>
              {isLoading && <Spin style={{ marginLeft: '8px' }} size="small" />}
              {!isLoading && (
                <div className="d-flex flex-row align-items-center">
                  <div
                    onClick={() => {
                      onSubmit();
                    }}
                    className="d-flex flex-row align-items-center"
                  >
                    <SaveOutlined
                      disabled={originalValue !== fieldValue}
                      style={{
                        marginLeft: '12px',
                        ...boldStyle,
                      }}
                    />

                    <span
                      style={{
                        marginLeft: '8px',
                        ...boldStyle,
                      }}
                    >
                      {formatMessage({ id: 'lu' })}
                    </span>
                  </div>
                  <Divider type="vertical" style={{ borderWidth: '2px' }} />

                  <UnmountClosed isOpened={changed}>
                    <div
                      onClick={() => {
                        setOriginalValue(fieldValue);
                      }}
                      className="d-flex flex-row align-items-center"
                    >
                      <CloseOutlined
                        style={{
                          marginLeft: '4px',
                        }}
                      />
                      <span style={{ marginLeft: '8px' }}>{formatMessage({ id: 'discard' })}</span>
                    </div>
                  </UnmountClosed>
                </div>
              )}
            </div>
          </>
        )}
        {!editable && <span>{fieldValue}</span>}
      </div>
    </Row>
  );
};

export default FieldCustomerEditable;
