import { useQuery } from 'react-query';
import axiosInstance from '@/services/api/axiosInstance';
import type { ICampaign, IPaginate, ServerFormError } from '@/services/api/types';
import type { AxiosResponse } from 'axios';
import useCompanyId from '@/hooks/useCompanyId';

export const useCustomerNoteKey = 'customer-notes';

function useCustomerNotes(CUSTOMER_ID: string) {
  const { COMPANY_ID, apiRoutes } = useCompanyId();

  return useQuery<AxiosResponse<IPaginate<any>>, ServerFormError>(
    [useCustomerNoteKey, COMPANY_ID, CUSTOMER_ID],
    () => {
      return axiosInstance.get(apiRoutes.customer_note(CUSTOMER_ID).list, {
        params: { size: 10000, page: 1, ordering: '-created_at' },
      });
    },
    {
      enabled: CUSTOMER_ID !== undefined,
    },
  );
}

export default useCustomerNotes;
