import type { Settings as LayoutSettings } from '@ant-design/pro-layout';
import { PageLoading, SettingDrawer } from '@ant-design/pro-layout';
import { history, RunTimeLayoutConfig, useIntl } from 'umi';
import RightContent from '@/components/RightContent';
import Footer from '@/components/Footer';
import defaultSettings from '../config/defaultSettings';
import { routesPath } from '../config/routes';
import { QueryClient, QueryClientProvider } from 'react-query';
import { CookiesConstant } from '@/services/api/types';
import axiosInstance, {
  checkCookiesParamsLandingPage,
  cookies,
  getAccessToken,
} from '@/services/api/axiosInstance';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import MenuItemCompanyResolver from '@/components/MenuItemCompanyResolver';
import ListJobs from '@/components/ListJobs';

import * as Sentry from '@sentry/react';
import { CaptureConsole } from '@sentry/integrations';
import { ConfirmationAccountProvider } from '@/components/ConfirmationAccountProvider';
import { Alert, Button } from 'antd';
import { DISPLAYED_POPUP, envs, isLandingPage } from '@/constants';
import { formatDateFromString, isHideLayout } from '@/services/api/util';
import { useListCompanyKey } from '@/services/api/company/useListCompany';
import { REMEMBER_COMPANY_KEY } from '@/pages/onboard';

import 'antd-country-phone-input/dist/index.css';
import 'animate.css';
import PhoneBridgeProvider from '@/components/PhoneBridgeProvider';
import DrawerHelperProvider from '@/components/DrawerHelperProvider';
import ChatProvider from '@/components/ChatProvider/ChatProvider';
import ListImportantNotifications from '@/components/ListImportantNotifications';
import { ArrowLeftOutlined } from '@ant-design/icons';

const isDev = process.env.NODE_ENV === 'development';
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 150,
    },
  },
});

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://e0fb75b1d9b24dde8aabc2c0e876c877@o4504016049668096.ingest.sentry.io/4505181650485248',
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay(),
      new CaptureConsole({
        levels: ['error'],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const consoleError = console.error.bind(console);
// eslint-disable-next-line
console.error = (message, ...args) => {
  if (typeof message === 'string' && message.startsWith('[React Intl] Missing message:')) {
    return;
  }
  consoleError(message, ...args);
};

export const initialStateConfig = {
  loading: <PageLoading />,
};

/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */
export async function getInitialState(): Promise<{
  settings?: Partial<LayoutSettings>;
  loading?: boolean;
}> {
  const current_partner = await axiosInstance.get('/current_partner');
  return {
    settings: { ...defaultSettings, current_partner: current_partner.data },
  };
}

export const layout: RunTimeLayoutConfig = ({ initialState, setInitialState }) => {
  // persistQueryClient({
  //   queryClient,
  // persistor: localStoragePersistor,
  // });
  const access = getAccessToken();
  const refresh = cookies.get(CookiesConstant.REFRESH_TOKEN);
  const apiKey = cookies.get(CookiesConstant.API_KEY);

  const prefixData =
    queryClient.getQueryData([
      useListCompanyKey,
      window.localStorage.getItem(REMEMBER_COMPANY_KEY),
    ]) || {};
  let preloadSettings = {
    primaryColor: '#1E276C',
    title: '',
    logo: '/logo.svg',
    siderWidth: 0,
  };
  window.initialState = initialState;
  window.setInitialState = setInitialState;
  if (prefixData && prefixData?.data?.partner && prefixData?.data?.partner?.color) {
    const { color, name: title, logo } = prefixData?.data?.partner;
    preloadSettings = {
      primaryColor: color,
      title: title,
      logo: logo,
      siderWidth: 208,
    };
    window.initialState.title = title;
  }

  checkCookiesParamsLandingPage();
  const isNotFound = history.location.pathname === routesPath.not_found;

  if (window.localStorage.getItem('umi_locale') === null) {
    window.localStorage.setItem('umi_locale', 'vi-VN');
    window.location.reload();
  }

  return {
    ...initialState?.settings,
    ...preloadSettings,
    // openKeys: [
    //   '/company/:companyID/wallet',
    //   '/company/:companyID/customers',
    //   '/company/:companyID/campaigns',
    //   '/company/:companyID/settings',
    //   '/company/:companyID/partner',
    //   '/company/:companyID/caller',
    // ],
    rightContentRender: () => (
      <QueryClientProvider client={queryClient}>
        <RightContent />
      </QueryClientProvider>
    ),
    disableContentMargin: false,
    waterMarkProps: {
      content: 'UCALL',
    },
    footerRender: () => (
      <QueryClientProvider client={queryClient}>
        <Footer />
      </QueryClientProvider>
    ),
    onPageChange: () => {
      window.sessionStorage.removeItem(DISPLAYED_POPUP);
      if (isLandingPage) {
        return;
      }
      if (location.pathname === routesPath.register) {
        return;
      }
      if (!isLandingPage && location.pathname === '/' && (access || refresh || apiKey)) {
        history.push(routesPath.onboard);
      }
      if (
        !access &&
        !refresh &&
        !apiKey &&
        location.pathname !== routesPath.login &&
        location.pathname !== routesPath.register &&
        location.pathname !== routesPath.register &&
        location.pathname !== routesPath.confirmation &&
        !location.pathname?.includes(routesPath.preview)
      ) {
        history.push(`${routesPath.login}?redirect=${location.pathname}`);
      }
    },
    links: isDev ? [] : [],
    menuHeaderRender: undefined,
    childrenRender: (children, props) => {
      const { formatMessage } = useIntl();
      return (
        <>
          {envs.IS_DEV && (
            <Alert
              banner
              showIcon={false}
              closable
              message={
                <div className="d-flex align-items-center justify-content-between flex-wrap">
                  <div>
                    <span>
                      {formatMessage({ id: 'y_l' })}{' '}
                      <b>
                        {formatMessage({ id: 'phin_bn' })} {envs.IS_DEV && !envs?.IS_STAG && 'Dev'}
                        {envs?.IS_STAG && 'Staging'}
                      </b>{' '}
                      {formatMessage({ id: 'ca_ucall' })}{' '}
                    </span>
                    <span>
                      {formatMessage({ id: 'c_build_vo_lc' })}{' '}
                      <b>{formatDateFromString(BUILD_TIME)}</b>
                    </span>
                  </div>
                </div>
              }
            />
          )}
          <QueryClientProvider client={queryClient}>
            <ConfirmationAccountProvider>
              <TransitionGroup>
                <CSSTransition
                  key={location.pathname}
                  classNames="fade"
                  unmountOnExit
                  timeout={300}
                >
                  <ChatProvider>
                    <PhoneBridgeProvider>
                      <DrawerHelperProvider>
                        <div style={{ display: 'block' }}>
                          <ListImportantNotifications />
                          <ListJobs />
                          {isHideLayout() && (
                            <div className="mb-2">
                              <Button
                                onClick={(event) => {
                                  event.stopPropagation();
                                  history.goBack();
                                }}
                                style={{ border: 'none' }}
                              >
                                <ArrowLeftOutlined />
                              </Button>
                            </div>
                          )}
                          {children}
                        </div>
                      </DrawerHelperProvider>
                    </PhoneBridgeProvider>
                  </ChatProvider>
                </CSSTransition>
              </TransitionGroup>
            </ConfirmationAccountProvider>
            <SettingDrawer
              prefixCls="ahihi"
              disableUrlParams
              enableDarkTheme
              settings={initialState?.settings}
              onSettingChange={(settings) => {
                setInitialState((preInitialState) => {
                  return {
                    ...preInitialState,
                    settings,
                  };
                });
              }}
            />
          </QueryClientProvider>
        </>
      );
    },
    ...(prefixData?.data?.partner?.name ? { title: prefixData?.data?.partner?.name } : {}),
    menuItemRender: (menuItemProps, defaultDom) => {
      return <MenuItemCompanyResolver menuItemProps={menuItemProps} defaultDom={defaultDom} />;
    },
    ...(isNotFound
      ? {
          headerRender: () => {
            return <></>;
          },
          headerHeight: 0,
          siderWidth: 0,
        }
      : {}),
    ...(isHideLayout()
      ? {
          menuRender: false,
          headerRender: false,
          contentStyle: {
            margin: 0,
          },
        }
      : {}),
  };
};
