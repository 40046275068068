import { useMutation } from 'react-query';
import type { DefaultServerError } from '@/services/api/types';
import axiosInstance from '@/services/api/axiosInstance';
import type { AxiosResponse } from 'axios';
import useCompanyId from '@/hooks/useCompanyId';

interface useUploadFileInput {
  formData: FormData;
}

function useUploadFile() {
  const { apiRoutes } = useCompanyId();

  return useMutation<AxiosResponse, DefaultServerError, useUploadFileInput>(({ formData }) => {
    return axiosInstance.post(apiRoutes.file.list, formData, {
      headers: { 'content-type': 'multipart/form-data' },
    });
  });
}

export default useUploadFile;
