import { useIntl } from 'umi';

export const constants = () => {
  const { formatMessage } = useIntl();

  const KnownPermissions = [
    'add_callcampaign',
    'view_callcampaign_own',
    'view_callcampaign_group',
    'view_callcampaign',
    'change_callcampaignsetting_own',
    'change_callcampaignsetting_group',
    'change_callcampaignsetting',
    'change_callcampaign_own',
    'change_callcampaign_group',
    'change_callcampaign',
    'view_callcampaignreport_own',
    'view_callcampaignreport_group',
    'view_callcampaignreport',
    'delete_callcampaign_own',
    'delete_callcampaign_group',
    'delete_callcampaign',
    'add_agentcallcampaign',
    'view_agentcallcampaign_own',
    'view_agentcallcampaign_group',
    'view_agentcallcampaign',
    'change_agentcallcampaignsetting_own',
    'change_agentcallcampaignsetting_group',
    'change_agentcallcampaignsetting',
    'change_agentcallcampaign_own',
    'change_agentcallcampaign_group',
    'change_agentcallcampaign',
    'view_agentcallcampaignreport_own',
    'view_agentcallcampaignreport_group',
    'view_agentcallcampaignreport',
    'delete_agentcallcampaign_own',
    'delete_agentcallcampaign_group',
    'delete_agentcallcampaign',
    'add_dialcampaign',
    'view_dialcampaign_own',
    'view_dialcampaign_group',
    'view_dialcampaign',
    'change_dialcampaignsetting_own',
    'change_dialcampaignsetting_group',
    'change_dialcampaignsetting',
    'change_dialcampaign_own',
    'change_dialcampaign_group',
    'change_dialcampaign',
    'view_dialcampaignreport_own',
    'view_dialcampaignreport_group',
    'view_dialcampaignreport',
    'delete_dialcampaign_own',
    'delete_dialcampaign_group',
    'delete_dialcampaign',
    'add_customer',
    'view_contact_customer',
    'export_customer',
    'view_customer_own',
    'view_customer_group',
    'view_customer',
    'change_customer_own',
    'change_customer_group',
    'change_customer',
    'change_employees_customer_own',
    'change_employees_customer_group',
    'change_employees_customer',
    'delete_customer_own',
    'delete_customer_group',
    'delete_customer',
    'change_manager_customer',
    'add_customergroup',
    'view_customergroup_own',
    'view_customergroup_group',
    'view_customergroup',
    'change_customergroup_own',
    'change_customergroup_group',
    'change_customergroup',
    'delete_customergroup_own',
    'delete_customergroup_group',
    'delete_customergroup',
    'view_call_manual_own',
    'view_call_manual_group',
    'view_call_manual',
    'export_call',
    'view_call_auto',
    'view_transactionsubscription',
    'view_subscription',
    'change_payment_infomation_company',
    'add_companyauthgroup_group',
    'add_companyauthgroup',
    'view_companyauthgroup_group',
    'view_companyauthgroup',
    'change_companyauthgroup_group',
    'change_companyauthgroup',
    'delete_companyauthgroup_group',
    'delete_companyauthgroup',
    'call_customer',
    'view_callcampaigncustomer',
    'view_callcampaigncustomer_group',
    'view_callcampaigncustomer_own',
  ];

  const SortedGroups = [
    formatMessage({ id: 'khch_hng' }),
    formatMessage({ id: 'tp_khch_hng' }),
    formatMessage({ id: 'menu.campaigns.call_campaigns' }),
    formatMessage({ id: 'chin_dch_tele' }),
    formatMessage({ id: 'dial_campaigns' }),
    formatMessage({ id: 'menu.history' }),
    formatMessage({ id: 'ngi_dng' }),
    formatMessage({ id: 'thanh_ton' }),
  ];

  const TransposePermissionObjects = {
    view_callcampaigncustomer: {
      group: formatMessage({ id: 'khch_hng' }),
      permission: formatMessage({ id: 'permission.xem_gi_khch_hng_chin_dch' }),
      privilege: formatMessage({ id: 'tt_c' }),
      code: 'view_callcampaigncustomer',
    },
    view_callcampaigncustomer_group: {
      group: formatMessage({ id: 'khch_hng' }),
      permission: formatMessage({ id: 'permission.xem_gi_khch_hng_chin_dch' }),
      privilege: formatMessage({ id: 'nhm' }),
      code: 'view_callcampaigncustomer_group',
    },
    view_callcampaigncustomer_own: {
      group: formatMessage({ id: 'khch_hng' }),
      permission: formatMessage({ id: 'permission.xem_gi_khch_hng_chin_dch' }),
      privilege: formatMessage({ id: 'c_nhn' }),
      code: 'view_callcampaigncustomer_own',
    },
    add_callcampaign: {
      group: formatMessage({ id: 'menu.campaigns.call_campaigns' }),
      permission: formatMessage({ id: 'permission.to_chin_dch' }),
      privilege: undefined,
      code: 'add_callcampaign',
    },
    view_callcampaign_own: {
      group: formatMessage({ id: 'menu.campaigns.call_campaigns' }),
      permission: formatMessage({ id: 'permission.xem_chin_dch' }),
      privilege: formatMessage({ id: 'c_nhn' }),
      code: 'view_callcampaign_own',
    },
    view_callcampaign_group: {
      group: formatMessage({ id: 'menu.campaigns.call_campaigns' }),
      permission: formatMessage({ id: 'permission.xem_chin_dch' }),
      privilege: formatMessage({ id: 'nhm' }),
      code: 'view_callcampaign_group',
    },
    view_callcampaign: {
      group: formatMessage({ id: 'menu.campaigns.call_campaigns' }),
      permission: formatMessage({ id: 'permission.xem_chin_dch' }),
      privilege: formatMessage({ id: 'tt_c' }),
      code: 'view_callcampaign',
    },
    change_callcampaignsetting_own: {
      group: formatMessage({ id: 'menu.campaigns.call_campaigns' }),
      permission: formatMessage({ id: 'permission.cp_nht_ci_t_chin_dch' }),
      privilege: formatMessage({ id: 'c_nhn' }),
      code: 'change_callcampaignsetting_own',
    },
    change_callcampaignsetting_group: {
      group: formatMessage({ id: 'menu.campaigns.call_campaigns' }),
      permission: formatMessage({ id: 'permission.cp_nht_ci_t_chin_dch' }),
      privilege: formatMessage({ id: 'nhm' }),
      code: 'change_callcampaignsetting_group',
    },
    change_callcampaignsetting: {
      group: formatMessage({ id: 'menu.campaigns.call_campaigns' }),
      permission: formatMessage({ id: 'permission.cp_nht_ci_t_chin_dch' }),
      privilege: formatMessage({ id: 'tt_c' }),
      code: 'change_callcampaignsetting',
    },
    change_callcampaign_own: {
      group: formatMessage({ id: 'menu.campaigns.call_campaigns' }),
      permission: formatMessage({ id: 'permission.cp_nht_trng_thi_bt_u_dng_chin_dch' }),
      privilege: formatMessage({ id: 'c_nhn' }),
      code: 'change_callcampaign_own',
    },
    change_callcampaign_group: {
      group: formatMessage({ id: 'menu.campaigns.call_campaigns' }),
      permission: formatMessage({ id: 'permission.cp_nht_trng_thi_bt_u_dng_chin_dch' }),
      privilege: formatMessage({ id: 'nhm' }),
      code: 'change_callcampaign_group',
    },
    change_callcampaign: {
      group: formatMessage({ id: 'menu.campaigns.call_campaigns' }),
      permission: formatMessage({ id: 'permission.cp_nht_trng_thi_bt_u_dng_chin_dch' }),
      privilege: formatMessage({ id: 'tt_c' }),
      code: 'change_callcampaign',
    },
    view_callcampaignreport_own: {
      group: formatMessage({ id: 'menu.campaigns.call_campaigns' }),
      permission: formatMessage({ id: 'permission.xem_bo_co' }),
      privilege: formatMessage({ id: 'c_nhn' }),
      code: 'view_callcampaignreport_own',
    },
    view_callcampaignreport_group: {
      group: formatMessage({ id: 'menu.campaigns.call_campaigns' }),
      permission: formatMessage({ id: 'permission.xem_bo_co' }),
      privilege: formatMessage({ id: 'nhm' }),
      code: 'view_callcampaignreport_group',
    },
    view_callcampaignreport: {
      group: formatMessage({ id: 'menu.campaigns.call_campaigns' }),
      permission: formatMessage({ id: 'permission.xem_bo_co' }),
      privilege: formatMessage({ id: 'tt_c' }),
      code: 'view_callcampaignreport',
    },
    delete_callcampaign_own: {
      group: formatMessage({ id: 'menu.campaigns.call_campaigns' }),
      permission: formatMessage({ id: 'permission.xo_chin_dch' }),
      privilege: formatMessage({ id: 'c_nhn' }),
      code: 'delete_callcampaign_own',
    },
    delete_callcampaign_group: {
      group: formatMessage({ id: 'menu.campaigns.call_campaigns' }),
      permission: formatMessage({ id: 'permission.xo_chin_dch' }),
      privilege: formatMessage({ id: 'nhm' }),
      code: 'delete_callcampaign_group',
    },
    delete_callcampaign: {
      group: formatMessage({ id: 'menu.campaigns.call_campaigns' }),
      permission: formatMessage({ id: 'permission.xo_chin_dch' }),
      privilege: formatMessage({ id: 'tt_c' }),
      code: 'delete_callcampaign',
    },
    add_agentcallcampaign: {
      group: formatMessage({ id: 'chin_dch_tele' }),
      permission: formatMessage({ id: 'permission.to_chin_dch' }),
      privilege: undefined,
      code: 'add_agentcallcampaign',
    },
    view_agentcallcampaign_own: {
      group: formatMessage({ id: 'chin_dch_tele' }),
      permission: formatMessage({ id: 'permission.xem_chin_dch' }),
      privilege: formatMessage({ id: 'c_nhn' }),
      code: 'view_agentcallcampaign_own',
    },
    view_agentcallcampaign_group: {
      group: formatMessage({ id: 'chin_dch_tele' }),
      permission: formatMessage({ id: 'permission.xem_chin_dch' }),
      privilege: formatMessage({ id: 'nhm' }),
      code: 'view_agentcallcampaign_group',
    },
    view_agentcallcampaign: {
      group: formatMessage({ id: 'chin_dch_tele' }),
      permission: formatMessage({ id: 'permission.xem_chin_dch' }),
      privilege: formatMessage({ id: 'tt_c' }),
      code: 'view_agentcallcampaign',
    },
    change_agentcallcampaignsetting_own: {
      group: formatMessage({ id: 'chin_dch_tele' }),
      permission: formatMessage({ id: 'permission.cp_nht_ci_t_chin_dch' }),
      privilege: formatMessage({ id: 'c_nhn' }),
      code: 'change_agentcallcampaignsetting_own',
    },
    change_agentcallcampaignsetting_group: {
      group: formatMessage({ id: 'chin_dch_tele' }),
      permission: formatMessage({ id: 'permission.cp_nht_ci_t_chin_dch' }),
      privilege: formatMessage({ id: 'nhm' }),
      code: 'change_agentcallcampaignsetting_group',
    },
    change_agentcallcampaignsetting: {
      group: formatMessage({ id: 'chin_dch_tele' }),
      permission: formatMessage({ id: 'permission.cp_nht_ci_t_chin_dch' }),
      privilege: formatMessage({ id: 'tt_c' }),
      code: 'change_agentcallcampaignsetting',
    },
    change_agentcallcampaign_own: {
      group: formatMessage({ id: 'chin_dch_tele' }),
      permission: formatMessage({ id: 'permission.cp_nht_trng_thi_bt_u_dng_chin_dch' }),
      privilege: formatMessage({ id: 'c_nhn' }),
      code: 'change_agentcallcampaign_own',
    },
    change_agentcallcampaign_group: {
      group: formatMessage({ id: 'chin_dch_tele' }),
      permission: formatMessage({ id: 'permission.cp_nht_trng_thi_bt_u_dng_chin_dch' }),
      privilege: formatMessage({ id: 'nhm' }),
      code: 'change_agentcallcampaign_group',
    },
    change_agentcallcampaign: {
      group: formatMessage({ id: 'chin_dch_tele' }),
      permission: formatMessage({ id: 'permission.cp_nht_trng_thi_bt_u_dng_chin_dch' }),
      privilege: formatMessage({ id: 'tt_c' }),
      code: 'change_agentcallcampaign',
    },
    view_agentcallcampaignreport_own: {
      group: formatMessage({ id: 'chin_dch_tele' }),
      permission: formatMessage({ id: 'permission.xem_bo_co' }),
      privilege: formatMessage({ id: 'c_nhn' }),
      code: 'view_agentcallcampaignreport_own',
    },
    view_agentcallcampaignreport_group: {
      group: formatMessage({ id: 'chin_dch_tele' }),
      permission: formatMessage({ id: 'permission.xem_bo_co' }),
      privilege: formatMessage({ id: 'nhm' }),
      code: 'view_agentcallcampaignreport_group',
    },
    view_agentcallcampaignreport: {
      group: formatMessage({ id: 'chin_dch_tele' }),
      permission: formatMessage({ id: 'permission.xem_bo_co' }),
      privilege: formatMessage({ id: 'tt_c' }),
      code: 'view_agentcallcampaignreport',
    },
    delete_agentcallcampaign_own: {
      group: formatMessage({ id: 'chin_dch_tele' }),
      permission: formatMessage({ id: 'permission.xo_chin_dch' }),
      privilege: formatMessage({ id: 'c_nhn' }),
      code: 'delete_agentcallcampaign_own',
    },
    delete_agentcallcampaign_group: {
      group: formatMessage({ id: 'chin_dch_tele' }),
      permission: formatMessage({ id: 'permission.xo_chin_dch' }),
      privilege: formatMessage({ id: 'nhm' }),
      code: 'delete_agentcallcampaign_group',
    },
    delete_agentcallcampaign: {
      group: formatMessage({ id: 'chin_dch_tele' }),
      permission: formatMessage({ id: 'permission.xo_chin_dch' }),
      privilege: formatMessage({ id: 'tt_c' }),
      code: 'delete_agentcallcampaign',
    },
    add_dialcampaign: {
      group: formatMessage({ id: 'dial_campaigns' }),
      permission: formatMessage({ id: 'permission.to_chin_dch' }),
      privilege: undefined,
      code: 'add_dialcampaign',
    },
    view_dialcampaign_own: {
      group: formatMessage({ id: 'dial_campaigns' }),
      permission: formatMessage({ id: 'permission.xem_chin_dch' }),
      privilege: formatMessage({ id: 'c_nhn' }),
      code: 'view_dialcampaign_own',
    },
    view_dialcampaign_group: {
      group: formatMessage({ id: 'dial_campaigns' }),
      permission: formatMessage({ id: 'permission.xem_chin_dch' }),
      privilege: formatMessage({ id: 'nhm' }),
      code: 'view_dialcampaign_group',
    },
    view_dialcampaign: {
      group: formatMessage({ id: 'dial_campaigns' }),
      permission: formatMessage({ id: 'permission.xem_chin_dch' }),
      privilege: formatMessage({ id: 'tt_c' }),
      code: 'view_dialcampaign',
    },
    change_dialcampaignsetting_own: {
      group: formatMessage({ id: 'dial_campaigns' }),
      permission: formatMessage({ id: 'permission.cp_nht_ci_t_chin_dch' }),
      privilege: formatMessage({ id: 'c_nhn' }),
      code: 'change_dialcampaignsetting_own',
    },
    change_dialcampaignsetting_group: {
      group: formatMessage({ id: 'dial_campaigns' }),
      permission: formatMessage({ id: 'permission.cp_nht_ci_t_chin_dch' }),
      privilege: formatMessage({ id: 'nhm' }),
      code: 'change_dialcampaignsetting_group',
    },
    change_dialcampaignsetting: {
      group: formatMessage({ id: 'dial_campaigns' }),
      permission: formatMessage({ id: 'permission.cp_nht_ci_t_chin_dch' }),
      privilege: formatMessage({ id: 'tt_c' }),
      code: 'change_dialcampaignsetting',
    },
    change_dialcampaign_own: {
      group: formatMessage({ id: 'dial_campaigns' }),
      permission: formatMessage({ id: 'permission.cp_nht_trng_thi_bt_u_dng_chin_dch' }),
      privilege: formatMessage({ id: 'c_nhn' }),
      code: 'change_dialcampaign_own',
    },
    change_dialcampaign_group: {
      group: formatMessage({ id: 'dial_campaigns' }),
      permission: formatMessage({ id: 'permission.cp_nht_trng_thi_bt_u_dng_chin_dch' }),
      privilege: formatMessage({ id: 'nhm' }),
      code: 'change_dialcampaign_group',
    },
    change_dialcampaign: {
      group: formatMessage({ id: 'dial_campaigns' }),
      permission: formatMessage({ id: 'permission.cp_nht_trng_thi_bt_u_dng_chin_dch' }),
      privilege: formatMessage({ id: 'tt_c' }),
      code: 'change_dialcampaign',
    },
    view_dialcampaignreport_own: {
      group: formatMessage({ id: 'dial_campaigns' }),
      permission: formatMessage({ id: 'permission.xem_bo_co' }),
      privilege: formatMessage({ id: 'c_nhn' }),
      code: 'view_dialcampaignreport_own',
    },
    view_dialcampaignreport_group: {
      group: formatMessage({ id: 'dial_campaigns' }),
      permission: formatMessage({ id: 'permission.xem_bo_co' }),
      privilege: formatMessage({ id: 'nhm' }),
      code: 'view_dialcampaignreport_group',
    },
    view_dialcampaignreport: {
      group: formatMessage({ id: 'dial_campaigns' }),
      permission: formatMessage({ id: 'permission.xem_bo_co' }),
      privilege: formatMessage({ id: 'tt_c' }),
      code: 'view_dialcampaignreport',
    },
    delete_dialcampaign_own: {
      group: formatMessage({ id: 'dial_campaigns' }),
      permission: formatMessage({ id: 'permission.xo_chin_dch' }),
      privilege: formatMessage({ id: 'c_nhn' }),
      code: 'delete_dialcampaign_own',
    },
    delete_dialcampaign_group: {
      group: formatMessage({ id: 'dial_campaigns' }),
      permission: formatMessage({ id: 'permission.xo_chin_dch' }),
      privilege: formatMessage({ id: 'nhm' }),
      code: 'delete_dialcampaign_group',
    },
    delete_dialcampaign: {
      group: formatMessage({ id: 'dial_campaigns' }),
      permission: formatMessage({ id: 'permission.xo_chin_dch' }),
      privilege: formatMessage({ id: 'tt_c' }),
      code: 'delete_dialcampaign',
    },
    add_customer: {
      group: formatMessage({ id: 'khch_hng' }),
      permission: formatMessage({ id: 'permission.thm_mi_khch_hng' }),
      privilege: undefined,
      code: 'add_customer',
    },
    view_contact_customer: {
      group: formatMessage({ id: 'khch_hng' }),
      permission: formatMessage({ id: 'permission.xem_thng_tin_lin_h' }),
      privilege: undefined,
      code: 'view_contact_customer',
    },
    export_customer: {
      group: formatMessage({ id: 'khch_hng' }),
      permission: formatMessage({ id: 'permission.xut_excel' }),
      privilege: undefined,
      code: 'export_customer',
    },
    view_customer_own: {
      group: formatMessage({ id: 'khch_hng' }),
      permission: formatMessage({ id: 'permission.xem_danh_sch' }),
      privilege: formatMessage({ id: 'c_nhn' }),
      code: 'view_customer_own',
    },
    view_customer_group: {
      group: formatMessage({ id: 'khch_hng' }),
      permission: formatMessage({ id: 'permission.xem_danh_sch' }),
      privilege: formatMessage({ id: 'nhm' }),
      code: 'view_customer_group',
    },
    view_customer: {
      group: formatMessage({ id: 'khch_hng' }),
      permission: formatMessage({ id: 'permission.xem_danh_sch' }),
      privilege: formatMessage({ id: 'tt_c' }),
      code: 'view_customer',
    },
    change_customer_own: {
      group: formatMessage({ id: 'khch_hng' }),
      permission: formatMessage({ id: 'permission.sa_thng_tin' }),
      privilege: formatMessage({ id: 'c_nhn' }),
      code: 'change_customer_own',
    },
    change_customer_group: {
      group: formatMessage({ id: 'khch_hng' }),
      permission: formatMessage({ id: 'permission.sa_thng_tin' }),
      privilege: formatMessage({ id: 'nhm' }),
      code: 'change_customer_group',
    },
    change_customer: {
      group: formatMessage({ id: 'khch_hng' }),
      permission: formatMessage({ id: 'permission.sa_thng_tin' }),
      privilege: formatMessage({ id: 'tt_c' }),
      code: 'change_customer',
    },
    change_employees_customer_own: {
      group: formatMessage({ id: 'khch_hng' }),
      permission: formatMessage({ id: 'permission.phn_cng_chm_sc' }),
      privilege: formatMessage({ id: 'c_nhn' }),
      code: 'change_employees_customer_own',
    },
    change_employees_customer_group: {
      group: formatMessage({ id: 'khch_hng' }),
      permission: formatMessage({ id: 'permission.phn_cng_chm_sc' }),
      privilege: formatMessage({ id: 'nhm' }),
      code: 'change_employees_customer_group',
    },
    change_employees_customer: {
      group: formatMessage({ id: 'khch_hng' }),
      permission: formatMessage({ id: 'permission.phn_cng_chm_sc' }),
      privilege: formatMessage({ id: 'tt_c' }),
      code: 'change_employees_customer',
    },
    delete_customer_own: {
      group: formatMessage({ id: 'khch_hng' }),
      permission: formatMessage({ id: 'permission.xo_khch_hng' }),
      privilege: formatMessage({ id: 'c_nhn' }),
      code: 'delete_customer_own',
    },
    delete_customer_group: {
      group: formatMessage({ id: 'khch_hng' }),
      permission: formatMessage({ id: 'permission.xo_khch_hng' }),
      privilege: formatMessage({ id: 'nhm' }),
      code: 'delete_customer_group',
    },
    delete_customer: {
      group: formatMessage({ id: 'khch_hng' }),
      permission: formatMessage({ id: 'permission.xo_khch_hng' }),
      privilege: formatMessage({ id: 'tt_c' }),
      code: 'delete_customer',
    },
    change_manager_customer: {
      group: formatMessage({ id: 'khch_hng' }),
      permission: formatMessage({ id: 'permission.t_nhn_chm_sc_khch_hng' }),
      privilege: undefined,
      code: 'change_manager_customer',
    },
    add_customergroup: {
      group: formatMessage({ id: 'tp_khch_hng' }),
      permission: formatMessage({ id: 'permission.to_tp_khch_hng' }),
      privilege: undefined,
      code: 'add_customergroup',
    },
    view_customergroup_own: {
      group: formatMessage({ id: 'tp_khch_hng' }),
      permission: formatMessage({ id: 'permission.xem_tp_khch_hng' }),
      privilege: formatMessage({ id: 'c_nhn' }),
      code: 'view_customergroup_own',
    },
    view_customergroup_group: {
      group: formatMessage({ id: 'tp_khch_hng' }),
      permission: formatMessage({ id: 'permission.xem_tp_khch_hng' }),
      privilege: formatMessage({ id: 'nhm' }),
      code: 'view_customergroup_group',
    },
    view_customergroup: {
      group: formatMessage({ id: 'tp_khch_hng' }),
      permission: formatMessage({ id: 'permission.xem_tp_khch_hng' }),
      privilege: formatMessage({ id: 'tt_c' }),
      code: 'view_customergroup',
    },
    change_customergroup_own: {
      group: formatMessage({ id: 'tp_khch_hng' }),
      permission: formatMessage({ id: 'permission.sa_tp_khch_hng' }),
      privilege: formatMessage({ id: 'c_nhn' }),
      code: 'change_customergroup_own',
    },
    change_customergroup_group: {
      group: formatMessage({ id: 'tp_khch_hng' }),
      permission: formatMessage({ id: 'permission.sa_tp_khch_hng' }),
      privilege: formatMessage({ id: 'nhm' }),
      code: 'change_customergroup_group',
    },
    change_customergroup: {
      group: formatMessage({ id: 'tp_khch_hng' }),
      permission: formatMessage({ id: 'permission.sa_tp_khch_hng' }),
      privilege: formatMessage({ id: 'tt_c' }),
      code: 'change_customergroup',
    },
    delete_customergroup_own: {
      group: formatMessage({ id: 'tp_khch_hng' }),
      permission: formatMessage({ id: 'permission.xo_tp_khch_hng' }),
      privilege: formatMessage({ id: 'c_nhn' }),
      code: 'delete_customergroup_own',
    },
    delete_customergroup_group: {
      group: formatMessage({ id: 'tp_khch_hng' }),
      permission: formatMessage({ id: 'permission.xo_tp_khch_hng' }),
      privilege: formatMessage({ id: 'nhm' }),
      code: 'delete_customergroup_group',
    },
    delete_customergroup: {
      group: formatMessage({ id: 'tp_khch_hng' }),
      permission: formatMessage({ id: 'permission.xo_tp_khch_hng' }),
      privilege: formatMessage({ id: 'tt_c' }),
      code: 'delete_customergroup',
    },
    view_call_manual_own: {
      group: formatMessage({ id: 'menu.history' }),
      permission: formatMessage({ id: 'permission.xem_lch_s_gi' }),
      privilege: formatMessage({ id: 'c_nhn' }),
      code: 'view_call_manual_own',
    },
    view_call_manual_group: {
      group: formatMessage({ id: 'menu.history' }),
      permission: formatMessage({ id: 'permission.xem_lch_s_gi' }),
      privilege: formatMessage({ id: 'nhm' }),
      code: 'view_call_manual_group',
    },
    view_call_manual: {
      group: formatMessage({ id: 'menu.history' }),
      permission: formatMessage({ id: 'permission.xem_lch_s_gi' }),
      privilege: formatMessage({ id: 'tt_c' }),
      code: 'view_call_manual',
    },
    export_call: {
      group: formatMessage({ id: 'menu.history' }),
      permission: formatMessage({ id: 'permission.xut_excel_lch_s_gi' }),
      privilege: undefined,
      code: 'export_call',
    },
    view_call_auto: {
      group: formatMessage({ id: 'menu.history' }),
      permission: formatMessage({ id: 'permission.xem_lch_s_gi_t_dng' }),
      privilege: undefined,
      code: 'view_call_auto',
    },
    view_transactionsubscription: {
      group: formatMessage({ id: 'thanh_ton' }),
      permission: formatMessage({ id: 'permission.xem_lch_s_gio_dch' }),
      privilege: undefined,
      code: 'view_transactionsubscription',
    },
    view_subscription: {
      group: formatMessage({ id: 'thanh_ton' }),
      permission: formatMessage({ id: 'permission.xem_gi_thanh_ton' }),
      privilege: undefined,
      code: 'view_subscription',
    },
    add_companyauthgroup_group: {
      group: formatMessage({ id: 'ngi_dng' }),
      permission: formatMessage({ id: 'permission.to_qun_l_phn_quyn' }),
      privilege: formatMessage({ id: 'nhm' }),
      code: 'add_companyauthgroup_group',
    },
    add_companyauthgroup: {
      group: formatMessage({ id: 'ngi_dng' }),
      permission: formatMessage({ id: 'permission.to_qun_l_phn_quyn' }),
      privilege: formatMessage({ id: 'tt_c' }),
      code: 'add_companyauthgroup',
    },
    view_companyauthgroup_group: {
      group: formatMessage({ id: 'ngi_dng' }),
      permission: formatMessage({ id: 'permission.xem_qun_l_phn_quyn' }),
      privilege: formatMessage({ id: 'nhm' }),
      code: 'view_companyauthgroup_group',
    },
    view_companyauthgroup: {
      group: formatMessage({ id: 'ngi_dng' }),
      permission: formatMessage({ id: 'permission.xem_qun_l_phn_quyn' }),
      privilege: formatMessage({ id: 'tt_c' }),
      code: 'view_companyauthgroup',
    },
    change_companyauthgroup_group: {
      group: formatMessage({ id: 'ngi_dng' }),
      permission: formatMessage({ id: 'permission.sa_qun_l_phn_quyn' }),
      privilege: formatMessage({ id: 'nhm' }),
      code: 'change_companyauthgroup_group',
    },
    change_companyauthgroup: {
      group: formatMessage({ id: 'ngi_dng' }),
      permission: formatMessage({ id: 'permission.sa_qun_l_phn_quyn' }),
      privilege: formatMessage({ id: 'tt_c' }),
      code: 'change_companyauthgroup',
    },
    delete_companyauthgroup_group: {
      group: formatMessage({ id: 'ngi_dng' }),
      permission: formatMessage({ id: 'permission.xo_qun_l_phn_quyn' }),
      privilege: formatMessage({ id: 'nhm' }),
      code: 'delete_companyauthgroup_group',
    },
    delete_companyauthgroup: {
      group: formatMessage({ id: 'ngi_dng' }),
      permission: formatMessage({ id: 'permission.xo_qun_l_phn_quyn' }),
      privilege: formatMessage({ id: 'tt_c' }),
      code: 'delete_companyauthgroup',
    },
    change_payment_infomation_company: {
      group: formatMessage({ id: 'ngi_dng' }),
      permission: formatMessage({ id: 'permission.cp_nht_h_s_cng_ty' }),
      privilege: formatMessage({ id: 'cnt_ty' }),
      code: 'change_payment_infomation_company',
    },
    call_customer: {
      group: formatMessage({ id: 'khch_hng' }),
      permission: formatMessage({ id: 'permission.gi_khch_hng' }),
      privilege: undefined,
      code: 'call_customer',
    },
  };

  return {
    KnownPermissions,
    SortedGroups,
    TransposePermissionObjects,
  };
};
