import { useQuery } from 'react-query';
import axiosInstance from '@/services/api/axiosInstance';
import type { ServerFormError } from '@/services/api/types';
import type { AxiosResponse } from 'axios';
import useCompanyId from '@/hooks/useCompanyId';
import { IPermission } from '@/services/api/types';
export const usePermissionsKey = 'permissions';

export function usePermission(permission: string) {
  const { data: permissions } = usePermissions();

  return permissions?.data?.find((p) => {
    return p?.codename === permission;
  });
}

function usePermissions() {
  const { apiRoutes, COMPANY_ID } = useCompanyId();

  return useQuery<AxiosResponse<IPermission[]>, ServerFormError>(
    [usePermissionsKey, COMPANY_ID],
    () => {
      return axiosInstance.get(apiRoutes.permission.list);
    },
  );
}

export default usePermissions;
