import { FacebookOutlined } from '@ant-design/icons';
import { DefaultFooter } from '@ant-design/pro-layout';
import { formatDateFromString, isHideLayout } from '@/services/api/util';
import useCurrentCompany from '@/services/api/company/useCurrentCompany';
import { useIntl } from '@@/plugin-locale/localeExports';
const { version } = require('../../../package.json');
import React, { useEffect, useMemo, useState } from 'react';
import usePing from '@/services/api/usePing';
import { iframe_id } from '@/components/PhoneBridgeProvider';
import PhoneBridgeIframe from '@/components/Footer/PhoneBridgeIframe';
import { Card } from 'antd';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();
  const { data } = useCurrentCompany();
  const defaultPartner = data?.data?.partner?.is_default;
  const { data: ping } = usePing(defaultPartner && process.env.NODE_ENV !== 'development');
  const { formatMessage } = useIntl();

  if (isHideLayout()) {
    return (
      <>
        <PhoneBridgeIframe />
      </>
    );
  }

  if (!data?.data?.partner?.is_default) {
    return (
      <>
        <DefaultFooter
          copyright={`Version: ${version}. Build at: ${formatDateFromString(BUILD_TIME)}`}
        />
        {/*<Card>*/}
        <PhoneBridgeIframe />
        {/*</Card>*/}
      </>
    );
  }

  return (
    <>
      <DefaultFooter
        copyright={`${currentYear}, UCALL JSC. Version: ${version}. Build at: ${formatDateFromString(
          BUILD_TIME,
        )}. Ping: ${ping}ms`}
      />
      <PhoneBridgeIframe />
    </>
  );
};

export default Footer;
