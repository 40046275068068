import { Alert } from 'antd';
import useJobs from '@/services/api/job/useJobs';
import useCompanyId from '@/hooks/useCompanyId';
import { useSessionstorage } from 'rooks';
import { useIntl } from '@@/plugin-locale/localeExports';

const ListJobs = () => {
  const [closed, setClosed] = useSessionstorage('closed-messages', []);
  const { COMPANY_ID } = useCompanyId();
  const { data } = useJobs();
  const { formatMessage } = useIntl();

  if (!COMPANY_ID) {
    return <></>;
  }

  return (
    <div className="mb-3">
      {data?.data?.map((job) => {
        if ((closed as string[]).includes(job.id)) {
          return <></>;
        }
        return (
          <Alert
            message={`${formatMessage({ id: 'job' })}: ${job.title}`}
            type={job.amount / job.total === 1 ? 'success' : 'warning'}
            description={`${formatMessage({ id: 'progress' })}: ${Math.round(
              (job.amount / job.total) * 100,
            )}% `}
            className="mb-2"
            closable
            onClose={() => {
              setClosed([...closed, job.id]);
            }}
          />
        );
      })}
    </div>
  );
};

export default ListJobs;
