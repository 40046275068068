import { useMutation, useQueryClient } from 'react-query';
import type { DefaultServerError, ICampaign } from '@/services/api/types';
import axiosInstance from '@/services/api/axiosInstance';
import type { AxiosResponse } from 'axios';
import useCompanyId from '@/hooks/useCompanyId';
import { ICall } from '@/services/api/types';

function useUpdateManualCall() {
  const { apiRoutes } = useCompanyId();
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<ICall>, DefaultServerError, Partial<ICall>>(
    (input) => {
      return axiosInstance.put(apiRoutes.call.detail(`${input.id}`), {
        ...input,
      });
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData('update_manual_call', data?.data);
      },
    },
  );
}

export default useUpdateManualCall;
