import { useQuery } from 'react-query';
import axiosInstance from '@/services/api/axiosInstance';
import type { IJob, ServerFormError } from '@/services/api/types';
import type { AxiosResponse } from 'axios';
import useCompanyId from '@/hooks/useCompanyId';
export const useJobsKey = 'jobs';

function useJobs() {
  const { COMPANY_ID, apiRoutes } = useCompanyId();
  return useQuery<AxiosResponse<IJob[]>, ServerFormError>(
    [useJobsKey],
    () => {
      return axiosInstance.get(apiRoutes.job.list, {
        params: { status: 'RUN' },
      });
    },
    {
      refetchInterval: COMPANY_ID ? 20000 : false,
      enabled: COMPANY_ID !== undefined,
    },
  );
}

export default useJobs;
