import { useMutation } from 'react-query';
import type { DefaultServerError, ICallBotScenario } from '@/services/api/types';
import axiosInstance from '@/services/api/axiosInstance';
import type { AxiosResponse } from 'axios';
import useCompanyId from '@/hooks/useCompanyId';

function useRegisterDevice(FCM_TOKEN: string) {
  const { apiRoutes } = useCompanyId();

  return useMutation<AxiosResponse<ICallBotScenario>, DefaultServerError>(() => {
    return axiosInstance.post(apiRoutes.devices.register, {
      registration_id: FCM_TOKEN,
      type: 'web',
    });
  });
}

export default useRegisterDevice;
