import Button from 'antd-button-color';
import { AudioPlayerProvider, useAudioPlayer } from 'react-use-audio-player';
import { DownloadOutlined, PauseOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { useIntl } from '@@/plugin-locale/localeExports';

interface PhoneRecordButtonProps {
  url: string;
  lite?: boolean;
  preload?: boolean;
  callback?: () => void;
}

const PhoneRecordButtonChild = ({
  url,
  lite = false,
  preload = true,
  callback = () => {},
}: PhoneRecordButtonProps) => {
  const { play, stop, playing, loading } = useAudioPlayer({
    src: url,
    format: 'wav',
    preload: preload,
    autoplay: false,
  });
  const { formatMessage } = useIntl();
  if (lite) {
    return (
      <>
        {!playing && (
          <PlayCircleOutlined
            onClick={() => {
              play();
              callback();
            }}
            style={{ color: 'var(--ant-success-color)', fontSize: '16px' }}
          />
        )}
        {playing && (
          <PauseOutlined
            onClick={() => {
              stop();
            }}
            style={{ color: 'var(--ant-error-color)', fontSize: '16px' }}
          />
        )}
      </>
    );
  }

  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div>
        {!playing && (
          <Button
            type="success"
            loading={loading}
            onClick={() => {
              play();
            }}
          >
            <PlayCircleOutlined /> {formatMessage({ id: 'nghe_ghi_m' })}
          </Button>
        )}
        {playing && (
          <Button
            type="danger"
            loading={loading}
            onClick={() => {
              stop();
            }}
          >
            <PauseOutlined /> {formatMessage({ id: 'stop' })}
          </Button>
        )}
      </div>
      <div>
        <Button type="success" with="link" href={url} target="_blank">
          <DownloadOutlined /> {formatMessage({ id: 'ti_v' })}
        </Button>
      </div>
    </div>
  );
};

const PhoneRecordButton = ({ url, lite, preload, callback }: PhoneRecordButtonProps) => {
  return (
    <AudioPlayerProvider>
      <PhoneRecordButtonChild url={url} lite={lite} preload={preload} callback={callback} />
    </AudioPlayerProvider>
  );
};

export default PhoneRecordButton;
