import { useMutation } from 'react-query';
import type { DefaultServerError } from '@/services/api/types';
import axiosInstance from '@/services/api/axiosInstance';
import type { AxiosResponse } from 'axios';
import useCompanyId from '@/hooks/useCompanyId';

function useDeleteCustomerNote(CUSTOMER_ID: string, CUSTOMER_NOTE_ID: string) {
  const { apiRoutes } = useCompanyId();

  return useMutation<AxiosResponse, DefaultServerError>(() => {
    return axiosInstance.delete(apiRoutes.customer_note(CUSTOMER_ID).detail(CUSTOMER_NOTE_ID));
  });
}

export default useDeleteCustomerNote;
