import type { ServerFormError } from '@/services/api/types';
import { get } from 'lodash';
import { message } from 'antd';
import moment from 'moment';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { getMessaging } from 'firebase/messaging';
import { envs, EVENTS_KEYS, isLandingPage } from '@/constants';
import Button from 'antd-button-color';
import React from 'react';
import { format, parseISO } from 'date-fns';
const firebaseConfig = {
  apiKey: 'AIzaSyAOxcvUrFg2cHAO_w7hn9PgBBO71iHL3xY',
  authDomain: 'ucall-blog-landingpage.firebaseapp.com',
  projectId: 'ucall-blog-landingpage',
  storageBucket: 'ucall-blog-landingpage.appspot.com',
  messagingSenderId: '784205208639',
  appId: '1:784205208639:web:cf5fcd8b0bba855352ac8a',
  measurementId: 'G-8DWJSC9KY3',
};

const landingPageFirebaseConfig = {
  apiKey: 'AIzaSyB4x03GCJlgdBbeOoafCIr0eF0g5avDkL4',
  authDomain: 'ucall-frontend-v2.firebaseapp.com',
  projectId: 'ucall-frontend-v2',
  storageBucket: 'ucall-frontend-v2.appspot.com',
  messagingSenderId: '638498092149',
  appId: '1:638498092149:web:ebd80bc758542c8b9bb43c',
  measurementId: 'G-JV7JN6HRLF',
};

export const VAPID_KEY =
  'BOuz0pBY8-kGBGws6wO4RBeXHKVNMrvnCJdnmbjsjofarVZbskR__QpzTo2SI9jwsOHd5Hd-hSO-It9nIhqCfR0';

export const firebaseApp = envs.IS_DEV
  ? undefined
  : initializeApp(isLandingPage ? landingPageFirebaseConfig : firebaseConfig);
export const firebaseAnalytics = envs.IS_DEV ? undefined : getAnalytics(firebaseApp);

export const firebaseMessaging = envs.IS_DEV ? undefined : getMessaging(firebaseApp);
export const TIME_FORMAT = 'HH:mm:ss';
export const DATE_ONLY_FORMAT = 'YYYY-MM-DD';
export const DATE_ONLY_DISPLAY_FORMAT = 'DD-MM-YYYY';

export const convertErrorResponseToAntdFormError = (response: ServerFormError) => {
  return Object.keys(response).map((r) => ({
    name: r,
    errors: get(response, r),
  }));
};

export const displayError = (err: any) => {
  if (err?.response?.data?.error_messages) {
    message.error(err?.response?.data?.error_messages);
  }
};

export const formatDateFromString = (date: string | undefined) => {
  if (!date) {
    return '';
  }
  return moment(date).format('DD/MM/YYYY · HH:mm:ss');
};

export const formatDateForTable = (date: string | undefined) => {
  if (!date) {
    return '';
  }
  const parsedDate = moment(date);
  return (
    <div>
      <div>{parsedDate.format('DD/MM/YYYY')}</div>
      <div className="text-muted">{parsedDate.format('HH:mm')}</div>
    </div>
  );
};

export const formatCurrency = (n: number) => {
  return n?.toString()?.replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
};
export const daysRemaining = (date: string) => {
  const eventdate = moment(date);
  const todaysdate = moment();
  return eventdate.diff(todaysdate, 'days');
};

export const durationToHumanize = (duration: Duration) => {
  return `${duration.months ? duration.months + ' tháng' : ''} ${
    duration.weeks ? duration.weeks + ' tuần' : ''
  } ${duration.days ? duration.days + ' ngày' : ''} ${
    duration.hours ? duration.hours + ' giờ' : ''
  } ${duration.minutes ? duration.minutes + ' phút' : ''} ${
    duration.seconds ? duration.seconds + ' giây' : ''
  } `;
};

export const isImg = /\.(gif|jpe?g|tiff?|png|webp|bmp|svg)$/i;
export const getChildrenToRender = (item, i) => {
  let tag = item.name.indexOf('title') === 0 ? 'h1' : 'div';
  tag = item.href ? 'a' : tag;
  let children =
    typeof item.children === 'string' && item.children.match(isImg)
      ? React.createElement('img', { src: item.children, alt: 'img' })
      : item.children;
  if (item.name.indexOf('button') === 0 && typeof item.children === 'object') {
    children = React.createElement(Button, {
      ...item.children,
    });
  }
  return React.createElement(tag, { key: i.toString(), ...item }, children);
};

export const trackOpenApp = () => {
  if (firebaseAnalytics) {
    logEvent(firebaseAnalytics, EVENTS_KEYS.OPEN_APP, {});
  }
  try {
    window.fbq('track', EVENTS_KEYS.OPEN_APP, {});
  } catch (e) {}
};

export const trackHeaderLink = (link: string) => {
  if (firebaseAnalytics) {
    logEvent(firebaseAnalytics, `${EVENTS_KEYS.HEADER}_${link}`?.toUpperCase(), {});
  }
  try {
    window.fbq('track', `${EVENTS_KEYS.HEADER}_${link}`?.toUpperCase(), {});
  } catch (e) {}
};

export const trackEvent = (event: string, data: any) => {
  if (firebaseAnalytics) {
    logEvent(firebaseAnalytics, event, data);
  }
  try {
    window.fbq('track', event, data);
  } catch (e) {}
};

export function pad(num, size) {
  num = num.toString();
  while (num.length < size) num = '0' + num;
  return num;
}

export function getAudio(id: string): HTMLAudioElement {
  const el = document.getElementById(id);
  if (!(el instanceof HTMLAudioElement)) {
    throw new Error(`Element "${id}" not found or not an audio element.`);
  }
  return el;
}

export const whichProduct = () => {
  if (window.location.href?.includes('callbot-ai-auto-call')) {
    return 'callbot';
  }
  if (window.location.href?.includes('call-center')) {
    return 'call_center';
  }
  if (window.location.href?.includes('customers-data')) {
    return 'uleads';
  }
  return 'callbot';
};

export const isHideLayout = () => {
  if (window?.location?.hostname?.includes('iframe.')) {
    return true;
  }
  return false;
};

export const saveWorkflowForBothVersions = async () => {
  if (isHideLayout()) {
    for (let i = 0; i < window.parent.frames.length; i++) {
      try {
        window.parent.frames[i].saveWorkflow();
      } catch (e) {
        console.log('frame not allow', window.parent.frames[i]);
      }
    }
  } else {
    await window.parent.saveWorkflow();
  }
};
export const displayToastSuccessForBothVersions = (message: any) => {
  if (isHideLayout()) {
    for (let i = 0; i < window.parent.frames.length; i++) {
      window.parent.frames[i].toast.success(message);
    }
  } else {
    return window.parent.toast.success(message);
  }
};

export const formatSubscriptionDate = (date: string) => {
  try {
    return format(parseISO(date), 'HH:mm dd/MM/yyyy');
  } catch {
    return 'Không xác định';
  }
};
